import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const TYPE = "PROGRESS";

export interface ProgressState {
  sjitOrders: {
    upload: number;
    process: number;
  };
  roas: any[];
  sjitInventory: any[];
  sjitInventoryPayload: any;
}

const initialState: ProgressState = {
  sjitOrders: {
    upload: 0,
    process: 0,
  },
  roas: [],
  sjitInventory: [],
  sjitInventoryPayload: null,
};

const slice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setSjitOrderUpload: (state, action) => {
      state.sjitOrders.upload = action.payload;
    },
    setSjitOrderProcess: (state, action) => {
      state.sjitOrders.process = action.payload;
    },
    setRoas: (state, action) => {
      state.roas = action.payload;
    },
    setSjitInventory: (state, action) => {
      state.sjitInventory = action.payload;
    },
    setSjitInventoryPayload: (state, action) => {
      state.sjitInventoryPayload = action.payload;
    },
  },
});

export const {
  setSjitOrderUpload,
  setSjitOrderProcess,
  setRoas,
  setSjitInventory,
  setSjitInventoryPayload,
} = slice.actions;

export default slice.reducer;
