import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Typography,
  Breadcrumb,
  Input,
  Select,
  Button,
  Space,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import {} from "@ant-design/icons";

import { TITLE, BASE_PATH } from ".";
import { useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import service from "../../../services/user";
import roleService from "../../../services/role";
import clientService from "../../../services/client";
import querystring from "query-string";
import _ from "lodash";
const { Title } = Typography;
const { Option } = Select;
export default function EntityForm(props: any) {
  const [form] = Form.useForm();
  const [id, setId] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const handleLoadRoles = async () => {
    const roles = await roleService.getAll();
    setRoles(roles);
  };
  const handleLoadClients = async () => {
    const clients = await clientService.getAll();
    setClients(clients);
  };

  useEffect(() => {
    handleLoadRoles();
    handleLoadClients();
  }, []);
  const handleSubmit = (values: any) => {
    const payload = { ...values, credentials: { username: values.email } };
    if (id) {
      service.update({ ...values, _id: id }).then((_) => navigate(BASE_PATH));
    } else {
      service.create({ ...values }).then((_) => navigate(BASE_PATH));
    }
  };
  const handleCancel = () => {
    navigate(BASE_PATH);
  };
  useEffect(() => {
    const id = new URLSearchParams(location.search).get("id");
    if (id) {
      setId(id);
      service.get(id).then((data) => {
        form.setFieldsValue(data);
      });
    }
  }, [location]);

  return (
    <div>
      <Breadcrumb style={{ marginBottom: "1rem" }}>
        <Breadcrumb.Item>Taxonomy</Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={BASE_PATH}>{TITLE[1]}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{TITLE[0]}</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={3}>
        {id ? "Update" : "New"} {TITLE[0]}
      </Title>
      <div className="mt-4 bg-white shadow p-4">
        <Form
          layout="vertical"
          name="subject"
          form={form}
          onFinish={handleSubmit}
          onFinishFailed={console.log}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: true, message: "Please assign a role." }]}
          >
            <Select>
              {roles.map((role, index) => (
                <Option value={role._id} key={index}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Clients"
            name="clients"
            rules={[{ required: true, message: "Please select a client." }]}
          >
            <Select mode="multiple" placeholder="Select Clients">
              {clients.map((role, index) => (
                <Option value={role._id} key={index}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <span>
                Username{" "}
                <span className="italic text-gray-400">
                  (will be used as login username)
                </span>
              </span>
            }
            name={["credentials", "username"]}
            rules={[
              { required: true, message: "Username is required." },
              {
                validator: async (rule, value) => {
                  const data = await service.checkCode(value, id);
                  if (!data.isValid) {
                    throw new Error("Code already exists");
                  }
                },
              },
            ]}
            validateFirst
          >
            <Input />
          </Form.Item>
          {!id && (
            <Form.Item
              label="Password"
              name={["credentials", "password"]}
              rules={[{ required: true, message: "Password is required." }]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item>
            <Space>
              <Button type="default" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
