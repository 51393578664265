import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/purchase-order";
export const register = (data: any) => postRequest(SERVICE_URL, data);
export const update = (data: any) => putRequest(SERVICE_URL, data);
export const bulkRegister = (data: any) =>
  postRequest(`${SERVICE_URL}/bulk`, data);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);
export const get = (id: string) => getRequest(`${SERVICE_URL}/${id}`);
export const getForVendorId = (id: string) =>
  getRequest(`${SERVICE_URL}/vendor/${id}`);
export const getGRNsForPurchaseOrderId = (id: string) =>
  getRequest(`${SERVICE_URL}/grn/${id}`);
export const getPendingQuantityForSKUs = (ids: string[]) =>
  postRequest(`${SERVICE_URL}/pending-quantity`, { skus: ids });
export const voidPO = (id: string) => putRequest(`${SERVICE_URL}/void/${id}`);
export const getMetrics = (data) => postRequest(`${SERVICE_URL}/metrics`, data);
export const getPendingPOReport = (endDate) =>
  postRequest(`${SERVICE_URL}/report/pending`, { endDate });
export const reportSummary = (data) =>
  postRequest(`${SERVICE_URL}/report/summary`, { ...data });
export default {
  bulkRegister,
  paginate,
  get,
  getForVendorId,
  getGRNsForPurchaseOrderId,
  register,
  update,
  getPendingQuantityForSKUs,
  voidPO,
  getMetrics,
  getPendingPOReport,
  reportSummary,
};
