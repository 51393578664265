import { API_URL } from "./apiUrl";
import axios from "axios";
import logout from "../../utils/logout";

const handleError = (err: any) => {
  let error = { statusCode: 0, statusText: "" };
  if (!err.response) {
    error.statusCode = 1000;
    error.statusText =
      "Cannot reach server. Please check your internet connection.";
  } else {
    error.statusCode = err?.response?.status;
    error.statusText = err?.response?.data?.error?.message;
  }
  if (error?.statusCode && error?.statusCode === 401) {
    logout(false, true);
  }
  throw new Error(`Code: ${error?.statusCode} Message: ${error?.statusText}`);
};

export const postRequest = async (url: string, data = {}, headers = {}) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "POST",
      headers: Object.assign({}, headers),
      data,
      withCredentials: true,
    });
    return res;
  } catch (err) {
    handleError(err);
  }
};
export const getRequest = async (url: string, headers = {}) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "GET",
      headers: Object.assign({}, headers),
      withCredentials: true,
    });
    return res;
  } catch (err) {
    // console.log(err);

    handleError(err);
  }
};
export const putRequest = async (url: string, data = {}, headers = {}) => {
  try {
    const res = await axios({
      url: API_URL + url,
      method: "PUT",
      headers: Object.assign({}, headers),
      data,
      withCredentials: true,
    });
    return res && res.data && (res.data.result || res.data);
  } catch (err) {
    handleError(err);
  }
};
