import { useState } from "react";
import { useSelector } from "react-redux";
import { Store } from "../../store";
import { UploadProps } from "antd/es/upload/Upload";
import { message, Tooltip } from "antd";
import { InboxOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Progress, Upload } from "antd";
import { useEffect } from "react";
import { useCallback } from "react";
import * as XLSX from "xlsx";
import { titleCase } from "title-case";
import service from "../../services/order";
import { Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { BASE_PATH } from ".";
const { Dragger } = Upload;
const { Title } = Typography;

const REQUIRED_COLUMNS = [
  "Date",
  "Order ID",
  "SKU Code",
  "Quantity",
  "Selling Price",
  "Order Amount",
  "Tax Rate",
  "Tax Amount",
];

const UploadRender: React.FC<any> = ({ onChange }) => {
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [error, setError] = useState<any>({ title: "", message: "" });
  const [data, setData] = useState<any[]>([]);
  const [reportRender, setReportRender] = useState<any>(null);
  const [response, setResponse] = useState<any>();
  const navigate = useNavigate();
  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    onChange(info) {
      if (info.fileList.length === 0) {
        setFileList([]);
      }

      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
  };

  const handleSubmit = async () => {
    try {
      setUploading(true);

      const file = fileList?.[0];
      const formData = new FormData();
      formData.append("file.xlsx", file);
      try {
        const response = await service.uploadBulk(formData);
        setResponse(response);
      } catch (uploadError) {
        message.error("File upload failed."); // Notify user about the error
      }
    } catch (error) {
    } finally {
      setUploading(false);
    }
  };

  const validateColumns = (row: { [key: string]: any }): string[] => {
    return REQUIRED_COLUMNS.filter((column) => !(column in row));
  };
  const findEmptyRequiredValues = (
    data: Array<{ [key: string]: any }>
  ): any[] => {
    let emptyDetails: any[] = [];

    data.forEach((row, index) => {
      REQUIRED_COLUMNS.forEach((column) => {
        if (!(column in row) || row[column] === null || row[column] === "") {
          emptyDetails.push({ row: index + 2, column: column });
        }
      });
    });

    return emptyDetails;
  };

  const readFile = useCallback((file: File) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const workbook = XLSX.read(e.target.result, {
          type: "binary",
          raw: true,
          cellDates: true,
        });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          defval: null,
        });
        setData(jsonData.filter((row) => row["Order ID"]));
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      message.error("Error reading the file.");
    }
  }, []);

  useEffect(() => {
    if (fileList.length === 0) {
      return () => {};
    }
    readFile(fileList[0]);
  }, [fileList]);

  useEffect(() => {
    if (fileList.length === 0) {
      setReportRender(null);
      return () => {};
    }

    if (data.length === 0) {
      setReportRender(
        <Alert
          className="mt-4"
          type="error"
          message="No data was found in the file, please validate that mappings are present in the first sheet."
          showIcon
        />
      );
      return () => {};
    }

    setReportRender(null);

    const missingColumns = validateColumns(data[0]);

    if (missingColumns.length > 0) {
      setReportRender(
        <Alert
          className="mt-4"
          type="error"
          message={`Missing required columns:`}
          description={`${missingColumns.join(", ")}`}
          showIcon
        />
      );
      return () => {};
    }

    const emptyDetails: { row: number; column: string }[] =
      findEmptyRequiredValues(data);

    if (emptyDetails?.length > 0) {
      const missingRequiredValuesMap: { [key: string]: number[] } = {};
      emptyDetails.forEach((detail) => {
        if (!missingRequiredValuesMap[detail.column]) {
          missingRequiredValuesMap[detail.column] = [];
        }
        missingRequiredValuesMap[detail.column].push(detail.row);
      });
      setReportRender(
        <Alert
          className="mt-4"
          type="error"
          description={
            <div>
              {Object.entries(missingRequiredValuesMap).map(
                ([column, rows]) => (
                  <div key={column}>
                    <strong>{titleCase(column)}:</strong>{" "}
                    <Tooltip
                      placement="right"
                      title={
                        <div>
                          <div>Rows: {rows.join(", ")}</div>
                        </div>
                      }
                    >
                      <span className="text-blue-500">
                        Rows <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </div>
                )
              )}
            </div>
          }
          message={
            <div>
              <div>Some required values are missing in the file.</div>
            </div>
          }
          showIcon
        />
      );
      return () => {};
    }
  }, [data, fileList]);

  return (
    <div>
      <div>
        <Title level={3}>Upload SJIT Orders</Title>
      </div>
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
      {error.title && (
        <Alert
          message={error.title}
          description={error.message}
          type="error"
          showIcon
        />
      )}
      <Divider className="my-2" />
      <div className="flex justify-between ">
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={fileList.length === 0 || uploading}
          loading={uploading}
        >
          Submit
        </Button>
      </div>
      <div>{reportRender}</div>
      <div className="mt-4">
        {response?.message ? (
          <Alert type="error" message={response.message} showIcon />
        ) : (
          response && (
            <Alert
              type={"success"}
              showIcon
              message={"Upload successful"}
              description={
                <span>
                  Batch Job{" "}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`${BASE_PATH}/sjit-jobs/${response.job}`);
                    }}
                  >
                    {response.job}
                  </a>{" "}
                  submitted
                </span>
              }
            />
          )
        )}
      </div>
    </div>
  );
};

export default UploadRender;
