export default function Logo(props: any) {
  return (
    <svg
      width="123"
      height="72"
      viewBox="0 0 123 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M94.3228 61.5C99.5228 60.7 104.056 60.0333 107.923 59.5C111.856 58.9667 114.723 58.7 116.523 58.7C118.389 58.7 119.789 58.8667 120.723 59.2C121.723 59.4667 122.223 60.0667 122.223 61C122.223 62.8667 117.189 65 107.123 67.4C97.1228 69.7333 89.7894 70.9 85.1228 70.9C83.0561 70.9 80.8894 70 78.6228 68.2C76.4228 66.3333 75.3228 64.4 75.3228 62.4C75.3228 60.8 78.5228 57.6 84.9228 52.8C90.9228 48.4 96.8228 43.2667 102.623 37.4C105.356 34.6 107.623 31.5333 109.423 28.2C111.289 24.8 112.223 21.7 112.223 18.9C112.223 16.0333 111.589 13.8 110.323 12.2C109.056 10.6 107.323 9.8 105.123 9.8C102.923 9.8 100.856 10.4667 98.9228 11.8C96.9894 13.1333 95.4228 14.4667 94.2228 15.8C93.0228 17.0667 92.2561 17.7 91.9228 17.7C90.7894 17.7 89.4561 17.2 87.9228 16.2C86.3894 15.2 85.6228 14.2 85.6228 13.2C85.6228 10.7333 87.6561 8 91.7228 5C95.8561 2 100.256 0.5 104.923 0.5C109.589 0.5 113.456 2.16667 116.523 5.5C119.656 8.76667 121.223 12.8333 121.223 17.7C121.223 32.1 108.889 47.2 84.2228 63C85.8228 62.8 89.1894 62.3 94.3228 61.5Z"
        fill="#777777"
      />
      <path
        d="M42.8 1.09998C50.6 1.09998 56.5 4.19998 60.5 10.4C64.5 16.6 66.5 24.4 66.5 33.8C66.5 43.2 64.1666 51.7666 59.5 59.5C54.9 67.1666 49.5666 71 43.5 71C39.2333 71 36.1 68.6 34.1 63.8C32.1 59 30.8 52.1666 30.2 43.3C27.4 47.2333 24.2 51.7 20.6 56.7C13.6666 66.3 8.79995 71.1 5.99995 71.1C4.93328 71.1 3.69995 70.2333 2.29995 68.5C0.899951 66.7667 0.199951 64.6 0.199951 62C0.199951 59.3333 1.09995 51.1 2.89995 37.3C4.69995 23.4333 5.59995 12.6333 5.59995 4.89997C5.59995 2.43331 6.33328 1.19997 7.79995 1.19997C9.26662 1.19997 10.9666 2.06664 12.9 3.79998C14.9 5.53331 15.9 7.13331 15.9 8.59998C15.9 9.99998 14.7666 17.0333 12.5 29.7C10.2999 42.3666 8.93328 52.3 8.39995 59.5C12.4666 52.6333 17.0333 46.3 22.1 40.5C27.2333 34.6333 30.9 31.7 33.1 31.7C35.3666 31.7 36.9333 36.7 37.8 46.7C38.1333 50.4333 38.7666 53.8666 39.7 57C40.7 60.1333 42.0333 61.7 43.7 61.7C47.4333 61.7 50.6666 58.7 53.4 52.7C56.2 46.6333 57.6 39.9333 57.6 32.6C57.6 24.4666 55.8 18.2 52.2 13.8C50.3333 11.4666 48.2666 10.3 46 10.3C43.8 10.3 41.9 9.53331 40.3 7.99998C38.7666 6.46664 38 5.13331 38 3.99997C38 2.06664 39.6 1.09998 42.8 1.09998Z"
        fill="#777777"
      />
    </svg>
  );
}
