import { Modal, Button } from "antd";

interface ConfirmationModalProps {
  title: string;
  visible: boolean;
  message: string;
  onConfirm: () => void;
  onReject: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
  confirmButtonType?: "primary" | "danger";
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  visible,
  message,
  onConfirm,
  onReject,
  onCancel,
  confirmText = "OK",
  cancelText = "Cancel",
  confirmButtonType = "primary",
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onReject}>
          {cancelText}
        </Button>,
        <Button
          key="confirm"
          variant="solid"
          color={confirmButtonType}
          onClick={onConfirm}
        >
          {confirmText}
        </Button>,
      ]}
    >
      <p>{message}</p>
    </Modal>
  );
};

export default ConfirmationModal;
