import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import warehouseReducer, { WarehouseState } from "./warehouseSlice";
import purchaseWarehouseReducer from "./purchaseWarehouseSlice";
import settingReducer, { SettingState } from "./settingSlice";
import batchReducer, { BatchState } from "./batchSlice";
import skuReducer, { SkuState } from "./skuSlice";
import reportReducer, { ReportState } from "./reportSlice";
import poReducer, { POState } from "./poSlice";
import authReducer, { AuthState } from "./authSlice";
import clientReducer, { ClientState } from "./clientSlice";
import progressReducer, { ProgressState } from "./progressSlice";
import labelPrinterReducer, { LabelPrinterState } from "./labelPrinterSlice";

export interface Store {
  warehouse: WarehouseState;
  setting: SettingState;
  batch: BatchState;
  sku: SkuState;
  report: ReportState;
  po: POState;
  auth: AuthState;
  purchaseWarehouse: WarehouseState;
  client: ClientState;
  progress: ProgressState;
  labelPrinter: LabelPrinterState;
}

const rootReducer = {
  warehouse: warehouseReducer,
  setting: settingReducer,
  batch: batchReducer,
  sku: skuReducer,
  report: reportReducer,
  auth: authReducer,
  po: poReducer,
  purchaseWarehouse: purchaseWarehouseReducer,
  client: clientReducer,
  progress: progressReducer,
  labelPrinter: labelPrinterReducer,
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["warehouse", "client", "labelPrinter"],
};

const persistCombinedReducers = persistCombineReducers(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistCombinedReducers,
  middleware: [thunk],
  devTools: true,
});

export const persistor = persistStore(store);
