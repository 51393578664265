import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import service from "../../services/payment";
import { BASE_PATH, TITLE } from ".";
import Title from "antd/es/typography/Title";
import { useSelector } from "react-redux";
import { Store } from "../../store";
import dayjs from "dayjs";
import { titleCase } from "title-case";

const PAYMENT_MODES = ["Cash", "Cheque", "NEFT", "RTGS", "IMPS"];

export default function EntityForm(props: any) {
  const [form] = Form.useForm();
  const params = useParams();
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const poState = useSelector((state: Store) => state.po);
  const type = Form.useWatch("type", form);

  useEffect(() => {
    if (params.id) {
      setId(params.id);
      service.get(params.id).then((res) => {
        form.setFieldsValue({
          ...res,
          vendor: res.vendor?._id,
          supplier: res.supplier?._id,
          date: dayjs(res.date),
        });
        setData(res);
      });
    }
  }, [params.id]);

  const handleFinish = async (values: any) => {
    console.log(values);
    setLoading(true);
    try {
      if (id) {
        await service.update({ _id: id, ...values });
      } else {
        await service.create(values);
      }
      navigate(BASE_PATH);
    } catch (error) {
      message.error(error.message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Title level={3}>
        {id ? "Edit" : "Add"} {TITLE[0]}
      </Title>
      <Form
        form={form}
        onFinish={handleFinish}
        className="mt-8"
        initialValues={{ date: dayjs(), type: "vendor" }}
      >
        <Form.Item name="type" label="Type">
          <Radio.Group optionType="button" buttonStyle="solid">
            <Radio.Button value="vendor">Vendor</Radio.Button>
            <Radio.Button value="supplier">Supplier</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {type === "supplier" ? (
          <Form.Item
            name="supplier"
            label="Supplier"
            rules={[{ required: true, message: "Please select a supplier" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "300px" }}
              loading={poState.suppliers.loading}
              placeholder="Select a supplier"
              allowClear
              disabled={!!id}
            >
              {poState.suppliers.data.map((supplier) => (
                <Select.Option key={supplier._id} value={supplier._id}>
                  {supplier.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <Form.Item
            name="vendor"
            label="Vendor"
            rules={[{ required: true, message: "Please select a vendor" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "300px" }}
              loading={poState.vendors.loading}
              placeholder="Select a vendor"
              allowClear
              disabled={!!id}
            >
              {poState.vendors.data.map((vendor) => (
                <Select.Option key={vendor._id} value={vendor._id}>
                  {vendor.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name="amount"
          label="Amount"
          rules={[{ required: true, message: "Please enter the amount" }]}
        >
          <InputNumber
            type="number"
            style={{ width: "300px" }}
            placeholder="Enter Amount"
            prefix="₹"
          />
        </Form.Item>
        <Form.Item
          name="mode"
          label="Payment Mode"
          rules={[{ required: true, message: "Please select a payment mode" }]}
        >
          <Select
            className=""
            style={{ width: "300px" }}
            placeholder="Select Payment Mode"
          >
            {PAYMENT_MODES.map((mode) => (
              <Select.Option key={mode} value={mode}>
                {mode}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="date"
          label="Payment Date"
          rules={[{ required: true, message: "Please select a payment date" }]}
        >
          <DatePicker
            allowClear={false}
            style={{ width: "300px" }}
            format="DD/MM/YYYY"
          />
        </Form.Item>
        <Form.Item name="reference" label="Reference">
          <Input.TextArea
            style={{ width: "300px" }}
            placeholder="Enter Reference"
          />
        </Form.Item>

        <Form.Item className="mt-4">
          <Space>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
