import { Routes, Route } from "react-router-dom";
import List from "./List";
import SJITForm from "./SJITForm";
// import View from "./View";
export const MODEL = "sku";
export const BASE_PATH = "/skus";
export const TITLE = ["Sku", "Skus"];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/sjit-stock`} element={<SJITForm />} />
      <Route path={`/`} element={<List />} />

      {/* <Route path={`/:id`} element={<View />} /> */}
    </Routes>
  );
}
