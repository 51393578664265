import { Layout } from "antd";
import Login from "../views/Login";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { Store } from "../store";
const { Content } = Layout;

export default function AuthLayout(props: any) {
  const authState = useSelector((state: Store) => state.auth);
  useEffect(() => {
    if (authState.user) {
      props.history.push("/");
    }
  }, [authState.user, props.history]);

  return <Login />;
}
