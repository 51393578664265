import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from "../services/client";
const TYPE = "CLIENT";

export interface ClientState {
  clients: any[];
  current: string;
  loading: boolean;
}

const initialState: ClientState = {
  clients: [],
  current: "",
  loading: false,
};

export const loadClientsAsync = createAsyncThunk(
  `${TYPE}/LOAD_CLIENTS`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getAll();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const clientSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setCurrent: (state, action) => {
      state.current = action.payload;
      localStorage.setItem("client", action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadClientsAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadClientsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.clients = action.payload;
    });
    builder.addCase(loadClientsAsync.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setCurrent } = clientSlice.actions;

export default clientSlice.reducer;
