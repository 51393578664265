import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  TimePicker,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../../store";
import { useEffect, useState } from "react";
import settingService from "../../../services/settings";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import * as SettingActions from "../../../store/settingSlice";
import statesOfIndia from "../../../utils/statesOfIndia";
import querystring from "query-string";
import _ from "lodash";
import { BASE_PATH, TITLE } from ".";

const SIZES = {
  male: [3, 4, 5, 6, 7, 8, 9, 10, 11],
  female: [2, 3, 4, 5, 6, 7, 8, 9],
};

function EntityForm(props: any) {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settingState = useSelector((state: Store) => state.setting);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [id, setId] = useState("");
  const [loadLoading, setLoadLoading] = useState(false);
  useEffect(() => {
    const params: any = querystring.parse(location.search);
    if (!_.isEmpty(params)) {
      setId(params.id);
      setLoadLoading(true);
      settingService
        .getSizeDistribution(params.id)
        .then((payload) => {
          form.setFieldsValue({ ...payload });
        })
        .finally(() => setLoadLoading(false));
    }
  }, [location]);
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      if (id) {
        await settingService.updateSizeDistribution({
          ...values,
          _id: id,
        });
      } else {
        await settingService.createSizeDistribution({
          ...values,
        });
      }
      navigate(BASE_PATH);
      return;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        New {TITLE[0]}
      </div>

      <Form form={form} layout="vertical" onFinish={onFinish} className="mt-4">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input className="w-[350px]" placeholder="Enter Name" />
        </Form.Item>
        <div className="p-3 border-2 rounded-lg">
          <div>Size Distribution Percentage</div>
          <Divider className="my-2" />
          <div className="flex gap-x-2 items-center mt-4">
            <div>Male</div>
            {SIZES.male.map((size) => (
              <Form.Item
                key={size}
                label={`Size: ${size}`}
                name={["distribution", "male", size.toString()]}
              >
                <InputNumber className="w-[100px]" placeholder="Enter Size" />
              </Form.Item>
            ))}
          </div>
          <div className="flex gap-x-2 items-center">
            <div>Female</div>
            {SIZES.female.map((size) => (
              <Form.Item
                key={size}
                label={`Size: ${size}`}
                name={["distribution", "female", size.toString()]}
              >
                <InputNumber className="w-[100px]" placeholder="Enter Size" />
              </Form.Item>
            ))}
          </div>
        </div>
        <div className="mt-4">
          <Form.Item className="w-max" noStyle>
            <Space>
              <Button onClick={() => navigate(BASE_PATH)}>Cancel</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default EntityForm;
