import { Button, Table, Tag } from "antd";
import { Store } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import settingsService from "../../../services/settings";
import * as SettingActions from "../../../store/settingSlice";

import { useNavigate } from "react-router-dom";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
function ApiKeys(props: any) {
  const navigate = useNavigate();
  const settingState = useSelector((state: Store) => state.setting);
  const dispatch = useDispatch();
  const [zones, setZones] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(SettingActions.loadZonesAsync({}));
  }, []);
  const handleEdit = (id: string) => {
    navigate(`/settings/zone/form?id=${id}`);
  };
  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        Sale Zones
        <Button
          type="primary"
          onClick={() => navigate("/settings/zone/form")}
          icon={<PlusOutlined />}
        >
          Add New
        </Button>
      </div>

      <Table
        className="mt-4"
        bordered
        pagination={false}
        size="small"
        dataSource={settingState.zones}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "States",
            dataIndex: "states",
            key: "states",
            render: (states) => (states || []).join(", "),
          },
          {
            title: "Action",
            key: "action",
            render: (text, record) => (
              <Button
                size="small"
                type="link"
                icon={<EditOutlined />}
                onClick={() => handleEdit(record._id)}
              >
                Edit
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
}

export default ApiKeys;
