import { useUpdateEffect } from "ahooks";
import { useEffect, useState } from "react";

export default function useSelectedColumnsState({
  breadcrumbs,
  availableColumns,
  defaultSelected,
}) {
  const [selectedColumns, setSelectedColumns] = useState<any[]>(
    defaultSelected || []
  );
  useEffect(() => {
    const columnsFromLocalStorage = localStorage.getItem(
      `${breadcrumbs.map((b) => b.label).join(":")}`
    );
    if (columnsFromLocalStorage) {
      const keys = JSON.parse(columnsFromLocalStorage);
      setSelectedColumns(
        keys.map((t) => availableColumns.find((c) => c.key === t))
      );
    }
  }, []);
  useUpdateEffect(() => {
    localStorage.setItem(
      `${breadcrumbs.map((b) => b.label).join(":")}`,
      JSON.stringify(selectedColumns.map((c) => c.key))
    );
  }, [selectedColumns]);
  const handleSelectionChange = (item: any) => {
    const index = selectedColumns.findIndex((c) => c.key === item.key);
    if (index === -1) {
      setSelectedColumns([...selectedColumns, item]);
    } else {
      setSelectedColumns(
        [...selectedColumns].filter((s) => s.key !== item.key)
      );
    }
  };
  return { selectedColumns, setSelectedColumns: handleSelectionChange };
}
