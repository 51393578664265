import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/journal";

export const create = (data: any[]) => postRequest(`${SERVICE_URL}/`, data);
export const update = (data: any[]) => putRequest(`${SERVICE_URL}/`, data);
export const generateJournalID = () => getRequest(`${SERVICE_URL}/journal-id`);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);
export const get = (id: string) => getRequest(`${SERVICE_URL}/${id}`);
export const getDependencies = () => getRequest(`${SERVICE_URL}/dependencies`);
export const voidEntity = (id: string) =>
  putRequest(`${SERVICE_URL}/void/${id}`);
export default {
  create,
  paginate,
  get,
  update,
  getDependencies,
  voidEntity,
};
