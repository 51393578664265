import { Routes, Route } from "react-router-dom";
import ApiKey from "./ApiKey";
import Mapping from "./Mapping";
import Zone from "./Zone";
import SizeDistribution from "./SizeDistribution";
import LabelPrinters from "./LabelPrinters";
import Role from "./Role";
import User from "./User";
import CutOffStyleId from "./CutOffStyleId";
import RawMaterial from "./RawMaterial";
export const MODEL = "client";
export const BASE_PATH = "/clients";
export const TITLE = ["Client", "Clients"];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/api-key/*`} element={<ApiKey />} />
      <Route path={`/mapping/*`} element={<Mapping />} />
      <Route path={`/zone/*`} element={<Zone />} />
      <Route path={`/size-distribution/*`} element={<SizeDistribution />} />
      <Route path={`/label-printer/*`} element={<LabelPrinters />} />
      <Route path={`/raw-material/*`} element={<RawMaterial />} />
      <Route path={`/role/*`} element={<Role />} />
      <Route path={`/user/*`} element={<User />} />
      <Route path={`/cut-off-style-id/*`} element={<CutOffStyleId />} />
    </Routes>
  );
}
