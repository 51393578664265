import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from "../services/sku";
const TYPE = "SKU";

export interface SkuState {
  unmapped: any[];
  skus: any[];
  products: any[];
  stocks: any[];
  loading: boolean;
  selectedApiKey: any;
}

const initialState: SkuState = {
  unmapped: [],
  skus: [],
  products: [],
  stocks: [],
  loading: false,
  selectedApiKey: null,
};

export const getUnmappedSkusAsync = createAsyncThunk(
  `${TYPE}/GET_UNMAPPED_SKUS`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getUnmappedSkus();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadSkusAsync = createAsyncThunk(
  `${TYPE}/LOAD_SKUS`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getAll();
      thunkAPI.dispatch(getLatestStockAsync({}));
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadProductsAsync = createAsyncThunk(
  `${TYPE}/LOAD_PRODUCTS`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getAllProducts();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getLatestStockAsync = createAsyncThunk(
  `${TYPE}/GET_LATEST_STOCK`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getLatestStock();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const skuSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setUnmapped: (state, action) => {
      state.unmapped = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUnmappedSkusAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUnmappedSkusAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.unmapped = action.payload;
    });
    builder.addCase(getUnmappedSkusAsync.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadSkusAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadSkusAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.skus = action.payload;
    });
    builder.addCase(loadSkusAsync.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getLatestStockAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLatestStockAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.stocks = action.payload;
    });
    builder.addCase(getLatestStockAsync.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadProductsAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadProductsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.products = action.payload;
    });
    builder.addCase(loadProductsAsync.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setUnmapped } = skuSlice.actions;

export default skuSlice.reducer;
