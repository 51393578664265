import { useEffect, useState, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Select,
  Table,
  Space,
  Dropdown,
  Modal,
} from "antd";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";

import { debounce } from "throttle-debounce";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TITLE, BASE_PATH } from ".";
import service from "../../services/sku";
import poService from "../../services/po";

import useSearchParamsTableState from "../../hooks/useSearchParamsTableState";
import { useSelector } from "react-redux";
import { Store } from "../../store";

const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const MASTER_KEYS = [];

export default function Entities(props: any) {
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({ onFetch: service.paginate });
  const navigate = useNavigate();
  useEffect(() => {
    loadMastersDebounced();
  }, []);
  const [pendingPOs, setPendingPOs] = useState<any[]>([]);
  const [masters, setMasters] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSku, setSelectedSku] = useState<any>(null);

  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);

  const columns: any[] = [
    { title: "ID", dataIndex: "id", key: "id" },
    { title: "SKU Code", dataIndex: "skuCode", key: "skuCode" },
    { title: "Selling Price", dataIndex: "sellingPrice", key: "sellingPrice" },
    { title: "MRP Price", dataIndex: "mrpPrice", key: "mrpPrice" },
    {
      title: "Stock",
      dataIndex: "stock",
      key: "stock",
      render: (stock: any) => stock?.inStock || 0,
    },
    {
      key: "sjitStock",
      title: "SJIT Stock",
      dataIndex: "sjitStock",
      render: (sjitStock: any) => sjitStock?.quantity || 0,
    },
    {
      key: "pendingPOs",
      title: "Pending Qty",
      render: (_, record: any) => {
        const pending = pendingPOs?.filter(
          (po) => po.skuCode === record.skuCode
        );
        const totalPending =
          pending?.reduce((acc, po) => acc + po.pending, 0) || 0;

        return (
          <a
            onClick={() => {
              setSelectedSku(record.skuCode);
              setIsModalOpen(true);
            }}
          >
            {totalPending}
          </a>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text: string) => dayjs(text).format("DD/MM/YY hh:mm A"),
    },
  ];

  const handleView = (entityId?: string) => {
    navigate(`${BASE_PATH}/${entityId}`);
  };

  useEffect(() => {
    if (data?.list.length > 0) {
      const ids = data.list.map((entity: any) => entity.skuCode);
      poService.getPendingQuantityForSKUs(ids).then((pos) => {
        setPendingPOs(pos);
      });
    }
  }, [data?.list]);

  const PendingPOsTable = () => {
    const filteredPOs = pendingPOs.filter((po) => po.skuCode === selectedSku);

    const columns = [
      {
        title: "PO ID",
        dataIndex: "id",
        key: "id",
        render: (id: string, record: any) => (
          <a
            onClick={() =>
              window.open(`/purchases/purchase-orders/${record._id}`, "_blank")
            }
          >
            {id}
          </a>
        ),
      },
      {
        title: "Vendor",
        dataIndex: "vendor",
        key: "vendor",
        render: (vendor: any) => vendor?.name,
      },
      {
        title: "Ordered Qty",
        dataIndex: "ordered",
        key: "ordered",
      },
      {
        title: "Received Qty",
        dataIndex: "received",
        key: "received",
      },
      {
        title: "Pending Qty",
        dataIndex: "pending",
        key: "pending",
      },
    ];

    return (
      <Table
        dataSource={filteredPOs}
        columns={columns}
        pagination={false}
        rowKey="_id"
        size="small"
      />
    );
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={3}>All {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col>
          <div className="flex gap-x-2">
            <Input.Search
              placeholder="Search"
              onChange={(e) => setInput(e.target.value)}
              value={input}
            />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                navigate(`${BASE_PATH}/sjit-stock`);
              }}
            >
              Add SJIT Inventory
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={data?.list}
            columns={columns}
            pagination={{
              ...(tableState?.pagination || {}),
              total: data?.queryCount,
            }}
            bordered
            onChange={(pagination, filters, sorter) => {
              delete (sorter as any).column;
              setTableState({ pagination, filters, sorter });
            }}
            rowKey={(record) => record._id}
            size="small"
          />
        </Col>
      </Row>
      <Modal
        title="Pending Purchase Orders"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={800}
      >
        <PendingPOsTable />
      </Modal>
    </div>
  );
}
