import {
  Button,
  DatePicker,
  Form,
  Input,
  Space,
  TimePicker,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../../store";
import { useState } from "react";
import settingService from "../../../services/settings";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import * as SettingActions from "../../../store/settingSlice";
function EntityForm(props: any) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settingState = useSelector((state: Store) => state.setting);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      await settingService.createApiKey({
        ...values,
        expiresAt: dayjs(values.expiresAt.date)
          .set("hour", values.expiresAt.time.hour())
          .set("minute", values.expiresAt.time.minute()),
      });
      dispatch(SettingActions.getSelectedApiKeyAsync({}));
      navigate("/settings/api-key");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        New API Key
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="mt-4"
        initialValues={{
          expiresAt: {
            date: dayjs().add(1, "month"),
            time: dayjs().add(1, "month"),
          },
        }}
      >
        <Form.Item
          label="Key"
          name="key"
          rules={[{ required: true, message: "Key is required" }]}
        >
          <Input className="w-[350px]" placeholder="Enter Key from OMS" />
        </Form.Item>
        <div className="flex gap-x-2 items-end">
          <Form.Item label="Expires At" name={["expiresAt", "date"]}>
            <DatePicker />
          </Form.Item>
          <Form.Item name={["expiresAt", "time"]}>
            <TimePicker format={"hh:mm A"} />
          </Form.Item>
        </div>
        <Tooltip
          title="Creating new API Key will invalidate and deactivate all other API keys"
          placement="right"
        >
          <Form.Item className="w-max" noStyle>
            <Space>
              <Button onClick={() => navigate("/settings/api-key")}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Tooltip>
      </Form>
    </div>
  );
}

export default EntityForm;
