import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from "../services/settings";
const TYPE = "PURCHASE_WAREHOUSE";

export interface WarehouseState {
  warehouses: any[];
  loading: boolean;
  selected: any;
}

const initialState: WarehouseState = {
  warehouses: [],
  loading: false,
  selected: null,
};

export const loadWarehousesAsync = createAsyncThunk(
  `${TYPE}/LOAD`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getPurchaseWarehouses();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const warehouseSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setWarehouses: (state, action) => {
      state.warehouses = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
      localStorage.setItem("purchaseWarehouse", action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadWarehousesAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadWarehousesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.warehouses = action.payload;
    });
    builder.addCase(loadWarehousesAsync.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setWarehouses, setSelected } = warehouseSlice.actions;

export default warehouseSlice.reducer;
