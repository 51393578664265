import { Routes, Route } from "react-router-dom";
import Report from "./Report";
import List from "./List";
import View from "./View";
import Bulk from "./Bulk";
import BulkManual from "./BulkManual";
import Form from "./Form";
import OpenList from "./OpenList";
export const MODEL = "sku";
export const BASE_PATH = "/purchases/purchase-orders";
export const TITLE = ["Purchase Order", "Purchase Orders"];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/report`} element={<Report />} />
      <Route path={`/bulk`} element={<Bulk />} />
      <Route path={`/manual-bulk`} element={<BulkManual />} />
      <Route path={`/form`} element={<Form />} />
      <Route path={`/form/:id`} element={<Form />} />
      <Route path={`/:id`} element={<View />} />
      <Route path={`/open`} element={<OpenList />} />
      <Route path={`/`} element={<List />} />
    </Routes>
  );
}
