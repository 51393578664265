import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../../store";
import { Button, Form, Input, message, Select } from "antd";
import settingsService from "../../../services/settings";

import { useEffect } from "react";

const { Option } = Select;

export default function CutOffStyleId(props: any) {
  useEffect(() => {
    settingsService.getCutOffStyleId().then((res) => {
      form.setFieldsValue({ value: res.value });
    });
  }, []);
  const [form] = Form.useForm();
  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        Cut Off Style ID
      </div>
      <div className="mt-4">
        <Form
          form={form}
          onFinish={(values) => {
            settingsService.updateCutOffStyleId(values).then(() => {
              message.success("Cut Off Style ID updated successfully");
            });
          }}
        >
          <Form.Item name="value" label="Style ID">
            <Input />
          </Form.Item>
          <Form.Item noStyle>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
