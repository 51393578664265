import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/credit-note";

export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);

export default {
  paginate,
};
