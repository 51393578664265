import { Form, Input, Button, message } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../store";
import * as authActions from "../store/authSlice";
import useQuery from "../hooks/useQuery";
import { useNavigate } from "react-router-dom";

export default function Login(props: any) {
  const navigate = useNavigate();
  const authState = useSelector((state: Store) => state.auth);
  const dispatch = useDispatch();
  const [loginForm] = Form.useForm();
  let query = useQuery();
  const handleContinue = async (values: any) => {
    try {
      (
        dispatch(
          authActions.loginAsync({
            username: values.username,
            password: values.password,
          })
        ) as any
      ).then((response) => {
        if (!response.error) {
          message.success("Login successful.");
          navigate("/dashboard");
        } else {
          message.error("Login failed.");
        }
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    if (query["session-expired"]) {
      message.error("Session expired. Please login again.");
    }
  }, [query]);

  return (
    <div className="flex flex-col bg-gradient-to-r from-violet-600 to-indigo-600 h-screen min-h-screen">
      <div className="absolute w-full">
        <div className="py-4 flex justify-center"></div>
      </div>
      <div className="flex flex-col items-center justify-center container mx-auto max-w-max grow h-full">
        <div className="flex flex-col bg-white rounded p-8 justify-around shadow">
          <div className="container mx-auto flex flex-col pt-1">
            <div className="text-center font-medium mb-6">
              Login to continue
            </div>
            <Form form={loginForm} onFinish={handleContinue} className="">
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Button
                block
                loading={authState.loading}
                htmlType="submit"
                type="primary"
                className="mt-2"
              >
                Continue
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
