import { Routes, Route } from "react-router-dom";
import List from "./List";
import ItemList from "./ItemList";
import SJITForm from "./SJITForm";
import SubmitSJITJob from "./SubmitSJITJob";
import SJITJobs from "./SJITJobs";
import ViewSJITJob from "./ViewSJITJob";
export const MODEL = "sku";
export const BASE_PATH = "/orders";
export const TITLE = ["Order", "Orders"];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/items`} element={<ItemList />} />
      <Route path={`/sjit`} element={<SubmitSJITJob />} />
      <Route path={`/sjit-jobs`} element={<SJITJobs />} />
      <Route path={`/sjit-jobs/:id`} element={<ViewSJITJob />} />
      <Route path={`/`} element={<List />} />
    </Routes>
  );
}
