// src/hooks/useAllowedModules.ts
import { useMemo } from "react";
import type { MenuProps } from "antd";

type Permissions = {
  [module: string]: {
    [action: string]: boolean;
  };
};

type MenuItem = Required<MenuProps>["items"][number];

/**
 * Filters the menu items based on user permissions.
 *
 * @param permissions - The user's permissions object.
 * @param items - The complete list of menu items.
 * @returns The list of allowed menu items.
 */
const useAllowedModules = (
  permissions: Permissions,
  items: MenuItem[]
): MenuItem[] => {
  /**
   * Extracts modules that have at least one permission set to true.
   *
   * @param permissions - The user's permissions object.
   * @returns An array of module keys with at least one true permission.
   */
  const getModulesWithAtLeastOneTrue = (permissions: Permissions): string[] => {
    return Object.keys(permissions).filter((module) => {
      const actions = permissions[module];
      return Object.values(actions).some((permission) => permission === true);
    });
  };

  /**
   * Processes the menu items to include only those allowed by permissions.
   *
   * @param permittedKeys - The list of permitted module keys.
   * @param items - The complete list of menu items.
   * @returns The filtered list of allowed menu items.
   */
  const filterAllowedModules = (
    permittedKeys: string[],
    items: MenuItem[]
  ): MenuItem[] => {
    const reducedKeys = new Set(
      items
        .reduce((acc, item: any) => {
          if (item.children) {
            return acc.concat(item.children || []);
          }
          return acc.concat(item);
        }, [] as MenuItem[])
        .map((item) => {
          const tokens = (item.key as string)
            .split("/")
            .filter((token) => token !== "");
          return tokens[tokens.length - 1];
        })
    );

    const allowedKeys = permittedKeys.filter((key) => reducedKeys.has(key));

    return items
      .filter((item: any) => {
        return (
          allowedKeys.includes(item.key.toString()) ||
          (item.children?.some((child: any) => {
            const tokens = (child.key as string)
              .split("/")
              .filter((token) => token !== "");
            return allowedKeys.includes(tokens[tokens.length - 1]);
          }) ??
            false)
        );
      })
      .map((item: any) => {
        if (item.children) {
          return {
            ...item,
            children: item.children.filter((child: any) => {
              const tokens = (child.key as string)
                .split("/")
                .filter((token) => token !== "");
              return allowedKeys.includes(tokens[tokens.length - 1]);
            }),
          };
        }
        return item;
      });
  };

  return useMemo(() => {
    if (!permissions) return [];

    const permittedKeys = getModulesWithAtLeastOneTrue(permissions);
    const allowedModules = filterAllowedModules(permittedKeys, items);

    return allowedModules;
  }, [permissions, items]);
};

export default useAllowedModules;
