import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/settings";

export const getApiKeys = () => getRequest(`${SERVICE_URL}/api-key/all`);
export const createApiKey = (payload) =>
  postRequest(`${SERVICE_URL}/api-key`, payload);
export const createSizeDistribution = (payload) =>
  postRequest(`${SERVICE_URL}/size-distribution`, payload);
export const updateSizeDistribution = (payload) =>
  putRequest(`${SERVICE_URL}/size-distribution`, payload);
export const createCutOffStyleId = (payload) =>
  postRequest(`${SERVICE_URL}/cut-off-style-id`, payload);
export const updateCutOffStyleId = (payload) =>
  putRequest(`${SERVICE_URL}/cut-off-style-id`, payload);
export const validateApiKey = () =>
  getRequest(`${SERVICE_URL}/api-key/validate`);
export const getMappings = () => getRequest(`${SERVICE_URL}/mapping/all`);
export const getFulfillmentCenters = () =>
  getRequest(`${SERVICE_URL}/fulfillment-center/all`);
export const uploadBulk = (formData: FormData) =>
  postRequest(`${SERVICE_URL}/mapping/upload`, formData);
export const processBulk = (formData: FormData) =>
  postRequest(`${SERVICE_URL}/mapping/process`, formData);
export const createZone = (payload) =>
  postRequest(`${SERVICE_URL}/zone`, payload);
export const updateZone = (payload) =>
  putRequest(`${SERVICE_URL}/zone`, payload);
export const getMappingForSkuCode = (skuCode: string) =>
  getRequest(`${SERVICE_URL}/mapping/sku/${skuCode}`);
export const getMappingForSkuCodes = (skuCodes: string[]) =>
  postRequest(`${SERVICE_URL}/mapping/sku`, { skuCodes });
export const getZones = () => getRequest(`${SERVICE_URL}/zone/all`);
export const getZone = (id: string) => getRequest(`${SERVICE_URL}/zone/${id}`);
export const getChannels = () => getRequest(`${SERVICE_URL}/channel/all`);
export const getBrands = () => getRequest(`${SERVICE_URL}/brand/all`);
export const getSeasons = () => getRequest(`${SERVICE_URL}/season/all`);
export const getCategories = () => getRequest(`${SERVICE_URL}/category/all`);
export const paginateMappings = (qs: string) =>
  getRequest(`${SERVICE_URL}/mapping?${qs}`);

export const getSizeDistributions = () =>
  getRequest(`${SERVICE_URL}/size-distribution/all`);
export const getSizeDistribution = (id: string) =>
  getRequest(`${SERVICE_URL}/size-distribution/${id}`);
export const getCutOffStyleId = () =>
  getRequest(`${SERVICE_URL}/cut-off-style-id/`);

export const getUnrecognizedSkuCodes = (rows: any[]) =>
  postRequest(`${SERVICE_URL}/mapping/validate-sku-codes`, { rows });
export const getPurchaseWarehouses = () =>
  getRequest(`${SERVICE_URL}/purchase-warehouse/all`);
export const validateWarehouses = (warehouses: string[]) =>
  postRequest(`${SERVICE_URL}/purchase-warehouse/validate`, { warehouses });
export const exportMappings = () =>
  postBlobRequest(`${SERVICE_URL}/mapping/export`);
export default {
  getApiKeys,
  validateApiKey,
  createApiKey,
  uploadBulk,
  getMappings,
  processBulk,
  createZone,
  updateZone,
  getZones,
  getZone,
  getChannels,
  getBrands,
  getSeasons,
  getCategories,
  getSizeDistributions,
  createSizeDistribution,
  updateSizeDistribution,
  getSizeDistribution,
  getMappingForSkuCode,
  getMappingForSkuCodes,
  getUnrecognizedSkuCodes,
  getPurchaseWarehouses,
  validateWarehouses,
  exportMappings,
  createCutOffStyleId,
  updateCutOffStyleId,
  getCutOffStyleId,
  paginateMappings,
  getFulfillmentCenters,
};
