import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import service from "../services/po";
import skuService from "../services/sku";
import { FilePdfOutlined } from "@ant-design/icons";
import getDocDefinition from "../utils/getPODocDefinition";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function PurchaseOrderPDFModal(props: any) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [data, setData] = useState<any>();
  const showModal = async () => {
    if (!data) return;

    const { skus } = data;

    const skuCodes = skus
      .map((sku: any) => sku.quantities)
      .flat()
      .map((quantity: any) => quantity.skuCode);

    const mappings = await Promise.all(
      skuCodes.map((skuCode: string) => skuService.getMappingForSku(skuCode))
    );

    for (let i = 0; i < skus.length; i++) {
      const sku = skus[i];
      sku.mapping = mappings[i];
    }

    // Define the PDF document
    const docDefinition: any = getDocDefinition(data);

    // Generate the PDF
    pdfMake.createPdf(docDefinition).getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
      setIsModalVisible(true);
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setPdfUrl(null);
  };

  useEffect(() => {
    if (props.id) {
      service.get(props.id).then((res) => {
        console.log(res);

        setData(res);
        showModal();
      });
    }
  }, [props.id]);

  return (
    <>
      <Button type="primary" onClick={showModal} icon={<FilePdfOutlined />}>
        Show PDF
      </Button>
      <Modal
        title="PDF Viewer"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        style={{ top: 20 }}
      >
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            style={{ width: "100%", height: "1020px", border: "none" }}
          />
        )}
      </Modal>
    </>
  );
}
