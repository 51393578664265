import { message } from "antd";
import { getDD as getDefaultDD } from "../templates/vendor";
import { PDFDocument } from "pdf-lib";

import getPDF from "../utils/getPDF";
import getBarcode from "../utils/getBarcode";

function base64ToArrayBuffer(base64: string) {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

function arrayBufferToBase64(buffer: ArrayBuffer) {
  const uint8Array = new Uint8Array(buffer);
  const chunkSize = 0x8000; // 32KB chunks to avoid too many arguments error
  let binary = "";

  for (let i = 0; i < uint8Array.length; i += chunkSize) {
    const chunk = uint8Array.subarray(i, i + chunkSize);
    binary += String.fromCharCode(...chunk);
  }

  return window.btoa(binary);
}

async function mergePDFs(base64Pdfs: string[]) {
  // Create a new PDF document
  const mergedPdf = await PDFDocument.create();

  // Convert base64 to ArrayBuffer and load the PDFs
  for (const base64 of base64Pdfs) {
    const arrayBuffer = base64ToArrayBuffer(base64);
    const pdf = await PDFDocument.load(arrayBuffer);
    const pages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    pages.forEach((page) => {
      mergedPdf.addPage(page);
    });
  }

  // Serialize the merged PDF document to bytes
  const mergedPdfBytes = await mergedPdf.save();

  // Convert the merged PDF bytes to base64
  const mergedPdfBase64 = arrayBufferToBase64(mergedPdfBytes);

  return mergedPdfBase64;
}

export async function getForMultiple(items: any[]) {
  if (!items || items.length === 0) {
    return message.error(
      "Products were not found. Please refresh and try again."
    );
  }

  const docArray = [];

  const batchedItems = [];
  for (let i = 0; i < items.length; i += 2) {
    batchedItems.push(items.slice(i, i + 2)); // Group items in batches of 2
  }

  await Promise.all(
    batchedItems.map(async (batch) => {
      // Fetch barcode for each item in the batch concurrently
      await Promise.all(
        batch.map(async (item: any) => {
          const barcodeImage = await getBarcode(item.skuCode);
          item.barcodeImage = barcodeImage;
        })
      );

      const dd = getDefaultDD(batch);
      const doc = await getPDF(dd);
      docArray.push(doc);
    })
  );

  // Merge PDFs
  const mergedPdf = await mergePDFs(docArray);

  console.log(mergedPdf);

  return mergedPdf;
}
