import { Row, Col, Typography, Button, Space, Table, Modal, Tag } from "antd";
import { BASE_PATH, TITLE } from ".";
import {
  PlusOutlined,
  EyeOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useSearchParamsTableState from "../../hooks/useSearchParamsTableState";
import service from "../../services/vendorDebitNote";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Store } from "../../store";
import { useCallback, useEffect, useState } from "react";
import vendorService from "../../services/vendor";
import supplierService from "../../services/supplier";
import { debounce } from "throttle-debounce";
import VDNPDFModal from "../../components/VDNPDFModal";
const { Title } = Typography;
const MASTER_KEYS = [
  {
    service: vendorService,
    value: "vendors",
  },
  {
    service: supplierService,
    value: "suppliers",
  },
];
export default function Entities(props: any) {
  const navigate = useNavigate();
  const [voidingRecord, setVoidingRecord] = useState<any>();

  const clientState = useSelector((state: Store) => state.client);
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({
      onFetch: service.paginate,
      defaultFilters: {
        client: [clientState.current],
      },
    });

  useEffect(() => {
    if (tableState) {
      setTableState({
        ...tableState,
        filters: {
          ...tableState.filters,
          client: [clientState.current],
        },
      });
    }
  }, [clientState]);
  const [masters, setMasters] = useState<any>({});

  useEffect(() => {
    loadMastersDebounced();
  }, []);
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);
  const columns = [
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      filters: masters.suppliers?.map((v: any) => ({
        text: v.name,
        value: v._id,
      })),
      filterSearch: true,
      render: (supplier, record) => (
        <Space>
          {supplier?.name} {supplier && !record.isActive && <Tag>Void</Tag>}
        </Space>
      ),
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      filters: masters.vendors?.map((v: any) => ({
        text: v.name,
        value: v._id,
      })),
      filterSearch: true,
      render: (vendor, record) => (
        <Space>
          {vendor?.name} {!record.isActive && <Tag>Void</Tag>}
        </Space>
      ),
    },
    { title: "Amount", dataIndex: "amount", key: "amount" },

    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (paymentDate) => dayjs(paymentDate).format("DD/MM/YYYY"),
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (startAt) => dayjs(startAt).format("DD/MM/YY hh:mm A"),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <VDNPDFModal data={record} />
          {record.isActive && (
            <Button
              type="link"
              onClick={() => {
                setVoidingRecord(record);
              }}
              icon={<CloseCircleOutlined />}
            >
              Void
            </Button>
          )}
          <Button
            type="link"
            onClick={() => navigate(`${BASE_PATH}/form/${record._id}`)}
            icon={<EditOutlined />}
          >
            Edit
          </Button>
          <div
            onClick={() => navigate(`${BASE_PATH}/${record._id}`)}
            className="text-blue-500 flex gap-x-2 items-baseline cursor-pointer"
          >
            <EyeOutlined /> View
          </div>
        </Space>
      ),
    },
  ];

  const handleVoid = () => {
    service
      .voidEntity(voidingRecord._id)
      .then(onRefresh)
      .finally(() => setVoidingRecord(undefined));
  };

  return (
    <div>
      <Modal
        title="Void Debit Note"
        open={!!voidingRecord}
        onOk={handleVoid}
        onCancel={() => setVoidingRecord(undefined)}
      >
        <p>Are you sure you want to void this Debit Note?</p>
      </Modal>
      <Row justify="space-between">
        <Col>
          <Title level={3}>All {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col>
          <Space>
            <Button
              type="primary"
              onClick={() => navigate(`${BASE_PATH}/form`)}
              icon={<PlusOutlined />}
            >
              Add New
            </Button>
          </Space>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={data?.list}
            columns={columns}
            pagination={{
              ...(tableState?.pagination || {}),
              total: data?.queryCount,
            }}
            bordered
            onChange={(pagination, filters, sorter) => {
              delete (sorter as any).column;
              setTableState({
                pagination,
                filters: { ...filters, client: [clientState.current] },
                sorter,
              });
              setTableState({ pagination, filters, sorter });
            }}
            rowKey={(record) => record._id}
            size="small"
          />
        </Col>
      </Row>
    </div>
  );
}
