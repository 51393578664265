import { Routes, Route } from "react-router-dom";
import List from "./List";
import View from "./View";
import Form from "./Form";
export const MODEL = "sku";
export const BASE_PATH = "/raw-material-purchase-orders";
export const TITLE = [
  "Raw Material Purchase Order",
  "Raw Material Purchase Orders",
];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/form`} element={<Form />} />
      <Route path={`/form/:id`} element={<Form />} />
      <Route path={`/:id`} element={<View />} />
      <Route path={`/`} element={<List />} />
    </Routes>
  );
}
