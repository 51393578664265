import { Button, Col, Row } from "antd";
import skuService from "../../../services/sku";
import { Typography } from "antd";
import { useState } from "react";
import saveAs from "file-saver";
import { DownloadOutlined } from "@ant-design/icons";
const { Title } = Typography;
function MasterSKUReport() {
  const [loading, setLoading] = useState(false);
  const handleExport = async () => {
    try {
      setLoading(true);
      const file = await skuService.exportToExcel();
      saveAs(file, "skus.xlsx");
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={3}>Master SKU Report</Title>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <div className="mt-4">
        <Button
          type="primary"
          onClick={() => handleExport()}
          loading={loading}
          icon={<DownloadOutlined />}
        >
          Download Report
        </Button>
      </div>
    </div>
  );
}

export default MasterSKUReport;
