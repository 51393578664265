import { Routes, Route } from "react-router-dom";
import MasterSKUReport from "./MasterSKUReport";
import PendingPOReport from "./PendingPOReport";
import POSummaryReport from "./POSummaryReport";
import GRNSummaryReport from "./GRNSummaryReport";

export const BASE_PATH = "/reports";
export const TITLE = ["Report", "Reports"];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/sku-report`} element={<MasterSKUReport />} />
      <Route path={`/pending-po-report`} element={<PendingPOReport />} />
      <Route path={`/po-summary-report`} element={<POSummaryReport />} />
      <Route path={`/grn-summary-report`} element={<GRNSummaryReport />} />
    </Routes>
  );
}
