import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../../store";
import { Button, Form, Select } from "antd";
import * as settingActions from "../../../store/settingSlice";
import * as labelPrinterActions from "../../../store/labelPrinterSlice";

const { Option } = Select;

export default function LabelPrinters(props: any) {
  const settingState = useSelector((state: Store) => state.setting);
  const labelPrinterState = useSelector((state: Store) => state.labelPrinter);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        Label Printers
      </div>
      <div className="mt-4">
        <Form
          form={form}
          onFinish={(values) =>
            dispatch(labelPrinterActions.setLabelPrinter(values.labelPrinter))
          }
        >
          <Form.Item label="Label Printer" name="labelPrinter">
            <Select>
              {labelPrinterState.labelPrinters.map((printer: any) => (
                <Option key={printer} value={printer}>
                  {printer}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
