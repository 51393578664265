import { useEffect, useState, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Select,
  Table,
  Space,
  Popover,
  List,
  Checkbox,
  Form,
  Modal,
  Tag,
  Switch,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  ExportOutlined,
} from "@ant-design/icons";

import { debounce } from "throttle-debounce";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TITLE, BASE_PATH } from ".";
import service from "../../services/invoice";

import useSearchParamsTableState from "../../hooks/useSearchParamsTableState";
import { useSelector } from "react-redux";
import { Store } from "../../store";
import OrderItemsModal from "../../components/OrderItemsModal";

const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const MASTER_KEYS = [];

export default function Entities(props: any) {
  const breadcrumbsRef = useRef([{ label: TITLE[1], url: BASE_PATH }]);
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({ onFetch: service.paginate });
  const navigate = useNavigate();
  const [resetPasswordVisible, setResetPasswordVisible] = useState("");
  const [toggleLoading, setToggleLoading] = useState(false);
  const [toggleKey, setToggleKey] = useState("");
  const [selectedClient, setSelectedClient] = useState<any>();
  const [selectedEntity, setSelectedEntity] = useState<any>();
  const clientState = useSelector((state: Store) => state.client);

  const warehouseState = useSelector((state: Store) => state.warehouse);
  useEffect(() => {
    loadMastersDebounced();
  }, []);
  const [masters, setMasters] = useState<any>({});
  useEffect(() => {
    if (tableState) {
      setTableState({
        ...tableState,
        filters: {
          ...tableState.filters,
          warehouse: [warehouseState.selected],
        },
      });
    }
  }, [warehouseState.selected]);
  const getColumnsFilters = useCallback(
    (key: string) => {
      return masters[key]
        ? masters[key].map((entity) => ({
            text: entity.name,
            value: entity._id,
          }))
        : [];
    },
    [masters]
  );
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);

  const columns: any[] = [
    {
      title: "Invoice ID",
      dataIndex: "invoiceId",
      key: "invoiceId",
      render: (text: string, record: any) => (
        <a
          onClick={() => {
            setSelectedEntity(record);
          }}
          className="flex items-baseline gap-x-2"
        >
          <ExportOutlined />
          {text || "Unassigned"}
        </a>
      ),
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (text: string) => (text ? dayjs(text).format("DD/MM/YYYY") : ""),
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (text: string) => (text ? dayjs(text).format("DD/MM/YYYY") : ""),
    },
    {
      title: "Shipment Date",
      dataIndex: "shipmentDate",
      key: "shipmentDate",
      render: (text: string) => (text ? dayjs(text).format("DD/MM/YYYY") : ""),
    },
    {
      title: "Delivered Date",
      dataIndex: "deliveredDate",
      key: "deliveredDate",
      render: (text: string) => (text ? dayjs(text).format("DD/MM/YYYY") : ""),
    },
    {
      title: "Warehouse",
      dataIndex: "warehouseId",
      key: "warehouseId",
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Buyer Name",
      dataIndex: "buyerName",
      key: "buyerName",
    },
    {
      title: "Buyer Address",
      dataIndex: "buyerAddress1",
      key: "buyerAddress1",
    },
    {
      title: "Buyer City",
      dataIndex: "buyerCity",
      key: "buyerCity",
    },
    {
      title: "Buyer State",
      dataIndex: "buyerState",
      key: "buyerState",
    },
    {
      title: "Buyer Pincode",
      dataIndex: "buyerPincode",
      key: "buyerPincode",
    },
    {
      title: "Buyer Phone",
      dataIndex: "buyerPhone",
      key: "buyerPhone",
    },
    {
      title: "Buyer Email",
      dataIndex: "buyerEmail",
      key: "buyerEmail",
    },
    {
      title: "GST Name",
      dataIndex: "gstName",
      key: "gstName",
    },
    {
      title: "GST Number",
      dataIndex: "gstNumber",
      key: "gstNumber",
    },
    {
      title: "Billing Name",
      dataIndex: "billingName",
      key: "billingName",
    },
    {
      title: "Billing Address",
      dataIndex: "billingAddress1",
      key: "billingAddress1",
    },
    {
      title: "Billing City",
      dataIndex: "billingCity",
      key: "billingCity",
    },
    {
      title: "Billing State",
      dataIndex: "billingState",
      key: "billingState",
    },
    {
      title: "Billing Pincode",
      dataIndex: "billingPincode",
      key: "billingPincode",
    },
    {
      title: "Billing Phone",
      dataIndex: "billingPhone",
      key: "billingPhone",
    },
    {
      title: "Billing Email",
      dataIndex: "billingEmail",
      key: "billingEmail",
    },
    {
      title: "Shipping Company",
      dataIndex: "shippingCompany",
      key: "shippingCompany",
    },
    {
      title: "Shipment Tracker",
      dataIndex: "shipmentTracker",
      key: "shipmentTracker",
    },
    {
      title: "Last ID",
      dataIndex: "lastId",
      key: "lastId",
    },
    {
      title: "Order Type",
      dataIndex: "orderType",
      key: "orderType",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => dayjs(text).format("DD/MM/YYYY"),
    },
  ];

  const handleView = (entityId?: string) => {
    navigate(`${BASE_PATH}/${entityId}`);
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={3}>All {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      {/* <Row justify="space-between" className="p-4">
        <Col className="flex">
          <Search
            placeholder={`Search ${TITLE[1]}`}
            enterButton={<SearchOutlined />}
            size="middle"
            onChange={(event) => {
              setInput(event.target.value);
            }}
            value={input}
          />
        </Col>
        <Col>
          {data?.queryCount !== data?.totalCount && (
            <Text className="flex items-center">
              Showing {data.queryCount} of {data?.totalCount} entries
              <Button
                type="link"
                onClick={() => {
                  setInput("");
                }}
              >
                Clear Search
              </Button>
            </Text>
          )}
        </Col>
        <Col></Col>
      </Row> */}
      <Row className="mt-2">
        <Col span={24}>
          <div className="overflow-data-table">
            <Table
              loading={loading}
              dataSource={data?.list}
              columns={columns}
              pagination={{
                ...(tableState?.pagination || {}),
                total: data?.queryCount,
              }}
              bordered
              className="overflow-table"
              onChange={(pagination, filters, sorter) => {
                delete (sorter as any).column;
                setTableState({
                  pagination,
                  filters: { ...filters, client: [clientState.current] },
                  sorter,
                });
                setTableState({ pagination, filters, sorter });
              }}
              rowKey={(record) => record._id}
              size="small"
            />
          </div>
        </Col>
      </Row>
      <OrderItemsModal
        open={!!selectedEntity}
        data={selectedEntity}
        type="invoice"
        onCancel={() => setSelectedEntity(undefined)}
      />
    </div>
  );
}
