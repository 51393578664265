import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../store";
import * as warehouseActions from "../store/warehouseSlice";
import { memo, useEffect } from "react";

function OMSWarehouseSelect(props: any) {
  const clientState = useSelector((state: Store) => state.client);
  const warehouseState = useSelector((state: Store) => state.warehouse);
  const dispatch = useDispatch();

  useEffect(() => {
    if (clientState.current && warehouseState.warehouses.length > 0) {
      const currentClient = clientState.clients.find(
        (c) => c._id === clientState.current
      );
      const defaultWarehouse = warehouseState.warehouses.find((w) =>
        w.name.match(new RegExp(`^Default ${currentClient?.id}$`, "i"))
      );
      dispatch(warehouseActions.setSelected(defaultWarehouse?._id));
      props.onChange?.(defaultWarehouse?._id);
    }
  }, [clientState.current, warehouseState.warehouses]);

  return (
    <div className="flex gap-x-2 items-baseline">
      {props.hideLabel ? undefined : <div>OMS Warehouse: </div>}
      <Select
        style={{ width: 300 }}
        allowClear={false}
        placeholder="Select Warehouse"
        loading={warehouseState.loading}
        value={warehouseState.selected}
        onChange={(value) => {
          dispatch(warehouseActions.setSelected(value));
          props.onChange?.(value);
        }}
      >
        {warehouseState.warehouses.length &&
          warehouseState.warehouses?.map((warehouse) => (
            <Select.Option value={warehouse._id} key={warehouse._id}>
              {warehouse.name} ({warehouse.id})
            </Select.Option>
          ))}
      </Select>
    </div>
  );
}

export default memo(OMSWarehouseSelect);
