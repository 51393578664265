import bwipjs from "bwip-js";
import { Canvg } from "canvg";
async function canvasToSVGString(canvas) {
  const ctx = canvas.getContext("2d");
  const svgString = `<svg xmlns="http://www.w3.org/2000/svg" width="${canvas.width}" height="${canvas.height}">
    <foreignObject width="100%" height="100%">
      <canvas xmlns="http://www.w3.org/1999/xhtml" width="${canvas.width}" height="${canvas.height}"></canvas>
    </foreignObject>
  </svg>`;

  const canvgInstance: any = Canvg.fromString(ctx, svgString);
  canvgInstance.start();

  return new Promise((resolve) => {
    canvgInstance.ready(() => {
      resolve(canvgInstance.getSerializedSvg());
    });
  });
}
export default async function generateBarcode(data) {
  return new Promise((resolve, reject) => {
    // Create an off-screen canvas
    const canvas = document.createElement("canvas");
    try {
      // The return value is the canvas element
      (bwipjs as any).toCanvas(canvas, {
        bcid: "code128", // Barcode type
        text: data, // Text to be encoded
        scale: 2, // Scaling factor
        height: 15, // Bar height in millimeters
        includetext: false, // Include human-readable text
        textxalign: "center", // Text alignment
        backgroundcolor: "FFFFFF", // Background color
      });
      const imageString = canvas.toDataURL("image/png");
      resolve(imageString);
    } catch (e) {
      console.log(e);
      reject(e);
      // `e` may be a string or Error object
    }
  });
}
