import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  message,
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import service from "../../services/vendorDebitNote";
import { BASE_PATH, TITLE } from ".";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import dayjs from "dayjs";
import skuService from "../../services/sku";
import {
  PlusOutlined,
  DeleteOutlined,
  CheckOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import * as poActions from "../../store/poSlice";

export default function EntityForm(props: any) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const poState = useSelector((state: Store) => state.po);
  const skuState = useSelector((state: Store) => state.sku);
  const isAddingDetail = Form.useWatch("isAddingDetail", form);
  const [vendorSkus, setVendorSkus] = useState<any[]>([]);
  const skus = Form.useWatch("skus", form);
  const amount = Form.useWatch("amount", form);
  const vendor = Form.useWatch("vendor", form);
  const barcode = Form.useWatch("barcode", form);
  const defaultPrices = useRef({});
  const [hasDispersalError, setHasDispersalError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const type = Form.useWatch("type", form);

  const addRef = useRef<any>();
  const totalDispersed =
    skus?.reduce(
      (acc, curr) => acc + (curr?.quantity || 0) * (curr?.rate || 0),
      0
    ) || 0;

  useEffect(() => {
    if (params.id) {
      setId(params.id);
      service.get(params.id).then((res) => {
        form.setFieldsValue({
          ...res,
          vendor: res.vendor?._id,
          supplier: res.supplier?._id,
          skus: res.skus.map((s) => ({ ...s, sku: s.sku._id })),

          date: dayjs(res.date),
        });
        setData(res);
      });
    }
  }, [params.id]);

  useEffect(() => {
    if (!vendor) {
      return setVendorSkus([]);
    }
    const vendorObject = poState.vendors.data.find((v) => v._id === vendor);
    const vendorProducts = poState.productsForClient.data.filter((p) =>
      vendorObject?.products.includes(p._id)
    );
    setVendorSkus(vendorProducts.map((p) => p.skus).flat());
  }, [poState.productsForClient, vendor, poState.vendors.data]);

  const handleFinish = async (values: any) => {
    try {
      const hasDispersalError = totalDispersed !== amount;
      if (isAddingDetail && hasDispersalError) {
        setHasDispersalError(true);
        return message.error("Amount and Total Dispersed do not match");
      }

      if (id) {
        await service.update({ _id: id, ...values });
      } else {
        await service.create(values);
      }
      navigate(BASE_PATH);
    } catch (error) {
      message.error(error.message);
      throw error;
    }
  };

  useEffect(() => {
    const totalDispersed =
      skus?.reduce(
        (acc, curr) => acc + (curr?.quantity || 0) * (curr?.rate || 0),
        0
      ) || 0;
    form.setFieldValue("amount", totalDispersed);
  }, [skus]);

  const handleInsertSku = () => {
    if (!vendorSkus || vendorSkus.length === 0) {
      message.error("No SKUs found for the vendor or Vendor not selected");
      return;
    }

    const sku = vendorSkus.find(
      (s) => s.skuCode === barcode || s.encodedSkuCode === barcode
    );
    if (sku) {
      const skus = form.getFieldValue("skus") || [];
      const nextIndex = skus.length;
      skus.push({ sku: sku._id, quantity: 1, rate: 0 });
      setDefaultPrice(nextIndex, sku._id);
      form.setFieldsValue({ skus, barcode: "" });
    } else {
      form.setFields([{ name: "barcode", errors: ["SKU not found"] }]);
    }
  };

  const setDefaultPrice = async (index, value) => {
    const sku = skuState.skus.find((s) => s._id === value);
    const mapping = await skuService.getMappingForSku(sku.skuCode);
    const price = mapping?.purchasePrice || 0;
    if (price !== 0) {
      const skus = form.getFieldValue("skus");
      skus[index].rate = price;
      form.setFieldsValue({ skus });
      defaultPrices.current[index] = price;
    }
  };
  const getTableData = () => {
    const skuList = skuState.skus; // All SKU data
    const skuMap = {};
    skuList.forEach((sku) => {
      skuMap[sku._id] = sku;
    });

    const productData = {};
    const allSizes = new Set();

    if (skus && skus.length > 0) {
      skus.forEach((item) => {
        const skuId = item.sku;
        const quantity = item.quantity;

        const sku = skuMap[skuId];

        if (!sku) return;
        const tokens = sku.skuCode.split("_");
        const size = tokens.pop();

        const productCode = tokens.join("_"); // Assuming sku has 'productCode' field

        allSizes.add(size);

        if (!productData[productCode]) {
          productData[productCode] = {};
        }

        if (!productData[productCode][size]) {
          productData[productCode][size] = 0;
        }

        productData[productCode][size] += quantity;
      });
    }

    const sizeColumns = Array.from(allSizes).sort();

    // Now, create an array of data for the table
    const dataSource = Object.keys(productData).map((productCode, index) => {
      const row: any = {
        key: index,
        productCode,
      };
      sizeColumns.forEach((size: string) => {
        row[size] = productData[productCode][size] || 0;
      });
      return row;
    });

    console.log("dataSource", dataSource);

    return { dataSource, sizeColumns };
  };

  const refreshVendors = () => {
    dispatch(poActions.fetchVendors());
    dispatch(poActions.fetchProductsForClient());
  };

  return (
    <div>
      <Title level={3}>
        {id ? "Edit" : "Add"} {TITLE[0]}
      </Title>
      <Form
        form={form}
        onFinish={handleFinish}
        className="mt-8"
        initialValues={{ date: dayjs(), type: "vendor" }}
        onFinishFailed={(e) => console.log(e)}
      >
        <Form.Item name="type" label="Type">
          <Radio.Group optionType="button" buttonStyle="solid">
            <Radio.Button value="vendor">Vendor</Radio.Button>
            <Radio.Button value="supplier">Supplier</Radio.Button>
          </Radio.Group>
        </Form.Item>
        {type === "supplier" ? (
          <Form.Item
            name="supplier"
            label="Supplier"
            rules={[{ required: true, message: "Please select a supplier" }]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "300px" }}
              loading={poState.suppliers.loading}
              placeholder="Select a supplier"
              allowClear
              disabled={!!id}
            >
              {poState.suppliers.data.map((supplier) => (
                <Select.Option key={supplier._id} value={supplier._id}>
                  {supplier.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : (
          <div className="flex  gap-x-2">
            <Form.Item name="vendor" label="Vendor">
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: "300px" }}
                loading={poState.vendors.loading}
                placeholder="Select a vendor"
                allowClear
                disabled={!!id}
              >
                {poState.vendors.data.map((vendor) => (
                  <Select.Option key={vendor._id} value={vendor._id}>
                    {vendor.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button
              icon={<SyncOutlined />}
              type="link"
              onClick={refreshVendors}
            />
          </div>
        )}
        <Form.Item
          name="amount"
          label="Amount"
          rules={[{ required: true, message: "Please enter the amount" }]}
        >
          <InputNumber
            type="number"
            style={{ width: "300px" }}
            placeholder="Enter Amount"
            prefix="₹"
          />
        </Form.Item>
        <Form.Item
          name="date"
          label="Date"
          rules={[{ required: true, message: "Please select a payment date" }]}
        >
          <DatePicker
            allowClear={false}
            style={{ width: "300px" }}
            format="DD/MM/YYYY"
          />
        </Form.Item>
        {type === "vendor" && (
          <Form.Item
            name="isAddingDetail"
            label="Add Detail"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        )}
        <Form.Item name="reference" label="Reference">
          <Input.TextArea
            style={{ width: "300px" }}
            placeholder="Enter Reference"
          />
        </Form.Item>
        {isAddingDetail && (
          <div className="flex gap-x-2">
            <Form.Item name="barcode" label="Barcode">
              <Input
                style={{ width: "300px" }}
                placeholder="Enter Barcode"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                    handleInsertSku();
                  }
                }}
              />
            </Form.Item>
            <Button
              icon={<CheckOutlined />}
              type="primary"
              onClick={() => handleInsertSku()}
            />
          </div>
        )}
        {isAddingDetail && (
          <div>
            <Form.List name="skus">
              {(fields, { add, remove }, { errors }) => {
                return (
                  <div className="p-2 border rounded">
                    <div className="flex justify-between items-center">
                      <span className="font-medium">Add Detail</span>
                      <Button
                        onClick={() => add({ quantity: 1, rate: 0 })}
                        type="primary"
                        size="small"
                        icon={<PlusOutlined />}
                      >
                        Add
                      </Button>
                    </div>
                    <Divider className="mt-1 mb-3" />
                    {fields.map((field, index) => (
                      <div className="flex gap-x-2" key={field.key}>
                        <Form.Item>{index + 1}.</Form.Item>
                        <Form.Item
                          {...field}
                          key={`${field.key}-sku`}
                          name={[field.name, "sku"]}
                          rules={[
                            { required: true, message: "SKU Code Missing" },
                          ]}
                          style={{ width: "20%" }}
                        >
                          <Select
                            variant="filled"
                            placeholder="Select SKU"
                            showSearch
                            optionFilterProp="label"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                add({ quantity: 1, rate: 0 });
                              }
                            }}
                            onChange={async (value) => {
                              await setDefaultPrice(index, value);
                            }}
                            options={vendorSkus.map((sku) => ({
                              label: sku.skuCode,
                              value: sku._id,
                            }))}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          key={`${field.key}-quantity`}
                          name={[field.name, "quantity"]}
                          rules={[
                            { required: true, message: "Quantity Missing" },
                          ]}
                          style={{ width: "15%" }}
                        >
                          <InputNumber
                            variant="filled"
                            placeholder="Enter Quantity"
                            className="w-full"
                            keyboard={false}
                          />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          key={`${field.key}-rate`}
                          name={[field.name, "rate"]}
                          rules={[{ required: true, message: "Rate Missing" }]}
                          style={{ width: "15%" }}
                        >
                          <InputNumber
                            variant="filled"
                            placeholder="Enter Price"
                            keyboard={false}
                            className="w-full"
                            prefix="₹"
                          />
                        </Form.Item>
                        {defaultPrices.current[index] !==
                          form.getFieldValue("skus")?.[index]?.rate && (
                          <Form.Item
                            key={`${field.key}-total`}
                            style={{ width: "20%" }}
                          >
                            {defaultPrices.current[field.name] && (
                              <Alert
                                type="info"
                                message={`Default Price: ₹${
                                  defaultPrices.current[field.name]
                                }`}
                                className="py-1 px-2"
                                action={
                                  <div
                                    className="ml-2 text-blue-500 cursor-pointer"
                                    onClick={() => {
                                      const skus = form.getFieldValue("skus");
                                      skus[index].rate =
                                        defaultPrices.current[index];
                                      form.setFieldsValue({ skus });
                                    }}
                                  >
                                    Set Default
                                  </div>
                                }
                              />
                            )}
                          </Form.Item>
                        )}
                        {form.getFieldValue("skus")?.[index]?.quantity &&
                        form.getFieldValue("skus")?.[index]?.rate !== 0 ? (
                          <Form.Item
                            key={`${field.key}-total`}
                            style={{ width: "10%" }}
                          >
                            ₹
                            {(
                              form.getFieldValue("skus")?.[index]?.quantity *
                              form.getFieldValue("skus")?.[index]?.rate
                            ).toLocaleString()}
                          </Form.Item>
                        ) : undefined}
                        <div>
                          <Button
                            onClick={() => remove(field.name)}
                            icon={<DeleteOutlined />}
                          ></Button>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              }}
            </Form.List>
          </div>
        )}
        {amount && totalDispersed !== amount && isAddingDetail ? (
          <div className="mt-4">
            <Alert
              showIcon
              type="error"
              message="Amount and Total Dispersed do not match"
              description={`Amount: ₹${amount.toLocaleString()} Total Dispersed: ₹${totalDispersed.toLocaleString()}`}
            />
          </div>
        ) : undefined}
        {hasDispersalError && (
          <Alert
            showIcon
            className="mt-4"
            type="error"
            message="Please resolve errors before submitting."
          />
        )}
        <Form.Item className="mt-4">
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            {isAddingDetail && (
              <Button type="link" onClick={() => setIsModalVisible(true)}>
                Show Report
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
      {/* Modal Component */}
      <Modal
        title="SKU Summary"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Table
          dataSource={getTableData().dataSource}
          columns={
            [
              {
                title: "Product Code",
                dataIndex: "productCode",
                key: "productCode",
              },
              // Dynamically add size columns
              ...getTableData().sizeColumns.map((size) => ({
                title: size,
                dataIndex: size,
                key: size,
                render: (text) => text || 0,
              })),
              {
                title: "Total Quantity",
                key: "total",
                render: (text, record) => {
                  return Object.values(record)
                    .filter((value) => Number.isInteger(value))
                    .reduce((acc: number, val: number) => acc + val, 0);
                },
              },
            ] as any
          }
          pagination={false}
        />
      </Modal>
    </div>
  );
}
