import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/raw-material";
export const create = (payload) => postRequest(SERVICE_URL, payload);
export const update = (payload) => putRequest(SERVICE_URL, payload);
export const get = (id: string) => getRequest(`${SERVICE_URL}/${id}`);
export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const getDependencies = () => getRequest(`${SERVICE_URL}/dependencies`);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);

export default {
  paginate,
  get,
  update,
  create,
  getAll,
  getDependencies,
};
