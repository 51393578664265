import NumberFormat from "react-number-format";
export default function Currency(props: any) {
  return (
    <NumberFormat
      value={props.value}
      displayType={"text"}
      thousandSeparator={true}
      prefix={"₹ "}
      fixedDecimalScale={true}
      decimalScale={2}
      className={props.className}
    />
  );
}
