import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/vendor";
export const create = (payload) => postRequest(SERVICE_URL, payload);
export const update = (payload) => putRequest(SERVICE_URL, payload);
export const get = (id: string) => getRequest(`${SERVICE_URL}/${id}`);
export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const checkCode = (code: string, _id: string) =>
  postRequest(`${SERVICE_URL}/check-code`, { code, _id });
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);
export const validateVendors = (vendors: string[]) =>
  postRequest(`${SERVICE_URL}/validate`, { vendors });
export const exportToExcel = () => postBlobRequest(`${SERVICE_URL}/export`);
export const getForIds = (clientAlias: string, ids: string[]) =>
  postRequest(`${SERVICE_URL}/ids`, { clientAlias, ids });

export default {
  paginate,
  get,
  update,
  create,
  checkCode,
  getAll,
  validateVendors,
  exportToExcel,
  getForIds,
};
