import { getRequest, postRequest, putRequest } from "./config/request";
const SERVICE_URL = "/user";

export const create = (payload) =>
  postRequest(`${SERVICE_URL}/register`, payload);
export const update = (payload) => putRequest(`${SERVICE_URL}`, payload);
export const paginate = (qs: string) =>
  getRequest(`${SERVICE_URL}/paginate?${qs}`);

export const get = (id) => getRequest(`${SERVICE_URL}/${id}`);
export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const checkCode = (code: string, _id: string) =>
  postRequest(`${SERVICE_URL}/check-username`, { code, _id });
export const toggleActive = (id: string) =>
  getRequest(`${SERVICE_URL}/${id}/toggle-active`);
export const resetPassword = (id: string) =>
  getRequest(`${SERVICE_URL}/${id}/reset-password`);
export const changePassword = (id: string, payload: any) =>
  postRequest(`${SERVICE_URL}/${id}/change-password`, payload);
export default {
  create,
  update,
  paginate,
  get,
  getAll,
  checkCode,
  toggleActive,
  resetPassword,
  changePassword,
};
