import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Space,
  Tag,
  message,
} from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import service from "../../services/journal";
import { BASE_PATH, TITLE } from ".";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import dayjs from "dayjs";
import * as poActions from "../../store/poSlice";

export default function EntityForm(props: any) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const params = useParams();
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const poState = useSelector((state: Store) => state.po);

  const [entities, setEntities] = useState<any[]>([]);
  const [dependencies, setDependencies] = useState<any>({});

  useEffect(() => {
    service.getDependencies().then((data) => {
      const [accounts] = data;
      setDependencies({
        accounts,
      });
    });
  }, []);

  const refreshEntitiies = () => {
    dispatch(poActions.fetchVendors());
    dispatch(poActions.fetchSuppliers());
  };

  useEffect(() => {
    if (params.id) {
      setId(params.id);
      service.get(params.id).then((res) => {
        form.setFieldsValue({
          ...res,
          date: dayjs(res.date),
          vendor: res.vendor?._id,
          supplier: res.supplier?._id,
          entity: res.vendor?._id || res.supplier?._id,
          account: [res.account],
        });
        setData(res);
      });
    }
  }, [params.id]);

  const handleFinish = async (values: any) => {
    values.date = values.date.toDate();
    const hasSelectedVendor = entities.find(
      (v) => v._id === values.entity && v.type === "Vendor"
    );
    if (hasSelectedVendor) {
      values.vendor = values.entity;
    }
    const hasSelectedSupplier = entities.find(
      (v) => v._id === values.entity && v.type === "Supplier"
    );
    if (hasSelectedSupplier) {
      values.supplier = values.entity;
    }
    values.account = values.account[0];
    console.log(values);
    try {
      if (id) {
        await service.update({ _id: id, ...values });
      } else {
        await service.create(values);
      }
      navigate(BASE_PATH);
    } catch (error) {
      message.error(error.message);
      throw error;
    }
  };

  useEffect(() => {
    setEntities([
      ...poState.vendors.data.map((v) => ({ ...v, type: "Vendor" })),
      ...poState.suppliers.data.map((v) => ({ ...v, type: "Supplier" })),
    ]);
  }, [poState.vendors.data, poState.suppliers.data]);
  return (
    <div>
      <Title level={3}>
        {id ? "Edit" : "Add"} {TITLE[0]}
      </Title>
      <Form
        form={form}
        onFinish={handleFinish}
        className="mt-8"
        initialValues={{ date: dayjs() }}
      >
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: "Please select a entry type" }]}
        >
          <Radio.Group optionType="button" buttonStyle="solid">
            <Radio.Button value="credit">Credit</Radio.Button>
            <Radio.Button value="debit">Debit</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="date"
          label="Date"
          rules={[{ required: true, message: "Please select a payment date" }]}
        >
          <DatePicker
            allowClear={false}
            style={{ width: "300px" }}
            format="DD/MM/YYYY"
          />
        </Form.Item>
        <Form.Item
          name="account"
          label="Account"
          className="w-full"
          rules={[
            { required: true, message: "Account is required" },
            { type: "array", len: 1, message: "Please select only 1 account" },
          ]}
        >
          <Select
            mode="tags"
            style={{ width: "300px" }}
            placeholder="Select Account"
          >
            {dependencies.accounts?.map((g: any) => (
              <Select.Option key={g} value={g}>
                {g}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={["amount"]}
          label="Amount"
          rules={[{ required: true, message: "Please enter the amount" }]}
        >
          <InputNumber
            type="number"
            style={{ width: "300px" }}
            placeholder="Enter Amount"
            prefix="₹"
          />
        </Form.Item>
        <Form.Item name="reference" label="Reference">
          <Input.TextArea
            style={{ width: "300px" }}
            placeholder="Enter Reference"
          />
        </Form.Item>
        <div className="flex  gap-x-2">
          <Form.Item name="entity" label="Vendor/Supplier">
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "300px" }}
              loading={poState.vendors.loading || poState.suppliers.loading}
              placeholder="Select a vendor"
              allowClear
              disabled={!!id}
            >
              {entities.map((vendor) => (
                <Select.Option key={vendor._id} value={vendor._id}>
                  {vendor.name} <Tag>{vendor.type}</Tag>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            icon={<SyncOutlined />}
            type="link"
            onClick={refreshEntitiies}
          />
        </div>

        <Form.Item className="mt-4">
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
