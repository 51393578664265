import { getRequest } from "./config/request";
const SERVICE_URL = "/warehouse";

export const getAll = () => getRequest(`${SERVICE_URL}`);
export const getForClient = (client: string) =>
  getRequest(`${SERVICE_URL}/client/${client}`);
export default {
  getAll,
  getForClient,
};
