import React, { useState, useEffect } from "react";
import FastQ from "fastq";
import { useSelector } from "react-redux";
import { getForMultiple } from "../utils/getPrintPayload";
import qz from "qz-tray";
import { PAGE_SETUP as defaultPageSetup } from "../templates/vendor";
import { message } from "antd";
import { Store } from "../store";
const handlePrint = async (printer, doc) => {
  try {
    const defaultConfig = qz.configs.create(printer);
    const printerConfig = {
      ...defaultConfig,
      config: {
        ...defaultConfig.config,
        units: "mm",
        size: defaultPageSetup.pageSize,
        orientation: "portrait",
      },
    };
    const printerData = [
      {
        type: "pdf",
        format: "base64",
        data: doc,
      },
    ];
    await qz.print(printerConfig, printerData);
    message.success("Successfully printed to QZ Tray");
  } catch (error) {
    return message.error("Error printing to QZ Tray");
  }
};
const getWorker = async (labelPrinter) => async (items: any[]) => {
  try {
    const doc = await getForMultiple(items);
    if (labelPrinter && doc) {
      await handlePrint(labelPrinter, doc);
    }
    return;
  } catch (error) {
    console.log(error);
  }
};

const usePrintQueue = () => {
  const [queue, setQueue] = useState<any>();
  const [isAvailable, setIsAvailable] = useState(false);
  const settingsState = useSelector((state: Store) => state.setting);
  const labelPrinterState = useSelector((state: Store) => state.labelPrinter);
  const poState = useSelector((state: Store) => state.po);

  useEffect(() => {
    if (
      !isAvailable &&
      labelPrinterState.labelPrinter &&
      settingsState.connectedToQzTray
    ) {
      setIsAvailable(true);
    } else {
      setIsAvailable(false);
    }
    if (labelPrinterState.labelPrinter) {
      console.log("Initializing queue");
      getWorker(labelPrinterState.labelPrinter).then((worker) =>
        setQueue(FastQ.promise(worker, 1))
      );
    }
  }, [settingsState]);
  return [queue, isAvailable];
};
export default usePrintQueue;
