import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import skuService from "../services/sku";
import vendorService from "../services/vendor";
import supplierService from "../services/supplier";
import service from "../services/po";

const TYPE = "PO";

export interface POState {
  vendors: {
    loading: boolean;
    data: any[];
  };
  suppliers: {
    loading: boolean;
    data: any[];
  };
  products: {
    loading: boolean;
    data: { _id: string; skus: any[] }[];
  };
  productsForClient: {
    loading: boolean;
    data: any[];
  };
}

const initialState: POState = {
  vendors: {
    loading: false,
    data: [],
  },
  suppliers: {
    loading: false,
    data: [],
  },
  products: {
    loading: false,
    data: [],
  },
  productsForClient: {
    loading: false,
    data: [],
  },
};

export const fetchVendors = createAsyncThunk(
  `${TYPE}/fetchVendors`,
  async () => {
    return await vendorService.getAll();
  }
);
export const fetchSuppliers = createAsyncThunk(
  `${TYPE}/fetchSuppliers`,
  async () => {
    return await supplierService.getAll();
  }
);

export const fetchProducts = createAsyncThunk(
  `${TYPE}/fetchProducts`,
  async () => {
    return await skuService.getAllProducts();
  }
);
export const fetchProductsForClient = createAsyncThunk(
  `${TYPE}/fetchProductsForClient`,
  async () => {
    return await skuService.getAllProductsForClient();
  }
);

const poSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVendors.pending, (state) => {
      state.vendors.loading = true;
    });
    builder.addCase(fetchVendors.fulfilled, (state, action) => {
      state.vendors.loading = false;
      state.vendors.data = action.payload;
    });
    builder.addCase(fetchVendors.rejected, (state) => {
      state.vendors.loading = false;
    });
    builder.addCase(fetchSuppliers.pending, (state) => {
      state.suppliers.loading = true;
    });
    builder.addCase(fetchSuppliers.fulfilled, (state, action) => {
      state.suppliers.loading = false;
      state.suppliers.data = action.payload;
    });
    builder.addCase(fetchSuppliers.rejected, (state) => {
      state.suppliers.loading = false;
    });
    builder.addCase(fetchProducts.pending, (state) => {
      state.products.loading = true;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products.loading = false;
      state.products.data = action.payload;
    });
    builder.addCase(fetchProducts.rejected, (state) => {
      state.products.loading = false;
    });
    builder.addCase(fetchProductsForClient.pending, (state) => {
      state.productsForClient.loading = true;
    });
    builder.addCase(fetchProductsForClient.fulfilled, (state, action) => {
      state.productsForClient.loading = false;
      state.productsForClient.data = action.payload.sort((a, b) =>
        a._id.localeCompare(b._id)
      );
    });
    builder.addCase(fetchProductsForClient.rejected, (state) => {
      state.productsForClient.loading = false;
    });
  },
});

export default poSlice.reducer;
