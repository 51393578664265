import { Routes, Route } from "react-router-dom";
import Form from "./Form";
import List from "./List";
export const MODEL = "role";
export const BASE_PATH = "/settings/raw-material";
export const TITLE = ["Raw Material", "Raw Materials"];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/form`} element={<Form />} />
      <Route path={`/`} element={<List />} />
    </Routes>
  );
}
