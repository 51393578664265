import { useUpdateEffect } from "ahooks";
import qs from "qs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { debounce } from "throttle-debounce";
const DEFAULT_TABLE_STATE = {
  pagination: {
    current: 1,
    pageSize: 20,
    showSizeChanger: true,
  },
  filters: {},
  sorter: {},
};
export default function useSearchParamsTableState({
  onFetch,
  defaultFilters = {},
}) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  let [searchParams, setSearchParams] = useSearchParams();
  const defaultTableState = {
    ...DEFAULT_TABLE_STATE,
    filters: defaultFilters,
  };
  const [tableState, setTableState] = useState<any>();
  const [_tableState, _setTableState] = useState<any>();
  const [query, setQuery] = useState("");
  const [_query, _setQuery] = useState(undefined);

  const searchDebounced = useCallback(
    debounce(500, (value) => setQuery(value)),
    []
  );
  const handleFetch = async () => {
    if (Object.keys(tableState || {}).length === 0) return;
    const params = qs.stringify({
      ...tableState,
      filters: { ...tableState.filters, query },
    });
    try {
      setLoading(true);
      const data = await onFetch(params);
      setData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (tableState) {
      handleFetch();
    }
  }, [query, tableState]);
  useEffect(() => {
    const params = qs.parse(searchParams.toString());
    const { query, ...tableState } = params;
    _setQuery(query as string);
    _setTableState(
      Object.keys(tableState).length > 0 ? tableState : defaultTableState
    );
  }, []);
  useUpdateEffect(() => {
    if (_query !== undefined) {
      searchDebounced(_query);
    }
  }, [_query, query]);
  useEffect(() => {
    const params = qs.parse(searchParams.toString());
    const { query, ...tableState } = params;
    setQuery(query as string);

    if (Object.keys(tableState).length > 0) {
      setTableState(tableState);
    } else {
      _setTableState(defaultTableState);
    }
  }, [searchParams]);
  useUpdateEffect(() => {
    const searchParams = qs.stringify({ ..._tableState, query: query || "" });
    setSearchParams(searchParams);
  }, [_tableState]);
  useUpdateEffect(() => {
    const searchParams = qs.stringify({
      ...defaultTableState,
      query: query || "",
    });
    setSearchParams(searchParams);
  }, [query]);

  return [
    _query,
    _setQuery,
    tableState,
    _setTableState,
    data,
    loading,
    handleFetch,
    setData,
  ];
}
