import { TDocumentDefinitions } from "pdfmake/interfaces";

function parseDate(date: string | number | Date): string {
  const parsedDate = new Date(date);
  return `${parsedDate.getDate()}/${
    parsedDate.getMonth() + 1
  }/${parsedDate.getFullYear()}`;
}

function prepareVendor(vendor: any): any[] {
  return [
    { text: `${vendor.name}\n`, bold: true },
    { text: `${vendor.address}\n` },
    { text: `Contact: ${vendor.contactPerson}\n` },
    { text: `Phone: ${vendor.phone}\n` },
    { text: `Email: ${vendor.email}\n` },
    { text: `GST: ${vendor.gst}\n` },
  ];
}

function preparePOExtra(): any[] {
  return [{ text: "Date:\n", margin: [0, 3, 0, 0] }];
}

function getDocDefinition(data: any): TDocumentDefinitions {
  const payload: any = {
    pageSize: "A4",
    pageMargins: [20, 30, 20, 30],
    styles: {
      header: { fontSize: 18, bold: true, margin: [0, 0, 0, 10] },
    },
    content: [
      {
        columns: [
          {
            width: "*",
            stack: [{ text: `\n`, bold: true }],
          },
          {
            alignment: "right",
            width: "50%",
            stack: [
              { text: "CREDIT NOTE\n", fontSize: 14, alignment: "right" },
              {
                text: `${data.id}`,
                fontSize: 10,
                margin: [0, 5, 0, 0],
              },
            ],
          },
        ],
      },
      {
        text: data.vendor ? "Vendor" : "Supplier",
        fontSize: 12,
        margin: [0, 10, 0, 0],
      },
      {
        columns: [
          {
            width: "50%",
            stack: data.vendor
              ? prepareVendor(data.vendor)
              : prepareVendor(data.supplier),
          },
          { width: "38%", alignment: "right", stack: preparePOExtra() },
          {
            width: "12%",
            alignment: "right",
            stack: [
              {
                text: `${parseDate(data.updatedAt)}\n`,
                margin: [0, 3, 0, 0],
              },
            ],
          },
        ],
      },
    ],
  };

  if (data.skus?.length > 0) {
    payload.content.push({
      margin: [0, 10, 0, 0],
      layout: {
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0;
          }
          return 0;
        },
        vLineWidth: function (i) {
          return 0;
        },
        hLineColor: function (i) {
          return i === 1 ? "black" : "#aaa";
        },
        vLineColor: function (i) {
          return "black";
        },
        paddingLeft: function (i) {
          return 8;
        },
        paddingRight: function (i, node) {
          return 8;
        },
        paddingTop: function (i, node) {
          return 4;
        },
        paddingBottom: function (i, node) {
          return 4;
        },
      },
      table: {
        widths: ["7%", "43%", "15%", "15%", "10%", "10%"],
        headerRows: 1,
        body: [
          [
            {
              text: "S.no",
              alignment: "left",
              fillColor: [0, 0, 0, 90],
              color: [0, 0, 0, 0],
            },
            {
              text: "Product",
              alignment: "left",
              fillColor: [0, 0, 0, 90],
              color: [0, 0, 0, 0],
            },
            {
              text: "Brand",
              alignment: "left",
              fillColor: [0, 0, 0, 90],
              color: [0, 0, 0, 0],
            },
            {
              text: "Quantity",
              alignment: "right",
              fillColor: [0, 0, 0, 90],
              color: [0, 0, 0, 0],
            },
            {
              text: "Rate",
              alignment: "right",
              fillColor: [0, 0, 0, 90],
              color: [0, 0, 0, 0],
            },
            {
              text: "Amount",
              alignment: "right",
              fillColor: [0, 0, 0, 90],
              color: [0, 0, 0, 0],
            },
          ],
          ...data.skus
            .map((sku: any, index: number) => [
              [
                { text: `${index + 1}`, alignment: "left" },
                { text: sku.productCode, alignment: "left" },
                { text: sku.mapping?.brand || "", alignment: "left" },
                {
                  text: sku.quantities.reduce((acc, q) => acc + q.ordered, 0),
                  alignment: "right",
                },
                {
                  text: sku.quantities[0].rate,
                  alignment: "right",
                },
                {
                  text: sku.quantities.reduce((acc, q) => acc + q.amount, 0),
                  alignment: "right",
                },
              ],
              [
                {
                  colSpan: 6,
                  margin: [45, 0],
                  layout: {
                    hLineColor: () => "#aaa",
                    vLineColor: () => "#aaa",
                    paddingLeft: () => 4,
                    paddingRight: () => 4,
                    paddingTop: () => 2,
                    paddingBottom: () => 2,
                  },
                  table: {
                    widths: Array(sku.quantities.length).fill(
                      `${(100 / sku.quantities.length).toFixed(1)}%`
                    ),
                    headerRows: 1,
                    body: [
                      sku.quantities.map((h) => ({
                        text: `UK ${h.size}`,
                        bold: true,
                      })),
                      sku.quantities.map((h) => ({
                        text: `${h.ordered}`,
                        bold: true,
                      })),
                    ],
                  },
                },
              ],
            ])
            .flat(),
          [
            {
              fillColor: [0, 0, 0, 90],
              color: [0, 0, 0, 0],
              text: { text: "Total", alignment: "left" },
              colSpan: 5,
            },
            "",
            "",
            "",
            "",
            {
              fillColor: [0, 0, 0, 90],
              color: [0, 0, 0, 0],
              text: {
                text: `${data.totalQuantity} PCS`,
                alignment: "right",
              },
            },
          ],
        ],
      },
    });
  }
  payload.content.push({
    columns: [
      {
        margin: [0, 10, 30, 0],
        width: "50%",
        text: `Reference: \n\n ${data.reference || ""}`,
      },
      {
        width: "50%",
        margin: [0, 10, 0, 0],
        table: {
          widths: ["70%", "30%"],
          body: [
            data.skus?.length > 0
              ? [
                  { text: `Total Quantity`, alignment: "right" },
                  {
                    text: `${data.totalQuantity} PCS`,
                    alignment: "right",
                  },
                ]
              : ["", ""],
            data.skus?.length > 0
              ? [
                  { text: `Total SKUS`, alignment: "right" },
                  {
                    text: `${data.skus
                      .map((sku) => sku.quantities.length)
                      .reduce((acc, val) => acc + val, 0)}`,
                    alignment: "right",
                  },
                ]
              : ["", ""],
            [
              { text: `Total Amount`, alignment: "right" },
              {
                text: `₹ ${data.totalAmount || data.amount}`,
                alignment: "right",
              },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 0 : 0;
          },
          vLineWidth: function (i) {
            return 0;
          },
          hLineColor: function (i) {
            return i === 1 ? "black" : "#aaa";
          },
          vLineColor: function (i) {
            return "black";
          },
          paddingLeft: function (i) {
            return 25;
          },
          paddingRight: function (i, node) {
            return 8;
          },
          paddingTop: function (i, node) {
            return 4;
          },
          paddingBottom: function (i, node) {
            return 4;
          },
        },
      },
    ],
  });

  return payload;
}

export default getDocDefinition;
