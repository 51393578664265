import { Routes, Route } from "react-router-dom";
import List from "./List";
export const MODEL = "sku";
export const BASE_PATH = "/finance/ledger";
export const TITLE = ["Ledger", "Ledgers"];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/`} element={<List />} />
    </Routes>
  );
}
