import { useEffect, useState, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Select,
  Table,
  Space,
  Popover,
  List,
  Checkbox,
  Form,
  Modal,
  Tag,
  Switch,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  ExportOutlined,
} from "@ant-design/icons";

import { debounce } from "throttle-debounce";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TITLE, BASE_PATH } from ".";
import service from "../../services/creditNote";

import useSearchParamsTableState from "../../hooks/useSearchParamsTableState";
import { useSelector } from "react-redux";
import { Store } from "../../store";
import OrderItemsModal from "../../components/OrderItemsModal";

const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const MASTER_KEYS = [];

export default function Entities(props: any) {
  const breadcrumbsRef = useRef([{ label: TITLE[1], url: BASE_PATH }]);
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({ onFetch: service.paginate });
  const navigate = useNavigate();
  const [resetPasswordVisible, setResetPasswordVisible] = useState("");
  const [toggleLoading, setToggleLoading] = useState(false);
  const [toggleKey, setToggleKey] = useState("");
  const [selectedClient, setSelectedClient] = useState<any>();
  const clientState = useSelector((state: Store) => state.client);

  const warehouseState = useSelector((state: Store) => state.warehouse);
  useEffect(() => {
    loadMastersDebounced();
  }, []);
  const [masters, setMasters] = useState<any>({});
  useEffect(() => {
    if (tableState) {
      setTableState({
        ...tableState,
        filters: {
          ...tableState.filters,
          client: [clientState.current],
        },
      });
    }
  }, [clientState.current]);
  const getColumnsFilters = useCallback(
    (key: string) => {
      return masters[key]
        ? masters[key].map((entity) => ({
            text: entity.name,
            value: entity._id,
          }))
        : [];
    },
    [masters]
  );
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);

  /*
  const creditNote = {
    "id" : "108749465",
    "warehouseId" : "6658828245f55388da71e476",
    "creditNoteNumber" : "CC142869",
    "creditNoteDate" : ISODate("2024-04-30T01:19:07.000+0000"),
    "invoiceId" : "I0925RH000021319",
    "invoiceDate" : ISODate("2024-04-26T02:31:12.000+0000"),
    "orderDate" : ISODate("2024-04-25T14:51:57.000+0000"),
    "channel" : "Rohil Polymers India - Myntra PPMP 17763",
    "buyerName" : "Ramalakshmi",
    "buyerAddress1" : "Fortune amore  lane no.1, parallel to high tension road,Kondapur",
    "buyerAddress2" : "Raja rajeshwari nagar road, Kondapur",
    "buyerCity" : "Hyderabad",
    "buyerState" : "TG",
    "buyerPincode" : "500084",
    "buyerPhone" : "9999999999",
    "buyerEmail" : null,
    "orderType" : "cod",
    "channelOrderId" : "6c362b9a-7270-464f-a4e2-6f777589f3c6",
    "channelSubOrderId" : "9885689779",
    "skuCode" : "HRXW8253Peach_7",
    "taxRate" : NumberInt(12),
    "igstRate" : NumberInt(12),
    "cgstRate" : NumberInt(0),
    "sgstRate" : NumberInt(0),
    "currencyCode" : "INR",
    "qty" : NumberInt(1),
    "sellingPricePerItem" : NumberInt(1899),
    "shippingChargePerItem" : NumberInt(0),
    "promoDiscounts" : NumberInt(-1349),
    "giftWrapCharges" : NumberInt(0),
    "transactionCharges" : NumberInt(0),
    "creditNoteAmount" : NumberInt(550),
    "taxAmount" : 58.93,
    "igstAmount" : 58.93,
    "cgstAmount" : NumberInt(0),
    "sgstAmount" : NumberInt(0),
    "createdAt" : ISODate("2024-05-30T14:34:53.916+0000"),
    "updatedAt" : ISODate("2024-05-30T14:34:53.916+0000"),
}

  */

  const columns: any[] = [
    {
      title: "Credit Note Number",
      dataIndex: "creditNoteNumber",
      key: "creditNoteNumber",
    },
    {
      title: "Credit Note Date",
      dataIndex: "creditNoteDate",
      key: "creditNoteDate",
      render: (text: string) => dayjs(text).format("DD/MM/YYYY"),
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceId",
      key: "invoiceId",
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (text: string) => dayjs(text).format("DD/MM/YYYY"),
    },
    {
      title: "Order Date",
      dataIndex: "orderDate",
      key: "orderDate",
      render: (text: string) => dayjs(text).format("DD/MM/YYYY"),
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
    },
    {
      title: "Buyer Name",
      dataIndex: "buyerName",
      key: "buyerName",
    },
    // {
    //   title: "Buyer Address",
    //   dataIndex: "buyerAddress1",
    //   key: "buyerAddress1",
    //   sorter: true,
    //   render: (text: string, record: any) => (
    //     <div>
    //       <div>{text}</div>
    //       <div>{record.buyerAddress2}</div>
    //       <div>
    //         {record.buyerCity}, {record.buyerState} - {record.buyerPincode}
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: "Buyer Address",
      dataIndex: "buyerAddress1",
      key: "buyerAddress1",
    },
    {
      title: "Buyer City",
      dataIndex: "buyerCity",
      key: "buyerCity",
    },
    {
      title: "Buyer State",
      dataIndex: "buyerState",
      key: "buyerState",
    },
    {
      title: "Buyer Pincode",
      dataIndex: "buyerPincode",
      key: "buyerPincode",
    },
    {
      title: "Buyer Phone",
      dataIndex: "buyerPhone",
      key: "buyerPhone",
    },
    {
      title: "Order Type",
      dataIndex: "orderType",
      key: "orderType",
    },
    {
      title: "SKU Code",
      dataIndex: "skuCode",
      key: "skuCode",
    },
    {
      title: "Tax Rate",
      dataIndex: "taxRate",
      key: "taxRate",
    },
    {
      title: "IGST Rate",
      dataIndex: "igstRate",
      key: "igstRate",
    },
    {
      title: "CGST Rate",
      dataIndex: "cgstRate",
      key: "cgstRate",
    },
    {
      title: "SGST Rate",
      dataIndex: "sgstRate",
      key: "sgstRate",
    },
    {
      title: "Currency Code",
      dataIndex: "currencyCode",
      key: "currencyCode",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "Selling Price Per Item",
      dataIndex: "sellingPricePerItem",
      key: "sellingPricePerItem",
    },
    {
      title: "Shipping Charge Per Item",
      dataIndex: "shippingChargePerItem",
      key: "shippingChargePerItem",
    },
    {
      title: "Promo Discounts",
      dataIndex: "promoDiscounts",
      key: "promoDiscounts",
    },
    {
      title: "Gift Wrap Charges",
      dataIndex: "giftWrapCharges",
      key: "giftWrapCharges",
    },
    {
      title: "Transaction Charges",
      dataIndex: "transactionCharges",
      key: "transactionCharges",
    },
    {
      title: "Credit Note Amount",
      dataIndex: "creditNoteAmount",
      key: "creditNoteAmount",
    },
    {
      title: "Tax Amount",
      dataIndex: "taxAmount",
      key: "taxAmount",
    },
    {
      title: "IGST Amount",
      dataIndex: "igstAmount",
      key: "igstAmount",
    },
    {
      title: "CGST Amount",
      dataIndex: "cgstAmount",
      key: "cgstAmount",
    },
    {
      title: "SGST Amount",
      dataIndex: "sgstAmount",
      key: "sgstAmount",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string) => dayjs(text).format("DD/MM/YYYY"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text: string) => dayjs(text).format("DD/MM/YYYY"),
    },
  ];

  const handleView = (entityId?: string) => {
    navigate(`${BASE_PATH}/${entityId}`);
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={3}>All {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      {/* <Row justify="space-between" className="p-4">
        <Col className="flex">
          <Search
            placeholder={`Search ${TITLE[1]}`}
            enterButton={<SearchOutlined />}
            size="middle"
            onChange={(event) => {
              setInput(event.target.value);
            }}
            value={input}
          />
        </Col>
        <Col>
          {data?.queryCount !== data?.totalCount && (
            <Text className="flex items-center">
              Showing {data.queryCount} of {data?.totalCount} entries
              <Button
                type="link"
                onClick={() => {
                  setInput("");
                }}
              >
                Clear Search
              </Button>
            </Text>
          )}
        </Col>
        <Col></Col>
      </Row> */}
      <Row className="mt-2">
        <Col span={24}>
          <div className="overflow-data-table">
            <Table
              loading={loading}
              dataSource={data?.list}
              columns={columns}
              pagination={{
                ...(tableState?.pagination || {}),
                total: data?.queryCount,
              }}
              bordered
              className="overflow-table"
              onChange={(pagination, filters, sorter) => {
                delete (sorter as any).column;
                setTableState({
                  pagination,
                  filters: { ...filters, client: [clientState.current] },
                  sorter,
                });
                setTableState({ pagination, filters, sorter });
              }}
              rowKey={(record) => record._id}
              size="small"
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
