import {
  deleteRequest,
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/order";

export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);
export const paginateItems = (qs: string) =>
  getRequest(`${SERVICE_URL}/items?${qs}`);
export const getOrderItems = (id: string) =>
  getRequest(`${SERVICE_URL}/${id}/order-items`);
export const uploadBulk = (data: any) =>
  postRequest(`${SERVICE_URL}/sjit/upload`, data);
export const processBulk = (data: any) =>
  postRequest(`${SERVICE_URL}/sjit/process`, data);

export const paginateJobs = (qs: string) =>
  getRequest(`${SERVICE_URL}/sjit/jobs?${qs}`);
export const getJob = (id: string) =>
  getRequest(`${SERVICE_URL}/sjit/jobs/${id}`);
export const getJobRows = (id: string) =>
  getRequest(`${SERVICE_URL}/sjit/jobs/${id}/rows`);
export const approveJob = (id: string) =>
  putRequest(`${SERVICE_URL}/sjit/jobs/${id}/approve`);
export const cancelJob = (id: string) =>
  deleteRequest(`${SERVICE_URL}/sjit/jobs/${id}`);
export default {
  paginate,
  getOrderItems,
  paginateItems,
  uploadBulk,
  processBulk,
  paginateJobs,
  getJob,
  getJobRows,
  approveJob,
  cancelJob,
};
