import io, { Socket } from "socket.io-client";
import { SOCKET_URL } from "./apiUrl";
import { debounce } from "throttle-debounce";
import * as batchActions from "../../store/batchSlice";
import * as progressActions from "../../store/progressSlice";
export let socket: Socket;

export const initialize = debounce(500, async () => {
  if (!socket) {
    const { store } = await import("../../store");
    socket = io(SOCKET_URL);

    socket.on("REFRESH_BATCHES", () => {
      console.log("Received REFRESH_BATCHES event");
      store.dispatch(batchActions.loadBatchesAsync({}) as any);
    });

    socket.on("SET_SJIT_ORDER_UPLOAD_PROGRESS", (data: any) => {
      console.log("Received SET_SJIT_ORDER_UPLOAD_PROGRESSS event");
      store.dispatch(progressActions.setSjitOrderUpload(data) as any);
    });

    socket.on("SET_SJIT_ORDER_PROCESS_PROGRESS", (data: any) => {
      console.log("Received SET_SJIT_ORDER_PROCESS_PROGRESS event");
      store.dispatch(progressActions.setSjitOrderProcess(data) as any);
    });

    socket.on("ROAS", (data: any) => {
      console.log("Received ROAS event");
      store.dispatch(progressActions.setRoas(data) as any);
    });
    socket.on("SJIT_INVENTORY", (data: any) => {
      console.log("Received SJIT_INVENTORY event");
      store.dispatch(progressActions.setSjitInventory(data) as any);
    });
    socket.on("SJIT_INVENTORY_PAYLOAD", (data: any) => {
      console.log("Received SJIT_INVENTORY_PAYLOAD event");
      store.dispatch(progressActions.setSjitInventoryPayload(data) as any);
    });

    socket.onAny((event, ...args) => {
      console.log(`Received ${event} event with data:`, args);
    });
  }
});

export const terminate = () => {
  if (socket) {
    socket.removeAllListeners();
    socket.close();
    socket = undefined;
  }
};

export const getSocket = () => {
  return socket;
};
