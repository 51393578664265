import { Button, Table, Tag } from "antd";
import { Store } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import settingsService from "../../../services/settings";
import * as SettingActions from "../../../store/settingSlice";

import { useNavigate } from "react-router-dom";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { BASE_PATH } from ".";
import { TITLE } from ".";

/*
{
    "_id" : ObjectId("665ef8c25d9df4b1923d8bf8"),
    "name" : "Main",
    "createdAt" : ISODate("2024-06-04T11:21:38.631+0000"),
    "updatedAt" : ISODate("2024-06-04T11:24:43.231+0000"),
    "__v" : NumberInt(0),
    "distribution" : {
        "male" : [
            null,
            null,
            null,
            null,
            0.075,
            0.175,
            0.25,
            0.25,
            0.15,
            0.1,
            NumberInt(0),
            NumberInt(0),
            NumberInt(0)
        ],
        "female" : [
            null,
            null,
            null,
            null,
            0.125,
            0.15625,
            0.21875,
            0.21875,
            0.15625,
            0.125
        ]
    }
}

*/

function ApiKeys(props: any) {
  const navigate = useNavigate();
  const settingState = useSelector((state: Store) => state.setting);
  const dispatch = useDispatch();
  const [zones, setZones] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(SettingActions.loadSizeDistributionsAsync({}));
  }, []);
  const handleEdit = (id: string) => {
    navigate(`${BASE_PATH}/form?id=${id}`);
  };
  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        {TITLE[1]}
        <Button
          type="primary"
          onClick={() => navigate(`${BASE_PATH}/form`)}
          icon={<PlusOutlined />}
        >
          Add New
        </Button>
      </div>

      <Table
        className="mt-4"
        bordered
        pagination={false}
        size="small"
        dataSource={settingState.sizeDistributions}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Distribution",
            dataIndex: "distribution",
            key: "distribution",
            render: ({ male, female }) => {
              return (
                <div>
                  <div className="flex gap-x-2">
                    {Object.keys(male)
                      .filter((key) => !!male[key])
                      .map((key) => {
                        return (
                          <div>
                            <Tag className="text-gray-500">Size {key}: </Tag>

                            <span className="font-semibold">{male[key]} %</span>
                          </div>
                        );
                      })}
                  </div>
                  <div className="flex gap-x-2 mt-2">
                    {Object.keys(female)
                      .filter((key) => !!female[key])
                      .map((key) => {
                        return (
                          <div>
                            <Tag className="text-gray-500">Size {key}: </Tag>

                            <span className="font-semibold">
                              {female[key]} %
                            </span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            },
          },
          {
            title: "Action",
            key: "action",
            render: (text, record) => (
              <Button
                size="small"
                type="link"
                icon={<EditOutlined />}
                onClick={() => handleEdit(record._id)}
              >
                Edit
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
}

export default ApiKeys;
