import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/sku";

const upload = (data: any) =>
  postRequest(`${SERVICE_URL}/sjit-stock/upload`, data);
const process = (data: any) =>
  postRequest(`${SERVICE_URL}/sjit-stock/process`, data);

export default {
  upload,
  process,
};
