import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from "../services/warehouse";
const TYPE = "WAREHOUSE";

export interface WarehouseState {
  warehouses: any[];
  loading: boolean;
  selected: any;
}

const initialState: WarehouseState = {
  warehouses: [],
  loading: false,
  selected: null,
};

export const loadWarehousesForClientAsync = createAsyncThunk(
  `${TYPE}/LOAD_FOR_CLIENT`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getForClient(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const warehouseSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setSelected: (state, action) => {
      state.selected = action.payload;
      localStorage.setItem("warehouse", action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadWarehousesForClientAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadWarehousesForClientAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.warehouses = action.payload;
    });
    builder.addCase(loadWarehousesForClientAsync.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setSelected } = warehouseSlice.actions;

export default warehouseSlice.reducer;
