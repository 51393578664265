import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import service from "../../services/rwpo";
import rwService from "../../services/rawMaterial";
import poService from "../../services/po";
import { useEffect, useRef, useState } from "react";
import Title from "antd/es/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../store";
import { BASE_PATH } from ".";
import * as poActions from "../../store/poSlice";
import {
  SyncOutlined,
  DeleteOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

export default function EntityForm(props: any) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const params = useParams();
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const poState = useSelector((state: Store) => state.po);
  const addRef = useRef<(defaultValue?: any, insertIndex?: number) => void>();
  const removeRef = useRef<(index: number | number[]) => void>();
  const products = Form.useWatch("products", form);
  const skus = Form.useWatch("skus", form);
  const vendor = Form.useWatch("vendor", form);
  const po = Form.useWatch("po", form);
  const [rawMaterials, setRawMaterials] = useState<any[]>();
  const selectedRawMaterials = Form.useWatch("rawMaterials", form);
  const [vendorPOs, setVendorPOs] = useState<any[]>();
  const [groups, setGroups] = useState<any[]>([]);
  const [rwpoForPO, setRWPOForPO] = useState<any[]>();

  useEffect(() => {
    loadRWs();
  }, []);

  const getForPO = (id: string) => {
    service.getForPO(id).then((res) => {
      setRWPOForPO(res);
    });
  };

  const loadRWs = async () => {
    const rws = await rwService.getAll();
    setGroups([...new Set(rws.map((rw) => rw.group))]);
    setRawMaterials(rws);
  };

  useEffect(() => {
    if (po?.length > 0) {
      Promise.all(po.map((po) => getForPO(po))).then((res) => {
        setRWPOForPO(res);
      });
    }
  }, [po]);

  useEffect(() => {
    if (params.id) {
      setId(params.id);
      service.get(params.id).then((res) => {
        form.setFieldsValue({
          ...res,
          vendor: res.vendor._id,
          supplier: res.supplier._id,
          rawMaterials: res.rawMaterials.map((rw) => ({
            ...rw,
            rawMaterial: rw.rawMaterial._id,
            group: rw.rawMaterial.group,
          })),
          po: res.po.map((po) => po._id),
        });
        setData(res);
      });
    }
  }, [params.id]);

  useEffect(() => {
    poService
      .getForVendorId(vendor)
      .then((res) => {
        setVendorPOs(res);
      })
      .catch((err) => setVendorPOs([]));
  }, [vendor]);

  const handleFinish = async (values: any) => {
    try {
      if (id) {
        await service.update({ _id: id, ...values });
      } else {
        await service.register(values);
      }
      navigate(BASE_PATH);
    } catch (error) {
      message.error(error.message);
      // throw error;
    }
  };

  const refreshVendors = () => {
    dispatch(poActions.fetchVendors());
  };

  const refreshSuppliers = () => {
    dispatch(poActions.fetchSuppliers());
  };

  return (
    <div>
      <Title level={3}>{id ? "Edit" : "Add"} Purchase Order</Title>
      <Form
        form={form}
        onFinish={handleFinish}
        className="mt-8"
        initialValues={{ withGST: true }}
        onValuesChange={(changed, all) => console.log(all)}
      >
        {data?.id && (
          <Form.Item name="id" label="ID">
            {data?.id}
          </Form.Item>
        )}
        <div className="flex  gap-x-2">
          <Form.Item name="supplier" label="Supplier">
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "300px" }}
              loading={poState.suppliers.loading}
              placeholder="Select a supplier"
              allowClear
              disabled={!!id}
            >
              {poState.suppliers.data.map((vendor) => (
                <Select.Option key={vendor._id} value={vendor._id}>
                  {vendor.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            icon={<SyncOutlined />}
            type="link"
            onClick={refreshSuppliers}
          />
        </div>
        <div className="flex  gap-x-2">
          <Form.Item name="vendor" label="Vendor">
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: "300px" }}
              loading={poState.vendors.loading}
              placeholder="Select a vendor"
              allowClear
              disabled={!!id}
            >
              {poState.vendors.data.map((vendor) => (
                <Select.Option key={vendor._id} value={vendor._id}>
                  {vendor.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button
            icon={<SyncOutlined />}
            type="link"
            onClick={refreshVendors}
          />
        </div>
        <div className="flex gap-x-2 items-baseline">
          <Form.Item name="po" label="PO">
            <Select
              style={{ width: "300px" }}
              placeholder="Select PO"
              mode="multiple"
              options={vendorPOs?.map((po) => ({
                label: po.id,
                value: po._id,
              }))}
            />
          </Form.Item>
          {!id && rwpoForPO?.length > 0 && (
            <div className="flex gap-x-2">
              Existing RWPO:
              {rwpoForPO?.map((rwpo) => (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      `/raw-material-purchase-orders/${rwpo._id}`,
                      `_blank`
                    );
                  }}
                >
                  {rwpo?.id}
                </a>
              ))}
            </div>
          )}
        </div>
        <Form.Item name="withGST" label="With GST" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.List name="rawMaterials">
          {(fields, { add, remove }) => {
            addRef.current = add;
            removeRef.current = remove;
            return (
              <div className="">
                <div className="flex gap-x-2">
                  <Button onClick={() => add({ quantity: 1 })}>Add</Button>
                  <Button
                    icon={<SyncOutlined />}
                    type="link"
                    onClick={loadRWs}
                  />
                </div>
                {fields.map((field, index) => {
                  const rawMaterial = selectedRawMaterials[index];

                  const rwObject = rawMaterials?.find(
                    (rm) => rm._id === rawMaterial?.rawMaterial
                  );

                  return (
                    <div className="flex gap-x-4 items-center">
                      <div>{index + 1}.</div>
                      <div>
                        <div className="flex gap-x-2 mt-4 ">
                          <Form.Item name={[field.name, "group"]}>
                            <Select
                              placeholder="Select Group"
                              style={{ width: 250 }}
                            >
                              {groups.map((group) => (
                                <Select.Option key={group} value={group}>
                                  {group}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item name={[field.name, "rawMaterial"]}>
                            <Select
                              style={{ width: 250 }}
                              className="composite-select"
                              placeholder="Select Raw Material"
                              onChange={(value) => {
                                form.setFieldValue(
                                  [
                                    "rawMaterials",
                                    index,
                                    "price",
                                  ] as unknown as string[],
                                  rawMaterials?.find((rm) => rm._id === value)
                                    ?.price
                                );
                                form.setFieldValue(
                                  [
                                    "rawMaterials",
                                    index,
                                    "supplierPrice",
                                  ] as unknown as string[],
                                  rawMaterials?.find((rm) => rm._id === value)
                                    ?.supplierPrice
                                );
                              }}
                              options={rawMaterials
                                ?.filter(
                                  (rm) =>
                                    !selectedRawMaterials[index].group ||
                                    rm.group ===
                                      selectedRawMaterials[index].group
                                )
                                .map((rm) => ({
                                  label: rm,
                                  value: rm._id,
                                }))}
                              optionRender={(node: any) => {
                                return (
                                  <div>
                                    <div className="text-xs text-white bg-gray-500 w-max px-1 rounded font-medium">
                                      {node.label.group}
                                    </div>
                                    {node.label.name}
                                  </div>
                                );
                              }}
                              labelRender={(node: any) => {
                                return (
                                  <div>
                                    <div className="text-xs text-white bg-gray-500 w-max px-1 rounded font-medium">
                                      {node.label?.group}
                                    </div>
                                    {node.label?.name}
                                  </div>
                                );
                              }}
                            ></Select>
                          </Form.Item>
                          <Form.Item name={[field.name, "quantity"]}>
                            <InputNumber
                              className="w-full"
                              keyboard={false}
                              placeholder="Enter Quantity"
                              suffix={rwObject?.unit}
                            />
                          </Form.Item>
                          <Form.Item name={[field.name, "price"]}>
                            <InputNumber
                              className="w-full"
                              keyboard={false}
                              placeholder="Enter Vendor Price"
                              prefix="VP"
                            />
                          </Form.Item>
                          <Form.Item name={[field.name, "supplierPrice"]}>
                            <InputNumber
                              className="w-full"
                              keyboard={false}
                              placeholder="Enter Supplier Price"
                              prefix="SP"
                            />
                          </Form.Item>
                          <div>
                            <Button
                              onClick={() => remove(field.name)}
                              icon={<DeleteOutlined />}
                            ></Button>
                          </div>
                        </div>
                        <Form.Item name={[field.name, "description"]}>
                          <Input
                            className="w-full"
                            placeholder="Enter Description"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }}
        </Form.List>
        <Form.Item className="mt-4">
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}
