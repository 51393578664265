import {
  Button,
  Divider,
  Steps,
  Typography,
  message,
  Upload,
  Table,
  Alert,
  Space,
  Tag,
  Tooltip,
} from "antd";
import { TITLE, BASE_PATH, columns as defaultColumns } from ".";
import service from "../../../services/settings";
import { useRef, useEffect, useState, useCallback } from "react";
import type { UploadProps } from "antd";
import vendorService from "../../../services/vendor";
import { titleCase } from "title-case";
import { InboxOutlined, InfoCircleOutlined } from "@ant-design/icons";
import clientService from "../../../services/client";
import * as XLSX from "xlsx";
import { debounce } from "throttle-debounce";
const { Title } = Typography;
const { Dragger } = Upload;

const REQUIRED_COLUMNS = [
  "OMS SKU Code",
  "MRP",
  "Category",
  "HSN Code",
  "Gender",
  "Vendor",
];

const UploadRender: React.FC<any> = ({ onChange }) => {
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [error, setError] = useState<any>({ title: "", message: "" });
  const [data, setData] = useState<any[]>([]);
  const [reportRender, setReportRender] = useState<any>(null);
  const [checkingSkuCodes, setCheckingSkuCodes] = useState(false);
  const [unrecognizedSkuCodes, setUnrecognizedSkuCodes] = useState<any[]>();
  const [invalidVendors, setInvalidVendors] = useState<any>();
  const [invalidClients, setInvalidClients] = useState<string[]>([]);
  const [duplicateSkuCodes, setDuplicateSkuCodes] = useState<any[]>([]);
  const [unrecognizedProductCodes, setUnrecognizedProductCodes] =
    useState<any[]>();
  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    onChange(info) {
      if (info.fileList.length === 0) {
        setFileList([]);
      }

      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
  };

  const handleNext = async () => {
    try {
      setUploading(true);

      const file = fileList?.[0];
      const formData = new FormData();
      formData.append("file.xlsx", file);
      try {
        const response = await service.uploadBulk(formData);
        onChange(response);
      } catch (uploadError) {
        message.error("File upload failed."); // Notify user about the error
      }
    } catch (error) {
    } finally {
      setUploading(false);
    }
  };

  const handleCheckSkuCodes = useCallback(
    debounce(1000, async (data) => {
      try {
        setCheckingSkuCodes(true);
        const skuCodes = data.map((row) => row["OMS SKU Code"]);
        const uniqueSkuCodes = Array.from(new Set(skuCodes));
        const response = await service.getUnrecognizedSkuCodes(uniqueSkuCodes);
        const unrecognizedSkuCodes: string[] = response.unrecognizedSkuCodes;
        console.log(unrecognizedSkuCodes);
        const unrecognizedProductCodes = unrecognizedSkuCodes
          .map((skuCode) => skuCode?.substring(0, skuCode?.lastIndexOf("_")))
          .filter(Boolean)
          .sort((a, b) => a.localeCompare(b));
        setUnrecognizedProductCodes(
          Array.from(new Set(unrecognizedProductCodes))
        );
        setUnrecognizedSkuCodes(unrecognizedSkuCodes);
      } catch (error) {
        console.log(error);
      } finally {
        setCheckingSkuCodes(false);
      }
    }),
    []
  );

  const validateColumns = (row: { [key: string]: any }): string[] => {
    return REQUIRED_COLUMNS.filter((column) => !(column in row));
  };
  const findEmptyRequiredValues = (
    data: Array<{ [key: string]: any }>
  ): any[] => {
    let emptyDetails: any[] = [];

    data.forEach((row, index) => {
      REQUIRED_COLUMNS.forEach((column) => {
        if (!(column in row) || row[column] === null || row[column] === "") {
          emptyDetails.push({ row: index + 2, column: column });
        }
      });
    });

    return emptyDetails;
  };

  const readFile = useCallback((file: File) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const workbook = XLSX.read(e.target.result, {
          type: "binary",
          raw: true,
        });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData: any = XLSX.utils
          .sheet_to_json(worksheet, {
            defval: null,
          })
          .filter((row) => row["OMS SKU Code"]);
        const countMap = new Map();
        for (let row of jsonData) {
          const key = row["OMS SKU Code"];
          countMap.set(key, (countMap.get(key) || 0) + 1);
        }
        setData(jsonData);
        const clientVendorMap = {};
        setDuplicateSkuCodes(
          Array.from(countMap.keys()).filter((key) => countMap.get(key) > 1)
        );

        for (let row of jsonData) {
          if (!row.Warehouse || !row.Vendor) {
            continue;
          }

          clientVendorMap[row.Warehouse.toUpperCase().trim()] = new Set([
            ...(clientVendorMap[row.Warehouse.toUpperCase().trim()] || []),
            row.Vendor?.toUpperCase().trim(),
          ]);
        }

        const clientVendorResults = await Promise.all(
          Object.keys(clientVendorMap).map((key) =>
            vendorService.getForIds(key, Array.from(clientVendorMap[key]))
          )
        );

        const validVendors = clientVendorResults.reduce(
          (acc, result, index) => {
            acc[Object.keys(clientVendorMap)[index]] = result;
            return acc;
          },
          {}
        );

        const clients: any = await clientService.getAll();
        const inputClientAliases = Array.from(
          new Set<string>(
            jsonData.map((row: { Warehouse: string }) =>
              row.Warehouse.trim().toUpperCase()
            )
          )
        );
        const invalidClientsByAlias: string[] = inputClientAliases.filter(
          (alias: string) =>
            !clients
              .map((client: { alias: string }) =>
                client.alias.trim().toUpperCase()
              )
              .includes(alias.trim().toUpperCase())
        );
        setInvalidClients(invalidClientsByAlias);

        const invalidVendors = {};

        Object.keys(clientVendorMap).forEach((key) => {
          const validVendorsForClient = validVendors[key];
          const clientVendors = clientVendorMap[key];
          console.log("clientVendors", clientVendors);
          const invalid = Array.from(clientVendors).filter(
            (v) => !validVendorsForClient.map((vo) => vo.code).includes(v)
          );
          invalidVendors[key] = invalid;
        });
        setInvalidVendors(invalidVendors);
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      message.error("Error reading the file.");
    }
  }, []);

  useEffect(() => {
    if (fileList.length === 0) {
      return () => {};
    }
    readFile(fileList[0]);
  }, [fileList]);

  useEffect(() => {
    if (fileList.length === 0) {
      setReportRender(null);
      return () => {};
    }

    if (data.length === 0) {
      setReportRender(
        <Alert
          className="mt-4"
          type="error"
          message="No data was found in the file, please validate that mappings are present in the first sheet."
          showIcon
        />
      );
      return () => {};
    }

    const missingColumns = validateColumns(data[0]);

    if (missingColumns.length > 0) {
      setReportRender(
        <Alert
          className="mt-4"
          type="error"
          message={`Missing required columns:`}
          description={`${missingColumns.join(", ")}`}
          showIcon
        />
      );
      return () => {};
    }

    setReportRender(undefined);

    const emptyDetails: { row: number; column: string }[] =
      findEmptyRequiredValues(data);

    if (emptyDetails?.length > 0) {
      const missingRequiredValuesMap: { [key: string]: number[] } = {};
      emptyDetails.forEach((detail) => {
        if (!missingRequiredValuesMap[detail.column]) {
          missingRequiredValuesMap[detail.column] = [];
        }
        missingRequiredValuesMap[detail.column].push(detail.row);
      });
      handleCheckSkuCodes(data);
      setReportRender(
        <Alert
          className="mt-4"
          type="error"
          description={
            <div>
              {Object.entries(missingRequiredValuesMap).map(
                ([column, rows]) => (
                  <div key={column}>
                    <strong>{titleCase(column)}:</strong>{" "}
                    <Tooltip
                      placement="right"
                      title={
                        <div>
                          <div>Rows: {rows.join(", ")}</div>
                        </div>
                      }
                    >
                      <span className="text-blue-500">
                        Rows <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </div>
                )
              )}
            </div>
          }
          message={
            <div>
              <div>Some required values are missing in the file.</div>
            </div>
          }
          showIcon
        />
      );
      return () => {};
    }
  }, [data, fileList]);

  return (
    <div>
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
      {error.title && (
        <Alert
          message={error.title}
          description={error.message}
          type="error"
          showIcon
        />
      )}
      <Divider className="my-2" />
      <div className="flex justify-between ">
        <Button disabled>Previous</Button>
        <Button
          type="primary"
          onClick={handleNext}
          disabled={fileList.length === 0 || uploading}
          loading={uploading}
        >
          Next
        </Button>
      </div>
      <div>{reportRender}</div>
      {Object.values(invalidVendors || {}).flat().length > 0 && (
        <Alert
          className="mt-2"
          type="error"
          message="Invalid Vendor Codes"
          description={Object.entries(invalidVendors || {}).map(
            ([key, value]) => (
              <div key={key}>
                <strong>{key}:</strong> {(value as string[]).join(", ")}
              </div>
            )
          )}
          showIcon
        />
      )}

      {invalidClients.length > 0 && (
        <Alert
          className="mt-2"
          type="error"
          message="Invalid Client Codes"
          description={invalidClients.join(", ")}
          showIcon
        />
      )}

      <div className="mt-2">
        {checkingSkuCodes ? (
          <Alert
            type="info"
            message="Checking for unrecognized SKU codes in the file..."
            showIcon
          />
        ) : unrecognizedSkuCodes ? (
          unrecognizedSkuCodes.length > 0 ? (
            <Alert
              type="warning"
              message={`Please check for ${unrecognizedSkuCodes.length} unrecognized SKU codes in the file.`}
              description={`${unrecognizedProductCodes.join(", ")}`}
              showIcon
            />
          ) : (
            <Alert
              type="success"
              message="Please check for unrecognized SKU codes in the file."
              showIcon
            />
          )
        ) : null}
      </div>
    </div>
  );
};

const ApproveProcess: React.FC<any> = ({
  data,
  onChange,
  onPrevious,
  loading,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [confirmModal, setConfirmModal] = useState(false);
  const [hasError, setHasError] = useState("");
  useEffect(() => {
    const data = [
      ...mappingsToBeCreated.map((d) => ({ ...d.device, action: "Create" })),
      ...mappingsToBeUpdated.map((d) => ({ ...d.device, action: "Update" })),
      ...unlinkableMappings.map((d) => ({ ...d.device, action: "Update" })),
    ];
  }, [data]);
  const handleNext = useCallback(() => {
    onChange();
  }, [data, onChange]);
  const columns: any[] = [
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      filters: [
        { text: "Create", value: "Create" },
        { text: "Update", value: "Update" },
        { text: "Unlinkable", value: "Unlinkable" },
      ],
      onFilter: (value: string, record) => record.action === value,
      sorter: (a, b) => (a.action < b.action ? -1 : 1),
    },
    ...defaultColumns,
  ];
  const {
    mappingsToBeCreated,
    mappingsToBeUpdated,
    unlinkableMappings,
    uploadId,
  } = data;
  return (
    <div className="overflow-data-table">
      <Table
        className=""
        pagination={{ defaultPageSize: 20 }}
        title={() => (
          <Space>
            <Tag color="red">{unlinkableMappings.length} UNLINKABLE</Tag>
            <Tag color="blue">{mappingsToBeCreated.length} CREATING</Tag>
            <Tag color="green">{mappingsToBeUpdated.length} UPDATING</Tag>
          </Space>
        )}
        dataSource={[
          ...unlinkableMappings.map((d) => ({
            ...d,
            action: "Unlinkable",
          })),
          ...mappingsToBeCreated.map((d) => ({
            ...d,
            action: "Create",
          })),
          ...mappingsToBeUpdated.map((d) => ({
            ...d,
            action: "Update",
          })),
        ]}
        size="small"
        columns={columns}
      />
      {!!hasError && <Alert message={hasError} type="error" showIcon />}
      <Divider className="my-2" />
      <div className="flex justify-between">
        <Button onClick={onPrevious}>Previous</Button>
        <Button
          type="primary"
          onClick={handleNext}
          loading={loading}
          disabled={!!hasError}
        >
          Approve
        </Button>
      </div>
    </div>
  );
};

const Report = (props: any) => {
  const { data } = props;
  const columns: any[] = [
    {
      key: "action",
      title: "Action",
      dataIndex: "action",
      filters: [
        { text: "Created", value: "Created" },
        { text: "Updated", value: "Updated" },
      ],
      onFilter: (value: string, record) => record.action === value,
      sorter: (a, b) => (a.action < b.action ? -1 : 1),
    },
    ...defaultColumns,
  ];
  const { mappingsCreated, mappingsUpdated } = data || {};
  return (
    <div className="overflow-data-table">
      {data && (
        <Table
          title={() => (
            <Space>
              <Tag color="blue">{mappingsCreated.length} CREATED</Tag>
              <Tag color="green">{mappingsUpdated.length} UPDATED</Tag>
            </Space>
          )}
          dataSource={[
            ...mappingsCreated.map((d) => ({
              ...d,
              action: "Created",
            })),
            ...mappingsUpdated.map((d) => ({
              ...d,
              action: "Updated",
            })),
          ]}
          pagination={{ defaultPageSize: 20 }}
          size="small"
          columns={columns}
        />
      )}
    </div>
  );
};

export default function Bulk() {
  const [step, setStep] = useState(0);
  const [isBlocking, setIsBlocking] = useState(true);
  const [uploadResult, setUploadResult] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState<any>();
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "Bulk Process" },
  ]);
  useEffect(() => {
    window.onbeforeunload = isBlocking ? () => true : undefined;
  }, [isBlocking]);
  const handleApprove = async () => {
    try {
      setLoading(true);
      const report = await service.processBulk({ ...uploadResult });
      setReport(report);
      setStep(2);
    } catch (error) {
      // throw error;
    } finally {
      setLoading(false);
    }
  };
  const render = () => {
    switch (step) {
      case 0: {
        return (
          <UploadRender
            onChange={(data) => {
              setStep(1);
              setUploadResult(data);
            }}
          />
        );
      }
      case 1: {
        return (
          <ApproveProcess
            data={uploadResult}
            onChange={handleApprove}
            onPrevious={() => setStep(0)}
            loading={loading}
          />
        );
      }
      case 2: {
        return <Report data={report} onPrevious={() => setStep(0)} />;
      }
    }
  };
  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        New Mapping
      </div>
      <div className="mt-8">
        <Steps
          progressDot
          size="small"
          current={step}
          onChange={() => {}}
          items={[
            {
              title: "Upload File",
            },
            {
              title: "Approve & Process",
            },
            {
              title: "Review Report",
            },
          ]}
        />
      </div>
      <div className="bg-white rounded-lg mt-2 shadow px-2 py-2">
        {render()}
      </div>
    </div>
  );
}
