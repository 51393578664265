import { Button, Col, DatePicker, Form, Row, Table } from "antd";
import skuService from "../../../services/sku";
import poService from "../../../services/po";
import { Typography } from "antd";
import { useEffect, useState } from "react";
import saveAs from "file-saver";
import { DownloadOutlined } from "@ant-design/icons";
import dayjs, { Dayjs } from "dayjs";
import * as XLSX from "xlsx"; // Added import
const { Title } = Typography;
function POSummaryReport() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleLoad = async (values: { duration: [Dayjs, Dayjs] }) => {
    try {
      setLoading(true);
      const data = await poService.reportSummary({ duration: values.duration });
      setData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleDownloadXlsx = () => {
    // Define headers matching AntD table
    const headers = [
      "PO ID",
      "Date",
      "Created By",
      "Vendor",
      "Ordered Quantity",
      "Received Quantity",
      "Rejected Quantity",
      "Pending Quantity",
    ];

    // Transform data to match headers
    const transformedData = data.map((item) => ({
      "PO ID": item.id,
      Date: dayjs(item.date).format("DD-MM-YYYY"),
      "Created By": item.createdBy,
      Vendor: item.vendor,
      "Ordered Quantity": item.totalOrdered,
      "Received Quantity": item.totalReceived,
      "Rejected Quantity": item.totalRejected,
      "Pending Quantity": item.totalPending,
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData, {
      header: headers,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "POSummaryReport");
    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "POSummaryReport.xlsx");
  };
  const columns = [
    { title: "PO ID", dataIndex: "id", key: "id" },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("DD-MM-YYYY"),
    },
    { title: "Created By", dataIndex: "createdBy", key: "createdBy" },
    { title: "Vendor", dataIndex: "vendor", key: "vendor" },
    {
      title: "Ordered Quantity",
      dataIndex: "totalOrdered",
      key: "totalOrdered",
    },
    {
      title: "Received Quantity",
      dataIndex: "totalReceived",
      key: "totalReceived",
    },
    {
      title: "Rejected Quantity",
      dataIndex: "totalRejected",
      key: "totalRejected",
    },
    {
      title: "Pending Quantity",
      dataIndex: "totalPending",
      key: "totalPending",
    },
  ];
  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={3}>PO Summary Report</Title>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <div>
        <Form form={form} onFinish={handleLoad}>
          <Form.Item label="Duration" name="duration">
            <DatePicker.RangePicker />
          </Form.Item>
          <Form.Item noStyle>
            <div className="flex gap-x-2">
              <Button type="primary" htmlType="submit">
                Load
              </Button>
              {data.length > 0 && ( // Added conditional rendering
                <Button
                  type="default"
                  onClick={handleDownloadXlsx}
                  icon={<DownloadOutlined />}
                  className="ml-2"
                >
                  Download as XLSX
                </Button>
              )}
            </div>
          </Form.Item>
        </Form>
      </div>
      <div className="mt-4">
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
}

export default POSummaryReport;
