import { getRequest, postRequest } from "./config/request";
const SERVICE_URL = "/report";

export const getProfiledProducts = (warehouse: string) =>
  getRequest(`${SERVICE_URL}/products/${warehouse}/profiled-products`);
export const getProfiledProductsByChannel = (
  warehouse: string,
  channel: string
) =>
  getRequest(
    `${SERVICE_URL}/products/${warehouse}/profiled-products/channel/${channel}`
  );
export const getProfiledProductsByBrand = (warehouse: string, brand: string) =>
  getRequest(
    `${SERVICE_URL}/products/${warehouse}/profiled-products/brand/${brand}`
  );
export const generatePurchaseOrderReport = (
  warehouse: string,
  start: string,
  end: string,
  trafficIndex: number,
  newItemsSalesCutOff: number
) =>
  postRequest(`${SERVICE_URL}/roas/`, {
    warehouse,
    start,
    end,
    trafficIndex,
    newItemsSalesCutOff,
  });

export default {
  getProfiledProducts,
  getProfiledProductsByChannel,
  getProfiledProductsByBrand,
  generatePurchaseOrderReport,
};
