import AppLayout from "../layouts/AppLayout";
import {
  Routes,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
const router = createBrowserRouter([
  {
    path: "/auth/*",
    element: <AuthLayout />,
  },
  {
    path: "*",
    element: <AppLayout />,
  },
]);

export default function RouteConfig(props: any) {
  return <RouterProvider router={router} />;
}
