export default [
  {
    title: "Channel Order ID",
    dataIndex: "channelOrderId",
    key: "channelOrderId",
  },
  {
    title: "Channel Sub Order ID",
    dataIndex: "channelSubOrderId",
    key: "channelSubOrderId",
  },
  {
    title: "Shipping Charge Per Item",
    dataIndex: "shippingChargePerItem",
    key: "shippingChargePerItem",
  },
  {
    title: "Promo Discounts",
    dataIndex: "promoDiscounts",
    key: "promoDiscounts",
  },
  {
    title: "Gift Wrap Charges",
    dataIndex: "giftWrapCharges",
    key: "giftWrapCharges",
  },
  {
    title: "Transaction Charges",
    dataIndex: "transactionCharges",
    key: "transactionCharges",
  },
  { title: "Currency Code", dataIndex: "currencyCode", key: "currencyCode" },

  { title: "IGST Rate", dataIndex: "igstRate", key: "igstRate" },
  { title: "IGST Amount", dataIndex: "igstAmount", key: "igstAmount" },
  { title: "CGST Rate", dataIndex: "cgstRate", key: "cgstRate" },
  { title: "CGST Amount", dataIndex: "cgstAmount", key: "cgstAmount" },
  { title: "SGST Rate", dataIndex: "sgstRate", key: "sgstRate" },
  { title: "SGST Amount", dataIndex: "sgstAmount", key: "sgstAmount" },
  {
    title: "Settlement Amount",
    dataIndex: "settlementAmount",
    key: "settlementAmount",
  },
  { title: "SKU UPC", dataIndex: "skuUpc", key: "skuUpc" },
  { title: "Listing SKU", dataIndex: "listingSku", key: "listingSku" },
  { key: "weltSkuCode", title: "Welt SKU Code", dataIndex: "weltSkuCode" },
  { key: "omsSkuCode", title: "OMS SKU Code", dataIndex: "omsSkuCode" },
  { key: "brand", title: "Brand", dataIndex: "brand" },
  { key: "image", title: "Image", dataIndex: "image" },
  { key: "purchasePrice", title: "Purchase Price", dataIndex: "purchasePrice" },
  { key: "parentSku", title: "Parent SKU", dataIndex: "parentSku" },
  { key: "mrp", title: "MRP", dataIndex: "mrp" },
  {
    key: "bauSellinPrice",
    title: "BAU Sellin Price",
    dataIndex: "bauSellinPrice",
  },
  { key: "category", title: "Category", dataIndex: "category" },
  { key: "warehouse", title: "Warehouse", dataIndex: "warehouse" },
  { key: "season", title: "Season", dataIndex: "season" },
  { key: "gstRate", title: "GST Rate", dataIndex: "gstRate" },
  { key: "hsnCode", title: "HSN Code", dataIndex: "hsnCode" },
  {
    key: "flipkartSellerSkuCode",
    title: "Flipkart Seller SKU Code",
    dataIndex: "flipkartSellerSkuCode",
  },
  { key: "flipkartFsn", title: "Flipkart FSN", dataIndex: "flipkartFsn" },
  { key: "flipkartLid", title: "Flipkart LID", dataIndex: "flipkartLid" },
  {
    key: "myntraStyleId",
    title: "Myntra Style ID",
    dataIndex: "myntraStyleId",
  },
  {
    key: "myntraSkuCode",
    title: "Myntra SKU Code",
    dataIndex: "myntraSkuCode",
  },
  {
    key: "myntraSellerSkuCode",
    title: "Myntra Seller SKU Code",
    dataIndex: "myntraSellerSkuCode",
  },
  { key: "myntraSkuId", title: "Myntra SKU ID", dataIndex: "myntraSkuId" },
  { key: "ajioSkuCode", title: "Ajio SKU Code", dataIndex: "ajioSkuCode" },
  {
    key: "ajioSellerSkuCode",
    title: "Ajio Seller SKU Code",
    dataIndex: "ajioSellerSkuCode",
  },
  {
    key: "amazonSellerSkuCode",
    title: "Amazon Seller SKU Code",
    dataIndex: "amazonSellerSkuCode",
  },
  {
    key: "amazonSkuCode",
    title: "Amazon SKU Code",
    dataIndex: "amazonSkuCode",
  },
];
