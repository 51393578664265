import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from "../services/settings";
const TYPE = "SETTING";

export interface SettingState {
  apiKeys: any[];
  zones: any[];
  mappings: any[];
  channels: any[];
  brands: any[];
  purchaseWarehouses: any[];
  connectedToQzTray: boolean;
  sizeDistributions: any[];
  loading: boolean;
  selectedApiKey: any;
}

const initialState: SettingState = {
  apiKeys: [],
  zones: [],
  mappings: [],
  channels: [],
  brands: [],
  sizeDistributions: [],
  connectedToQzTray: false,
  purchaseWarehouses: [],
  loading: false,
  selectedApiKey: null,
};

export const getSelectedApiKeyAsync = createAsyncThunk(
  `${TYPE}/GET_SELECTED_API_KEY`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.validateApiKey();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadPurchaseWarehousesAsync = createAsyncThunk(
  `${TYPE}/LOAD_PURCHASE_WAREHOUSES`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getPurchaseWarehouses();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadApiKeysAsync = createAsyncThunk(
  `${TYPE}/LOAD_API_KEYS`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getApiKeys();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const loadZonesAsync = createAsyncThunk(
  `${TYPE}/LOAD_ZONES`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getZones();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadMappingsAsync = createAsyncThunk(
  `${TYPE}/LOAD_MAPPINGS`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getMappings();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadChannelsAsync = createAsyncThunk(
  `${TYPE}/LOAD_CHANNELS`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getChannels();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadBrandsAsync = createAsyncThunk(
  `${TYPE}/LOAD_BRANDS`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getBrands();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const loadSizeDistributionsAsync = createAsyncThunk(
  `${TYPE}/LOAD_SIZE_DISTRIBUTIONS`,
  async (payload: any, thunkAPI) => {
    try {
      const response = await service.getSizeDistributions();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const warehouseSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setConnectedToQzTray: (state, action) => {
      state.connectedToQzTray = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadApiKeysAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadApiKeysAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.apiKeys = action.payload;
    });
    builder.addCase(loadApiKeysAsync.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getSelectedApiKeyAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSelectedApiKeyAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedApiKey = action.payload;
    });
    builder.addCase(getSelectedApiKeyAsync.rejected, (state, action) => {
      state.loading = false;
      state.selectedApiKey = action.payload;
    });
    builder.addCase(loadZonesAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadZonesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.zones = action.payload;
    });
    builder.addCase(loadZonesAsync.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadMappingsAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadMappingsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.mappings = action.payload;
    });
    builder.addCase(loadMappingsAsync.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadChannelsAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadChannelsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.channels = action.payload;
    });
    builder.addCase(loadChannelsAsync.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadBrandsAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadBrandsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.brands = action.payload;
    });
    builder.addCase(loadBrandsAsync.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadSizeDistributionsAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadSizeDistributionsAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.sizeDistributions = action.payload;
    });
    builder.addCase(loadSizeDistributionsAsync.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(loadPurchaseWarehousesAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadPurchaseWarehousesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.purchaseWarehouses = action.payload;
    });
    builder.addCase(loadPurchaseWarehousesAsync.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setConnectedToQzTray } = warehouseSlice.actions;

export default warehouseSlice.reducer;
