import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/invoice";

export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);
export const paginateItems = (qs: string) =>
  getRequest(`${SERVICE_URL}/items?${qs}`);
export const getOrderItems = (id: string) =>
  getRequest(`${SERVICE_URL}/${id}/order-items`);
export default {
  paginate,
  getOrderItems,
  paginateItems,
};
