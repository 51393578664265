import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import service from "../../services/grn";
import {
  Button,
  Col,
  Descriptions,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import Title from "antd/es/typography/Title";
import {
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

function getSKUColumns(skus: any[]) {
  const processedProducts = [];

  for (let sku of skus) {
    const payload = {
      productCode: sku.productCode,
      purchasePrice: sku.purchasePrice,
    };
    for (let quantity of sku.quantities) {
      payload[quantity.size] = {
        received: quantity.received,
        rejected: quantity.rejected,
      };
    }
  }

  return [];
}

const handleLoad = (id: string, setData: any, setLoading) => {
  service.get(id).then((p) => {
    setData(p);
    setLoading(false);
  });
};

export function Info(props: any) {
  return (
    <Descriptions className="mt-3">
      <Descriptions.Item label="ID">{props.data.id}</Descriptions.Item>
      <Descriptions.Item label="Vendor Challan ID">
        {props.data.vendorChallanID}
      </Descriptions.Item>
      <Descriptions.Item label="Vendor Challan Date">
        {dayjs(props.data.vendorChallanDate).format("DD/MM/YY hh:mm A")}
      </Descriptions.Item>
      <Descriptions.Item label="Purchase Order ID">
        {props.data.purchaseOrder.id}
      </Descriptions.Item>
      <Descriptions.Item label="Total Received">
        {props.data.totalReceivedQuantity}
      </Descriptions.Item>
      <Descriptions.Item label="Total Rejected">
        {props.data.totalRejectedQuantity}
      </Descriptions.Item>
    </Descriptions>
  );
}

export function SKUTable(props: any) {
  const sizeColumns = Array.from(
    new Set(
      props.data.skus
        .map((sku) => sku.quantities)
        .flat()
        .map((q) => q.size)
    )
  );

  const [newPurchasePrices, setNewPurchasePrices] = useState<any>({});
  const [editingKeys, setEditingKeys] = useState<number[]>([]);
  const data: any[] = [];
  for (let sku of props.data?.skus || []) {
    const payload = {
      productCode: sku.productCode,
      purchasePrice: sku.purchasePrice,
    };
    for (let quantity of sku.quantities) {
      payload[quantity.size] = {
        received: quantity.received,
        rejected: quantity.rejected,
      };
    }
    data.push(payload);
  }

  const handleSave = async (
    index: number,
    productCode: string,
    purchasePrice: number
  ) => {
    try {
      await service.updatePurchasePriceForProductCode({
        goodsReceiveNoteId: props.data._id,
        productCode,
        purchasePrice,
      });
      props.onRefresh();
      setEditingKeys(editingKeys.filter((key) => key !== index));
      setNewPurchasePrices({
        ...newPurchasePrices,
        [index]: undefined,
      });
      message.success("Purchase price updated successfully");
    } catch (error) {
      message.error("Failed to update purchase price");
      console.log(error);
    }
  };

  return (
    <>
      <Title level={4} className="p-2 mt-2">
        Products
      </Title>
      <Table
        size="small"
        dataSource={data.filter((r) => r.purchasePrice)}
        columns={[
          {
            title: "Product Code",
            dataIndex: "productCode",
            key: "productCode",
          },
          {
            title: "Purchase Price",
            dataIndex: "purchasePrice",
            key: "purchasePrice",
            render: (text: any, record: any, index: number) => {
              return editingKeys.includes(index) ? (
                <div className="flex gap-x-2">
                  <InputNumber
                    size="small"
                    prefix={"₹"}
                    defaultValue={text}
                    onChange={(value) =>
                      setNewPurchasePrices({
                        ...newPurchasePrices,
                        [index]: value,
                      })
                    }
                  />
                  <span
                    className="cursor-pointer text-green-500"
                    onClick={() =>
                      newPurchasePrices[index]
                        ? handleSave(
                            index,
                            record.productCode,
                            newPurchasePrices[index]
                          )
                        : {}
                    }
                  >
                    <CheckOutlined />
                  </span>
                  <span
                    className="cursor-pointer text-red-500"
                    onClick={() => {
                      setEditingKeys(
                        editingKeys.filter((key) => key !== index)
                      );
                      setNewPurchasePrices({
                        ...newPurchasePrices,
                        [index]: undefined,
                      });
                    }}
                  >
                    <CloseOutlined />
                  </span>
                </div>
              ) : (
                <div className="flex gap-x-2">
                  <span>₹{text?.toFixed(2) || ""}</span>
                  <span
                    className="cursor-pointer text-blue-500"
                    onClick={() => setEditingKeys([...editingKeys, index])}
                  >
                    <EditOutlined />
                  </span>
                </div>
              );
            },
          },
          ...sizeColumns.map((size: string) => ({
            title: size,
            dataIndex: `size.${size}`,
            key: `size.${size}`,
            render: (text: any, record: any) => {
              return record[size] ? (
                <Tooltip
                  title={`${record[size]?.received} received & ${record[size]?.rejected} rejected`}
                >
                  <Space>
                    <span className="text-green-600">
                      {record[size]?.received}
                    </span>
                    <span className="text-gray-300">/</span>
                    <span className="text-red-700">
                      {record[size]?.rejected}
                    </span>
                  </Space>
                </Tooltip>
              ) : (
                <></>
              );
            },
          })),
        ]}
      />
    </>
  );
}

export default function View(props: any) {
  const params = useParams();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);

  const [showVoidModal, setShowVoidModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  console.log(queryParams);
  useEffect(() => {
    if (params.id) {
      handleLoad(params.id, setData, setLoading);
    }
  }, [params]);

  const voidGRN = () => {
    if (!data) return;
    service.voidGRN(params.id).then(() => {
      message.success("GRN voided successfully");
      handleLoad(params.id, setData, setLoading);
    });
  };

  return (
    <Spin spinning={loading}>
      {data && (
        <div>
          <Modal
            title="Void GRN"
            open={showVoidModal}
            onOk={() => {
              setShowVoidModal(false);
              voidGRN();
            }}
            onCancel={() => setShowVoidModal(false)}
          >
            <p>Are you sure you want to void this GRN?</p>
          </Modal>
          <Row justify="space-between">
            <Col>
              <Title level={3} className="flex items-center gap-x-2">
                {data.id} <Tag>{!data?.isActive ? "Void" : ""}</Tag>{" "}
              </Title>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>
          <div className="bg-white rounded-lg p-2">
            <Info data={data} />
          </div>
          {data.isActive && (
            <div className="flex gap-x-2 justify-end mt-2">
              <Button
                type="link"
                icon={<CloseCircleOutlined />}
                onClick={() => setShowVoidModal(true)}
              >
                Void GRN
              </Button>
            </div>
          )}
          <SKUTable
            data={data}
            isEditing={queryParams.get("edit")}
            onRefresh={() => handleLoad(params.id, setData, setLoading)}
          />
        </div>
      )}
    </Spin>
  );
}
