// src/hooks/useMenuItems.ts
import React from "react";
import {
  DashboardOutlined,
  SettingOutlined,
  KeyOutlined,
  LayoutOutlined,
  CopyOutlined,
  IdcardOutlined,
  ShoppingCartOutlined,
  FundOutlined,
  BoxPlotOutlined,
  SubnodeOutlined,
  UserOutlined,
  PrinterOutlined,
  DesktopOutlined,
} from "@ant-design/icons";
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import { TbCreditCardPay } from "react-icons/tb";
import { LuArrowLeftRight } from "react-icons/lu";
import { TiDocumentAdd, TiDocumentText } from "react-icons/ti";
import { BsDownload } from "react-icons/bs";
import { MdOutlineSummarize, MdChecklist } from "react-icons/md";
import { GrRevert } from "react-icons/gr";

import type { MenuProps } from "antd";

/**
 * Type definition for a single menu item.
 */
type MenuItem = Required<MenuProps>["items"][number];

/**
 * Helper function to create a menu item.
 *
 * @param label - The display label for the menu item.
 * @param key - The unique key for the menu item.
 * @param link - The navigation link associated with the menu item.
 * @param icon - The icon component for the menu item.
 * @param children - An array of child menu items for nested menus.
 * @returns A MenuItem object.
 */
const getItem = (
  label: React.ReactNode,
  key: React.Key,
  link?: string,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    link,
  } as MenuItem;
};

/**
 * Custom hook to provide the menu items configuration.
 *
 * @returns An array of MenuItem objects representing the application's sidebar menu.
 */
const useMenuItems = (): MenuItem[] => {
  const items: MenuItem[] = [
    getItem("Dashboard", "dashboard", "dashboard", <DashboardOutlined />),
    // Uncomment and customize the Invoices section if needed
    // getItem("Invoices", "invoices", "invoices", <SnippetsOutlined />, [
    //   getItem("Summarized", "invoices", "invoices", <MdOutlineSummarize />),
    //   getItem("Itemized", "invoices/items", "invoices/items", <MdChecklist />),
    // ]),
    getItem("Purchases", "purchases", "purchases", <ShoppingCartOutlined />, [
      getItem(
        "All",
        "purchases/purchase-orders/",
        "purchases/purchase-orders/",
        <MdChecklist />
      ),
      getItem(
        "Open",
        "purchases/purchase-orders",
        "purchases/purchase-orders/open",
        <MdChecklist />
      ),
      getItem(
        "GRNs",
        "purchases/purchase-orders/goods-receive-notes/",
        "purchases/purchase-orders/goods-receive-notes/",
        <MdChecklist />
      ),
    ]),
    getItem(
      "Raw Material POs",
      "raw-material-purchase-orders",
      "raw-material-purchase-orders",
      <IdcardOutlined />
    ),

    getItem("Orders", "orders", "orders", <CopyOutlined />, [
      getItem("Summarized", "orders", "orders", <MdOutlineSummarize />),
      getItem("Itemized", "orders/items", "orders/items", <MdChecklist />),
    ]),
    getItem("Finance", "finance", "finance", <HiOutlineCurrencyRupee />, [
      getItem(
        "Payments",
        "finance/payments",
        "finance/payments",
        <TbCreditCardPay />
      ),
      getItem(
        "Credit Notes",
        "finance/vendor-credit-note",
        "finance/vendor-credit-note",
        <MdOutlineSummarize />
      ),
      getItem(
        "Debit Notes",
        "finance/vendor-debit-note",
        "finance/vendor-debit-note",
        <MdOutlineSummarize />
      ),
      getItem(
        "Journal",
        "finance/journal",
        "finance/journal",
        <TiDocumentAdd />
      ),
      getItem(
        "Ledgers",
        "finance/ledgers",
        "finance/ledgers",
        <TiDocumentText />
      ),
    ]),
    getItem("Reports", "reports", "reports", <FundOutlined />, [
      getItem(
        "Master SKU Report",
        "reports/sku-report",
        "reports/sku-report",
        <MdOutlineSummarize />
      ),
      getItem(
        "PO Summary Report",
        "reports/po-summary-report",
        "reports/po-summary-report",
        <MdOutlineSummarize />
      ),
      getItem(
        "Pending PO Report",
        "reports/pending-po-report",
        "reports/pending-po-report",
        <MdOutlineSummarize />
      ),
      getItem(
        "GRN Report",
        "reports/grn-summary-report",
        "reports/grn-summary-report",
        <MdOutlineSummarize />
      ),
    ]),
    getItem("Manual Inward", "inward", "inward", <BsDownload />),
    getItem("Returns", "returns", "returns", <GrRevert />),
    getItem("SKUs", "skus", "skus", <DesktopOutlined />),
    getItem("Vendors", "vendors", "vendors", <IdcardOutlined />),
    getItem("Suppliers", "suppliers", "suppliers", <IdcardOutlined />),
    getItem("Settings", "settings", "", <SettingOutlined />, [
      getItem(
        "API Key",
        "settings/api-key",
        "settings/api-key",
        <KeyOutlined />
      ),
      getItem(
        "Cut Off Style ID",
        "settings/cut-off-style-id",
        "settings/cut-off-style-id",
        <KeyOutlined />
      ),
      getItem(
        "Mapping",
        "settings/mapping",
        "settings/mapping",
        <LuArrowLeftRight />
      ),
      getItem("Zones", "settings/zone", "settings/zone", <LayoutOutlined />),
      getItem(
        "Raw Materials",
        "settings/raw-material",
        "settings/raw-material",
        <LayoutOutlined />
      ),
      getItem(
        "Size Distribution",
        "settings/size-distribution",
        "settings/size-distribution",
        <BoxPlotOutlined />
      ),
      getItem("Roles", "settings/role", "settings/role", <SubnodeOutlined />),
      getItem(
        "Label Printers",
        "settings/label-printer",
        "settings/label-printer",
        <PrinterOutlined />
      ),
      getItem("Users", "settings/user", "settings/user", <UserOutlined />),
    ]),
  ];

  return items;
};

export default useMenuItems;
