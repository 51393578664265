import { Button, Table, Tag } from "antd";
import { Store } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import * as SettingActions from "../../../store/settingSlice";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, StopOutlined } from "@ant-design/icons";
function ApiKeys(props: any) {
  const navigate = useNavigate();
  const settingState = useSelector((state: Store) => state.setting);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SettingActions.loadApiKeysAsync({}));
  }, []);
  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        API Keys
        <Button
          type="primary"
          onClick={() => navigate("/settings/api-key/form")}
          icon={<PlusOutlined />}
        >
          Add New
        </Button>
      </div>

      <Table
        className="mt-4"
        bordered
        pagination={false}
        size="small"
        dataSource={settingState.apiKeys}
        columns={[
          {
            title: "Key",
            dataIndex: "key",
            key: "key",
            render: (key, record) => (
              <div className="flex justify-between items-center">
                {key}
                <div>
                  {record.isActive ? (
                    <Tag bordered={false} color="green">
                      Active
                    </Tag>
                  ) : (
                    <Tag bordered={false} color="red">
                      Inactive
                    </Tag>
                  )}
                  {record.isValid ? (
                    <Tag bordered={false} color="green">
                      Valid
                    </Tag>
                  ) : (
                    <Tag bordered={false} color="red">
                      Invalid
                    </Tag>
                  )}
                </div>
              </div>
            ),
          },

          {
            title: "Validated At",
            dataIndex: "validatedAt",
            key: "validatedAt",
            render: (validatedAt) =>
              validatedAt && dayjs(validatedAt).format("DD/MM/YYYY hh:mm A"),
          },
          {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (createdAt) =>
              dayjs(createdAt).format("DD/MM/YYYY hh:mm A"),
          },
          {
            title: "Expires At",
            dataIndex: "expiresAt",
            key: "expiresAt",
            render: (expiresAt) =>
              dayjs(expiresAt).format("DD/MM/YYYY hh:mm A"),
          },
          {
            title: "Action",
            key: "action",
            render: (text, record) => (
              <Button size="small" type="link" icon={<StopOutlined />}>
                Suspend
              </Button>
            ),
          },
        ]}
      />
    </div>
  );
}

export default ApiKeys;
