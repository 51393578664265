import { Routes, Route } from "react-router-dom";
import Form from "./Form";
import Bulk from "./Bulk";
import List from "./List";
export const MODEL = "client";
export const BASE_PATH = "/clients";
export const TITLE = ["Client", "Clients"];
export const columns = [
  { title: "Image", dataIndex: "Image", key: "Image" },
  {
    title: "Purchase Price",
    dataIndex: "Purchase Price",
    key: "Purchase Price",
  },
  {
    title: "Welt Sku Code",
    dataIndex: "Welt Sku Code",
    key: "Welt Sku Code",
  },
  { title: "OMS SKU Code", dataIndex: "OMS SKU Code", key: "OMS SKU Code" },
  {
    title: "Myntra SKU Code",
    dataIndex: "Myntra SKU Code",
    key: "Myntra SKU Code",
  },
  { title: "Brand", dataIndex: "Brand", key: "Brand" },
  { title: "Parent SKU", dataIndex: "Parent SKU", key: "Parent SKU" },
  { title: "MRP", dataIndex: "MRP", key: "MRP" },
  {
    title: "BAU Sellin Price",
    dataIndex: "BAU Sellin Price",
    key: "BAU Sellin Price",
  },
  { title: "Category", dataIndex: "Category", key: "Category" },
  { title: "Sub Category", dataIndex: "Sub Category", key: "Sub Category" },
  { title: "Styles", dataIndex: "Styles", key: "Styles" },
  { title: "SKU Name", dataIndex: "SKU Name", key: "SKU Name" },
  { title: "Flipkart FSN", dataIndex: "Flipkart FSN", key: "Flipkart FSN" },
  { title: "Flpkart LID", dataIndex: "Flpkart LID", key: "Flpkart LID" },
  {
    title: "Myntra Style Id",
    dataIndex: "Myntra Style Id",
    key: "Myntra Style Id",
  },
  {
    title: "Myntra SKU Code.1",
    dataIndex: "Myntra SKU Code.1",
    key: "Myntra SKU Code.1",
  },
  {
    title: "Myntra Seller SKU Code",
    dataIndex: "Myntra Seller SKU Code",
    key: "Myntra Seller SKU Code",
  },
  {
    title: "Myntra SKU Id",
    dataIndex: "Myntra SKU Id",
    key: "Myntra SKU Id",
  },
  {
    title: "AJIO SKU Code",
    dataIndex: "AJIO SKU Code",
    key: "AJIO SKU Code",
  },
  {
    title: "AJIO Seller SKU Code",
    dataIndex: "AJIO Seller SKU Code",
    key: "AJIO Seller SKU Code",
  },
  {
    title: "Amazon Seller SKU Code",
    dataIndex: "Amazon Seller SKU Code",
    key: "Amazon Seller SKU Code",
  },
  {
    title: "Amazon SKU Code",
    dataIndex: "Amazon SKU Code",
    key: "Amazon SKU Code",
  },
];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/bulk`} element={<Bulk />} />
      <Route path={`/`} element={<List />} />
    </Routes>
  );
}
