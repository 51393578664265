import {
  Alert,
  Button,
  Collapse,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Store } from "../../store";
import service from "../../services/grn";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import settingsService from "../../services/settings";
import { BASE_PATH } from ".";

export default function EntityForm(props: any) {
  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [data, setData] = useState<any>();
  const [errors, setErrors] = useState<any>({});
  const [mappings, setMappings] = useState<any[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [loadingMappings, setLoadingMappings] = useState(false);
  const payload = Form.useWatch("payload", form);

  useEffect(() => {
    if (params.id) {
      setId(params.id);
      service.get(params.id).then((res) => {
        form.setFieldsValue({
          ...res,
          purchaseOrder: res.purchaseOrder._id,
          vendorChallanDate: dayjs(res.vendorChallanDate),
        });

        for (let product of res.skus) {
          console.log(product);
          form.setFieldsValue({
            ["payload"]: {
              ...form.getFieldValue("payload"),
              [product.productCode]: {
                purchasePrice: product.purchasePrice,
                ...product.quantities.reduce((acc, q) => {
                  acc[q.skuCode] = {
                    received: q.received,
                    rejected: q.rejected,
                  };
                  return acc;
                }, {}),
              },
            },
          });
        }

        // for (let q of sku.quantities) {
        //           console.log(q);
        //           form.setFieldsValue({
        //             ["payload"]: {
        //               ...form.getFieldValue("payload"),
        //               [sku.productCode]: {
        //                 ...form.getFieldValue("payload")?.[sku.productCode],
        //                 [q.skuCode]: {
        //                   received: q.received,
        //                   rejected: q.rejected,
        //                 },
        //                 purchasePrice: res.skus.find(
        //                   (s) => s.productCode === sku.productCode
        //                 )?.purchasePrice,
        //               },
        //             },
        //           });
        //         }
        // for (let sku of res.purchaseOrder.skus) {
        //   for (let q of sku.quantities) {
        //     console.log(q);
        //     form.setFieldsValue({
        //       ["payload"]: {
        //         ...form.getFieldValue("payload"),
        //         [sku.productCode]: {
        //           ...form.getFieldValue("payload")?.[sku.productCode],
        //           [q.skuCode]: {
        //             received: q.received,
        //             rejected: q.rejected,
        //           },
        //           purchasePrice: res.skus.find(
        //             (s) => s.productCode === sku.productCode
        //           )?.purchasePrice,
        //         },
        //       },
        //     });
        //   }
        // }
        setLoadingMappings(true);
        settingsService
          .getMappingForSkuCodes(
            res.purchaseOrder.skus
              .map((sku) => sku.quantities)
              .flat()
              .map((q) => q.skuCode)
          )
          .then((mappings) => {
            setMappings(mappings);
          })
          .finally(() => setLoadingMappings(false));
        setData(res);
      });
    }
  }, [params.id]);

  const handleSubmit = async (values: any) => {
    console.log(values);
    const skus: any[] = [];
    for (let productCode in values.payload) {
      const payload = {
        productCode,
        quantities: [],
        purchasePrice: values.payload[productCode].purchasePrice,
      };
      for (let skuCode in values.payload[productCode]) {
        if (
          values.payload[productCode][skuCode] &&
          values.payload[productCode][skuCode].received === 0 &&
          values.payload[productCode][skuCode].rejected === 0
        ) {
          delete values.payload[productCode][skuCode];
        }
      }
      payload.quantities = Object.keys(values.payload[productCode])
        .filter((skuCode) => skuCode !== "purchasePrice")
        .map((skuCode) => {
          const tokens = skuCode.split("_");
          return {
            skuCode,
            size: tokens[tokens.length - 1],
            received: values.payload[productCode][skuCode].received,
            rejected: values.payload[productCode][skuCode].rejected,
          };
        });
      skus.push(payload);
    }
    values.skus = skus;
    try {
      setSubmitLoading(true);
      await service.update({ ...values, _id: id });
      message.success("GRN updated successfully");
      navigate(BASE_PATH);
    } catch (error) {
      message.error("Failed to update GRN");
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div>
      <Title level={3}>{id ? "Edit" : "Add"} Good Receive Note</Title>
      <Form
        form={form}
        className="mt-8"
        onValuesChange={(changedValues, allValues) => {
          console.log(allValues);
        }}
        onFinish={handleSubmit}
      >
        <Form.Item name="id" label="ID">
          {data?.id}
        </Form.Item>
        <Form.Item name="vendorChallanID" label="Vendor Challan ID">
          {data?.vendorChallanID}
        </Form.Item>
        <Form.Item name="vendorChallanDate" label="Vendor Challan Date">
          {dayjs(data?.vendorChallanDate).format("DD/MM/YY hh:mm A")}
        </Form.Item>
        <Form.Item name="purchaseOrder" label="Purchase Order">
          {data?.purchaseOrder.id}
        </Form.Item>
        <Form.Item label="Total Received">
          {Object.values(form.getFieldValue("payload") || {})
            .flat()
            .map((value) => Object.values(value))
            .flat()
            .filter((v) => !!v)
            .filter((v) => v.received)
            .reduce((acc, val) => acc + val.received, 0)}
        </Form.Item>
        <Collapse size="small">
          {data?.purchaseOrder.skus.map((sku, index) => {
            const defaultPurchasePrice = mappings.find((m) =>
              m.omsSkuCode.includes(sku.productCode)
            )?.purchasePrice;

            const parentErrors = errors.errorFields?.filter(
              (ef) => ef.name[1] === 0
            );

            const error = parentErrors?.filter(
              (pe) => pe.name[3] === sku.productCode
            );

            if (error?.length > 0) {
              console.log(sku.productCode, error);
            }
            const sizeColumns = Array.from(
              new Set(sku.quantities.map((q) => q.size))
            );

            return (
              <Collapse.Panel
                header={
                  <div className="flex gap-x-2">
                    <div className={error?.length > 0 ? "text-red-500" : ""}>
                      {sku.productCode}
                    </div>
                    <div className="flex gap-x-2">
                      {sizeColumns.map((size: string) => {
                        const quantity = sku.quantities.find(
                          (q) => q.size === size
                        );

                        return (
                          <Tooltip
                            title={`${quantity?.received} received & ${quantity?.rejected} rejected`}
                          >
                            <Tag>
                              <span className="font-medium pr-2">
                                {size}:{"  "}
                              </span>
                              <Space>
                                <span className="text-green-600">
                                  {form.getFieldValue([
                                    "payload",
                                    sku.productCode,
                                    quantity.skuCode,
                                    "received",
                                  ]) || 0}
                                </span>
                                <span className="text-gray-300">/</span>
                                <span className="text-red-700">
                                  {form.getFieldValue([
                                    "payload",
                                    sku.productCode,
                                    quantity.skuCode,
                                    "rejected",
                                  ]) || 0}
                                </span>
                              </Space>
                            </Tag>
                          </Tooltip>
                        );
                      })}
                      <span>
                        <span className="font-medium pr-2">Total:</span>
                        <span>
                          {sku.quantities.reduce(
                            (acc, q) =>
                              acc +
                              (form.getFieldValue([
                                "payload",
                                sku.productCode,
                                q.skuCode,
                                "received",
                              ]) || 0),
                            0
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                }
                forceRender
                key={index}
              >
                <div className="flex gap-x-2 items-start">
                  <Form.Item
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.purchaseOrder?.[sku.productCode]?.received !==
                        currentValues.purchaseOrders?.[sku.productCode]
                          ?.received ||
                      prevValues.purchaseOrder?.[sku.productCode]?.rejected !==
                        currentValues.purchaseOrder?.[sku.productCode]?.rejected
                    }
                    noStyle
                  >
                    {({ getFieldValue }) => {
                      const productCodePayload = getFieldValue([
                        "purchaseOrder",
                        sku.productCode,
                      ]);

                      let received = 0,
                        rejected = 0;

                      if (productCodePayload) {
                        for (let size in productCodePayload) {
                          if (isNaN(+size)) {
                            continue;
                          }
                          received += productCodePayload[size].received;
                          rejected += productCodePayload[size].rejected;
                        }
                      }
                      return (
                        <Form.Item
                          name={["payload", sku.productCode, "purchasePrice"]}
                          label="Purchase Price"
                          rules={[
                            {
                              validator: (_, value) => {
                                if (
                                  (received || rejected) &&
                                  (!value || value <= 0)
                                ) {
                                  return Promise.reject(
                                    new Error(
                                      "Purchase Price is required and should be non-zero"
                                    )
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder="Purchase Price"
                            prefix="₹"
                            style={{ width: "300px" }}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  <Spin spinning={loadingMappings}>
                    {defaultPurchasePrice ? (
                      <Alert
                        style={{ padding: "4px 12px" }}
                        type="info"
                        showIcon
                        message={`Default purchase price: ₹${defaultPurchasePrice}`}
                      />
                    ) : (
                      <Alert
                        style={{ padding: "4px 12px" }}
                        type="warning"
                        showIcon
                        message={`Default purchase price: None`}
                      />
                    )}
                  </Spin>
                </div>
                <Table
                  pagination={false}
                  size="small"
                  bordered
                  dataSource={
                    sku.quantities.filter((q) => q.ordered > 0) as any[]
                  }
                  columns={[
                    {
                      title: "SKU",
                      dataIndex: "skuCode",
                      key: "skuCode",
                    },
                    {
                      title: "Ordered",
                      dataIndex: "ordered",
                      key: "ordered",
                    },
                    {
                      title: "Pending",
                      dataIndex: "pending",
                      key: "pending",
                      render: (pending, record) => {
                        const received =
                          sku.quantities.find((q) => q.size === record.size)
                            ?.received || 0;
                        return record.pending + received;
                      },
                    },
                    {
                      title: "Received",
                      key: "received",
                      render: (text, record, index) => {
                        return (
                          <Form.Item
                            noStyle
                            rules={[
                              {
                                validator: (_, value) => {
                                  const rejected = form.getFieldValue([
                                    "payload",
                                    sku.productCode,
                                    record.skuCode,
                                    "rejected",
                                  ]);

                                  const pending =
                                    sku.quantities.find(
                                      (q) => q.size === record.size
                                    )?.pending || 0;

                                  const received =
                                    sku.quantities.find(
                                      (q) => q.size === record.size
                                    )?.received || 0;

                                  if (
                                    (value || 0) + (rejected || 0) >
                                    pending + received
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        `Total received and rejected quantity cannot exceed pending quantity of ${pending}`
                                      )
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                            name={[
                              "payload",
                              sku.productCode,
                              record.skuCode,
                              "received",
                            ]}
                          >
                            <InputNumber placeholder="Received" />
                          </Form.Item>
                        );
                      },
                    },
                    {
                      title: "Rejected",
                      key: "rejected",
                      render: (text, record, index) => {
                        return (
                          <Form.Item
                            noStyle
                            rules={[
                              {
                                validator: (_, value) => {
                                  const received = form.getFieldValue([
                                    "payload",
                                    sku.productCode,
                                    record.skuCode,
                                    "received",
                                  ]);

                                  const pending =
                                    sku.quantities.find(
                                      (q) => q.size === record.size
                                    )?.pending || 0;

                                  const receivedQty =
                                    sku.quantities.find(
                                      (q) => q.size === record.size
                                    )?.received || 0;

                                  if (
                                    (value || 0) + (received || 0) >
                                    pending + receivedQty
                                  ) {
                                    return Promise.reject(
                                      new Error(
                                        `Total received and rejected quantity cannot exceed pending quantity of ${pending}`
                                      )
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                            name={[
                              "payload",
                              sku.productCode,
                              record.skuCode,
                              "rejected",
                            ]}
                          >
                            <InputNumber placeholder="Rejected" />
                          </Form.Item>
                        );
                      },
                    },
                  ]}
                />
              </Collapse.Panel>
            );
          })}
        </Collapse>
        <div className="mt-4">
          <Form.Item noStyle>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
