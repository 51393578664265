import { Routes, Route } from "react-router-dom";
import List from "./List";
import ItemList from "./ItemList";
// import View from "./View";
export const MODEL = "sku";
export const BASE_PATH = "/skus";
export const TITLE = ["Invoice", "Invoices"];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/items`} element={<ItemList />} />
      <Route path={`/`} element={<List />} />
    </Routes>
  );
}
