import {
  Button,
  Col,
  Descriptions,
  message,
  Modal,
  Row,
  Spin,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import service from "../../services/payment";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Title } = Typography;

export function Info(props: any) {
  return (
    <Descriptions className="mt-2">
      <Descriptions.Item label={props.data.vendor ? "Vendor" : "Supplier"}>
        {props.data.vendor?.name || props.data.supplier?.name || ""}
      </Descriptions.Item>
      <Descriptions.Item label="Amount">{props.data.amount}</Descriptions.Item>
      <Descriptions.Item label="Mode">{props.data.mode}</Descriptions.Item>
      <Descriptions.Item label="Reference">
        {props.data.reference}
      </Descriptions.Item>
      <Descriptions.Item label="Date">
        {dayjs(props.data.date).format("DD/MM/YY hh:mm A")}
      </Descriptions.Item>
    </Descriptions>
  );
}

export default function PaymentView(props: any) {
  const params = useParams();
  const [showVoidModal, setShowVoidModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (params.id) {
      handleLoad(params.id);
    }
  }, [params]);
  const handleLoad = (id: string) => {
    service.get(id).then((p) => {
      setData(p);
      setLoading(false);
    });
  };
  const voidEntity = () => {
    if (!data) return;
    service.voidPayment(data._id).then(() => {
      message.success("Payment voided successfully");
      handleLoad(data._id);
    });
  };

  return (
    <Spin spinning={loading}>
      {data && (
        <div>
          <Modal
            title="Void PO"
            visible={showVoidModal}
            onOk={() => {
              setShowVoidModal(false);
              voidEntity();
            }}
            onCancel={() => setShowVoidModal(false)}
          >
            <p>Are you sure you want to void this payment?</p>
          </Modal>
          <Row justify="space-between">
            <Col>
              <Title level={3}>
                {data.id} {!data.isActive ? <Tag>Void</Tag> : undefined}
              </Title>
            </Col>
            <Col></Col>
            <Col>
              {" "}
              <div className="flex gap-x-2 justify-end mt-2">
                <Button
                  type="link"
                  icon={<CloseCircleOutlined />}
                  onClick={() => setShowVoidModal(true)}
                >
                  Void Payment
                </Button>
              </div>
            </Col>
          </Row>
          <div className="bg-white rounded-lg p-2">
            <Info data={data} />
          </div>
        </div>
      )}
    </Spin>
  );
}
