import _ from "lodash";

export const PAGE_SETUP = {
  pageSize: {
    width: 100,
    height: 25,
  },
  pageMargins: [0, 3, 0, 0],
  pageOrientation: "landscape",
};
export const getDD = function (payload) {
  const items = payload;
  const content: any[] = [];

  for (let item of items) {
    const itemContent = {
      width: "50%",
      margin: [2.5, 0],
      stack: [
        {
          columns: [
            {
              width: "45%",
              text: [
                { text: "Size" },
                {
                  text: `${item.size}/${item.euSize}`,
                  fontSize: 5,
                },
              ],
              fontSize: 3,
              bold: true,
            },

            {
              width: "55%",
              text: item.color,
              alignment: "right",
              fontSize: 3,
              bold: true,
            },
          ],
        },
        {
          columns: [
            {
              width: "*",
              text: "",
            },
            {
              width: "auto",
              text: item.barcodeValue,
              fontSize: 3,
              bold: true,
            },
            {
              width: "*",
              text: "",
            },
          ],
        },
        {
          image: item.barcodeImage,
          width: 45,
          height: 8,
        },

        {
          margin: [0, 1, 0, 0],
          columns: [
            {
              width: "50%",
              text: `Qty : ${item.quantity * 2} N (${item.quantity} Pair)`,
              fontSize: 3,
              bold: true,
              alignment: "left",
            },

            {
              width: "50%",
              text: item.vendor,
              alignment: "right",
              fontSize: 3,
              bold: true,
            },
          ],
        },
      ],
    };
    content.push(itemContent);
  }

  const contentAsGrid = [];

  for (let i = 0; i < Math.ceil(content.length / 2); i++) {
    const itemContent = {
      columns: [content[i], content[i + 1]],
    };
    contentAsGrid.push(itemContent);
  }

  const pageSetup = PAGE_SETUP;
  const documentDefinition = {
    ...pageSetup,
    content: [...contentAsGrid],
  };

  return _.cloneDeep(documentDefinition);
};
