import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import service from "../../services/rwpo";
import {
  Button,
  Col,
  Descriptions,
  Dropdown,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Tag,
} from "antd";
import Title from "antd/es/typography/Title";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import * as XLSX from "xlsx";
import {
  CloseCircleOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  EditOutlined,
} from "@ant-design/icons";
import usePrintQueue from "../../hooks/usePrintQueue";
import { BASE_PATH } from ".";
import RWPurchaseOrderPDFModal from "../../components/RWPurchaseOrderPDFModal";
import ConfirmationModal from "../../components/ConfirmationModal"; // Add this import
import usePermission from "../../hooks/usePermission";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function Info(props: any) {
  return (
    <Descriptions className="mt-2">
      <Descriptions.Item label="Supplier">
        {props.data.supplier?.name}
      </Descriptions.Item>
      <Descriptions.Item label="Vendor">
        {props.data.vendor?.name}
      </Descriptions.Item>
      <Descriptions.Item label="Total SKUs">
        {props.data.totalSKUs}
      </Descriptions.Item>
      <Descriptions.Item label="Purchase Orders">
        <Dropdown
          overlay={
            <Space direction="vertical">
              {props.data.po?.map((p) => (
                <a href={`/po/${p.id}`} key={p.id}>
                  {p.id}
                </a>
              ))}
            </Space>
          }
        >
          <div>{props.data.po?.length} POs</div>
        </Dropdown>
      </Descriptions.Item>
      <Descriptions.Item label="Status">
        {props.data.status === "Pending Approval" ? (
          <Tag color="orange">Pending Approval</Tag>
        ) : props.data.status === "Open" ? (
          <Tag color="cyan">Open</Tag>
        ) : props.data.status === "Closed" ? (
          <Tag color="green">Closed</Tag>
        ) : (
          <Tag>Cancelled</Tag>
        )}
      </Descriptions.Item>
    </Descriptions>
  );
}

export function SKUTable(props: any) {
  const [grns, setGRNs] = useState<any[]>();

  return (
    <>
      <Title level={4} className="p-2">
        Products
      </Title>
      <Table
        size="small"
        dataSource={props.data.rawMaterials as any[]}
        columns={[
          {
            title: "Raw Material",
            dataIndex: "rawMaterial",
            key: "rawMaterial",
            render: (rawMaterial, record) => (
              <div className="flex flex-col ">
                <span>{rawMaterial?.name}</span>
                <span>{record.description}</span>
              </div>
            ),
          },
          {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render: (quantity, record) =>
              quantity + " " + record.rawMaterial.unit,
          },
          {
            title: "Vendor Price",
            dataIndex: "price",
            key: "price",
            render: (quantity, record) =>
              quantity + " / " + record.rawMaterial.unit,
          },
          {
            title: "Supplier Price",
            dataIndex: "supplierPrice",
            key: "supplierPrice",
            render: (quantity, record) =>
              quantity + " / " + record.rawMaterial.unit,
          },
        ]}
      />
    </>
  );
}

export default function View(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const [printQueue, isAvailable] = usePrintQueue();
  const permissions = usePermission({ module: "raw-material-purchase-orders" });

  const [printLoading, setPrintLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [showVoidModal, setShowVoidModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [approvingRecord, setApprovingRecord] = useState<any>(); // New state for approval
  useEffect(() => {
    if (params.id) {
      handleLoad(params.id);
    }
  }, [params]);

  const handleLoad = (id: string) => {
    service.get(id).then((p) => {
      setData(p);
      setLoading(false);
    });
  };
  const generateExcelFile = () => {
    if (!data) return null;

    const wsData = [
      ["STYLE CODE", "Size", "SKU ID", "Colour code", "Qty", "Vendor Code"],
    ];
    for (let sku of data.skus) {
      const { productCode, quantities } = sku;

      for (let quantity of quantities) {
        wsData.push([
          productCode,
          quantity.size,
          quantity.skuCode,
          "",
          quantity.ordered,
          data.vendor.code,
        ]);
      }
    }

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    return new Blob([wbout], { type: "application/octet-stream" });
  };

  const voidPO = () => {
    if (!data) return;
    service.voidPO(data._id).then(() => {
      message.success("PO voided successfully");
      handleLoad(data._id);
    });
  };

  const closePO = () => {
    if (!data) return;
    service.update({ ...data, status: "Closed" }).then(() => {
      message.success("PO closed successfully");
      handleLoad(data._id);
    });
  };

  return (
    <Spin spinning={loading}>
      {data && (
        <div>
          <Modal
            title="Void PO"
            visible={showVoidModal}
            onOk={() => {
              setShowVoidModal(false);
              voidPO();
            }}
            onCancel={() => setShowVoidModal(false)}
          >
            <p>Are you sure you want to void this PO?</p>
          </Modal>
          <Modal
            title="Close PO"
            visible={showCloseModal}
            onOk={() => {
              setShowCloseModal(false);
              closePO();
            }}
            onCancel={() => setShowCloseModal(false)}
          />
          <ConfirmationModal
            title="Approve Purchase Order"
            visible={!!approvingRecord}
            message="Are you sure you want to approve this purchase order?"
            onConfirm={() => {
              if (approvingRecord) {
                service
                  .approve(approvingRecord._id)
                  .then(() => {
                    message.success("PO approved successfully");
                    handleLoad(approvingRecord._id);
                    setApprovingRecord(undefined);
                  })
                  .catch(() => {
                    message.error("Failed to approve PO");
                  });
              }
            }}
            onReject={() => {
              if (approvingRecord) {
                service
                  .update({ ...approvingRecord, status: "Cancelled" })
                  .then(() => {
                    message.success("PO rejected successfully");
                    handleLoad(approvingRecord._id);
                    setApprovingRecord(undefined);
                  })
                  .catch(() => {
                    message.error("Failed to rejected PO");
                  });
              }
            }}
            onCancel={() => setApprovingRecord(undefined)}
            confirmText="Approve"
            cancelText="Reject"
            confirmButtonType="primary"
          />
          <Row justify="space-between">
            <Col>
              <Title level={3}>{data.id}</Title>
            </Col>
            <Col></Col>
            <Col>
              <RWPurchaseOrderPDFModal
                id={data._id}
                permissions={permissions}
              />
            </Col>
          </Row>
          <div className="bg-white rounded-lg p-2">
            <Info data={data} />
          </div>
          {["Open", "In Progress", "Pending Approval"].includes(
            data.status
          ) && (
            <div className="flex gap-x-2 justify-end mt-2">
              <Button
                type="link"
                icon={<CloseCircleOutlined />}
                onClick={() => setShowVoidModal(true)}
              >
                Void PO
              </Button>
              <Button
                type="link"
                icon={<CheckCircleOutlined />}
                onClick={() => setShowCloseModal(true)}
              >
                Close PO
              </Button>
              {data.status === "Pending Approval" && permissions.approve && (
                <Button
                  type="link"
                  icon={<CheckOutlined />}
                  onClick={() => setApprovingRecord(data)}
                >
                  Approve PO
                </Button>
              )}
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => navigate(`${BASE_PATH}/form/${data._id}`)}
              >
                Edit PO
              </Button>
            </div>
          )}
          <div className="mt-2 bg-white rounded-lg">
            <SKUTable data={data} isAvailable={isAvailable} />
          </div>
        </div>
      )}
    </Spin>
  );
}
