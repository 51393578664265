import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/ledger";

export const getEntries = (data: any) =>
  postRequest(`${SERVICE_URL}/entries`, data);

export default {
  getEntries,
};
