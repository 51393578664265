import {
  Alert,
  Button,
  Descriptions,
  Divider,
  message,
  Spin,
  Table,
  Tag,
  Timeline,
} from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import service from "../../services/order";
import { useParams } from "react-router-dom";
import io, { Socket } from "socket.io-client";
import { SOCKET_URL } from "../../services/config/apiUrl";
import { debounce, throttle } from "throttle-debounce";

import {
  ArrowRightOutlined,
  DownloadOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import * as XLSX from "xlsx";

const socket = io(SOCKET_URL);

export default function ViewSJITJob(props: any) {
  const [payload, setPayload] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();
  const [job, setJob] = useState<any>();

  useEffect(() => {
    socket.on("REFRESH_SJIT_ORDER_JOB", () => {
      console.log("Received REFRESH_SJIT_ORDER_JOB event");
      getJobDebounced();
      getJobRowsDebounced();
    });
  }, []);

  const getJob = async () => {
    try {
      const job = await service.getJob(params.id);
      setJob(job);
    } catch (error) {
      console.error(error);
    }
  };

  const getJobDebounced = useCallback(throttle(300, getJob), []);

  const getJobRows = async () => {
    try {
      const rows = await service.getJobRows(params.id);
      setPayload(rows);
    } catch (error) {
      console.error(error);
    }
  };

  const getJobRowsDebounced = useCallback(throttle(20000, getJobRows), []);

  useEffect(() => {
    setLoading(true);
    Promise.all(
      [service.getJob(params.id), service.getJobRows(params.id)].map((p) =>
        p.catch((e) => {
          message.error("Failed to fetch data");
          console.error(e);
        })
      )
    )
      .then(([job, payload]) => {
        setJob(job);
        setPayload(payload);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.id]);

  const handleRefresh = async () => {
    setLoading(true);
    Promise.all(
      [service.getJob(params.id), service.getJobRows(params.id)].map((p) =>
        p.catch((e) => {
          message.error("Failed to fetch data");
          console.error(e);
        })
      )
    )
      .then(([job, payload]) => {
        setJob(job);
        setPayload(payload);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: any[] = [
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (text: string) => {
        if (text === "OK") {
          return <Tag color="green">OK</Tag>;
        } else if (text === "Sku not found") {
          return <Tag color="red">SKU Not Found</Tag>;
        } else if (text === "Mapping not found") {
          return <Tag color="red">Mapping Not Found</Tag>;
        } else if (text === "Warehouse not found") {
          return <Tag color="red">Warehouse Not Found</Tag>;
        }
      },
      filters: [
        {
          text: `OK (${payload?.filter((r) => r.status === "OK").length})`,
          value: "OK",
        },
        {
          text: `Sku not found (${
            payload?.filter((r) => r.status === "Sku not found").length
          })`,
          value: "Sku not found",
        },
        {
          text: `Mapping not found (${
            payload?.filter((r) => r.status === "Mapping not found").length
          })`,
          value: "Mapping not found",
        },
      ],
      onFilter: (value: string, record: any) =>
        record.status.indexOf(value) === 0,
    },
    {
      key: "orderDate",
      title: "Order Date",
      dataIndex: "orderDate",
      render: (text: string) => {
        return dayjs(text).format("DD/MM/YYYY");
      },
    },
    {
      key: "sku",
      title: "SKU",
      dataIndex: "skuCode",
      render: (text: string, record: any) => {
        return (
          <span>
            {text} <ArrowRightOutlined /> {record.omsSkuCode}{" "}
          </span>
        );
      },
    },
    {
      key: "quantity",
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      key: "sellingPrice",
      title: "Selling Price",
      dataIndex: "sellingPrice",
    },
    {
      key: "orderAmount",
      title: "Order Amount",
      dataIndex: "orderAmount",
    },
    {
      key: "taxRate",
      title: "Tax Rate",
      dataIndex: "taxRate",
    },
    {
      key: "taxAmount",
      title: "Tax Amount",
      dataIndex: "taxAmount",
    },
  ];

  const handleDownloadErroredRows = async () => {
    try {
      const nonOkOrderIds = payload
        .filter((r) => r.status !== "OK")
        .map((r) => r.orderId);

      const ws = XLSX.utils.json_to_sheet(
        payload
          .filter((r) => nonOkOrderIds.includes(r.orderId))
          .map((r) => ({
            Date: r.orderDate,
            "Order ID": r.orderId,
            "SKU Code": r.skuCode,
            Quantity: r.quantity,
            "Selling Price": r.sellingPrice,
            "Order Amount": r.orderAmount,
            "Tax Rate": r.taxRate,
            "Tax Amount": r.taxAmount,
            Status: r.status,
          }))
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Errored Rows");
      XLSX.writeFile(wb, "errored_rows.xlsx");
    } catch (error) {
      throw error;
    }
  };

  const handleApprove = async () => {
    try {
      await service.approveJob(params.id);
      message.success("Job approved successfully");
    } catch (error) {
      message.error("Failed to approve job");
      console.log(error);
    }
  };

  const handleCancel = async () => {
    try {
      await service.cancelJob(params.id);
      message.success("Job cancelled successfully");
      setTimeout(() => {
        getJobDebounced();
        getJobRowsDebounced();
      }, 1000);
    } catch (error) {
      message.error("Failed to cancel job");
      console.log(error);
    }
  };

  return (
    <Spin spinning={loading}>
      {job && (
        <div className="">
          <div>
            <div className="flex justify-between items-center">
              <h1 className="text-2xl font-semibold">
                SJIT Order Job {job._id}{" "}
                <Button
                  type="link"
                  icon={<SyncOutlined />}
                  onClick={handleRefresh}
                ></Button>
              </h1>
              {job.status === "VALIDATED" && (
                <Button
                  type="primary"
                  onClick={() => handleApprove()}
                  loading={loading}
                  disabled={job.status !== "VALIDATED"}
                >
                  Approve
                </Button>
              )}
              {job.status.includes("PROCESSING") && (
                <Button
                  type="primary"
                  onClick={() => handleCancel()}
                  loading={loading}
                >
                  Cancel
                </Button>
              )}
            </div>
            <Divider className="my-2" />
          </div>
          <div className="bg-white rounded my-4 px-2 pb-1 pt-3">
            <Descriptions bordered={false}>
              <Descriptions.Item label="Status">{job.status}</Descriptions.Item>
              <Descriptions.Item label="Created At">
                {dayjs(job.createdAt).format("YYYY-MM-DD HH:mm:ss A")}
              </Descriptions.Item>
              <Descriptions.Item label="Updated At">
                {dayjs(job.updatedAt).format("YYYY-MM-DD HH:mm:ss A")}
              </Descriptions.Item>
              <Descriptions.Item label="Pending Rows">
                {payload.length}
              </Descriptions.Item>
              <Descriptions.Item label="Processed Rows">
                {job.rowCount - payload.length}
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div className="bg-white px-4 pt-4 rounded mb-4">
            <Timeline
              mode="left"
              items={job.timeline.map((t) => ({
                label: dayjs(t.timestamp).format("YYYY-MM-DD HH:mm:ss A"),
                children: t.status,
              }))}
            />
          </div>
          <div>
            <Alert
              type="info"
              message={
                <div className="flex gap-x-2">
                  <div>
                    <span className="font-semibold">Total Orders:</span>{" "}
                    {payload.length.toLocaleString()}
                  </div>
                  <div>
                    <span className="font-semibold">Success:</span>{" "}
                    {payload
                      .filter((r) => r.status === "OK")
                      .length.toLocaleString()}
                  </div>
                  <div>
                    <span className="font-semibold">SKU Not Found:</span>{" "}
                    {payload
                      .filter((r) => r.status === "Sku not found")
                      .length.toLocaleString()}
                  </div>
                  <div>
                    <span className="font-semibold">Mapping Not Found:</span>{" "}
                    {payload
                      .filter((r) => r.status === "Mapping not found")
                      .length.toLocaleString()}
                  </div>
                </div>
              }
            />
          </div>
          <Table
            className=""
            pagination={{ defaultPageSize: 20 }}
            dataSource={payload || []}
            size="small"
            columns={columns}
          />
          <div></div>

          <Divider className="my-2" />
          <div>
            <Alert
              type="warning"
              message={
                <div>
                  Download errored rows:{" "}
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={() => handleDownloadErroredRows()}
                  >
                    <DownloadOutlined className="mr-1" />
                    Download
                  </span>
                </div>
              }
            />
          </div>
          <Divider className="my-2" />
        </div>
      )}
    </Spin>
  );
}
