import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from "../services/report";
const TYPE = "REPORT";

interface ProfiledProductsState {
  data: any[];
  loading: boolean;
  error: string;
}

interface ProfiledProductsStateByChannel {
  data: any[];
  loading: boolean;
  error: string;
}
interface ProfiledProductsStateByBrand {
  data: any[];
  loading: boolean;
  error: string;
}

export interface ReportState {
  profiledProducts: ProfiledProductsState;
  profiledProductsByChannel: ProfiledProductsStateByChannel;
  profiledProductsByBrand: ProfiledProductsStateByBrand;
}

const initialState: ReportState = {
  profiledProducts: {
    data: [],
    loading: false,
    error: "",
  },
  profiledProductsByChannel: {
    data: [],
    loading: false,
    error: "",
  },
  profiledProductsByBrand: {
    data: [],
    loading: false,
    error: "",
  },
};

export const getProfiledProductsAsync = createAsyncThunk(
  `${TYPE}/GET_PROFILED_PRODUCTS`,
  async (warehouse: string, thunkAPI) => {
    try {
      const response = await service.getProfiledProducts(warehouse);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProfiledProductsByChannelAsync = createAsyncThunk(
  `${TYPE}/GET_PROFILED_PRODUCTS_BY_CHANNEL`,
  async (
    { warehouse, channel }: { warehouse: string; channel: string },
    thunkAPI
  ) => {
    try {
      const response = await service.getProfiledProductsByChannel(
        warehouse,
        channel
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getProfiledProductsByBrandAsync = createAsyncThunk(
  `${TYPE}/GET_PROFILED_PRODUCTS_BY_BRAND`,
  async (
    { warehouse, brand }: { warehouse: string; brand: string },
    thunkAPI
  ) => {
    try {
      const response = await service.getProfiledProductsByBrand(
        warehouse,
        brand
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const settingSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfiledProductsAsync.pending, (state) => {
        state.profiledProducts.loading = true;
      })
      .addCase(getProfiledProductsAsync.fulfilled, (state, action) => {
        state.profiledProducts.loading = false;
        state.profiledProducts.data = action.payload;
      })
      .addCase(getProfiledProductsAsync.rejected, (state, action) => {
        state.profiledProducts.loading = false;
        state.profiledProducts.error = action.payload as string;
      })
      .addCase(getProfiledProductsByChannelAsync.pending, (state) => {
        state.profiledProductsByChannel.loading = true;
      })
      .addCase(getProfiledProductsByChannelAsync.fulfilled, (state, action) => {
        state.profiledProductsByChannel.loading = false;
        state.profiledProductsByChannel.data = action.payload;
      })
      .addCase(getProfiledProductsByChannelAsync.rejected, (state, action) => {
        state.profiledProductsByChannel.loading = false;
        state.profiledProductsByChannel.error = action.payload as string;
      })
      .addCase(getProfiledProductsByBrandAsync.pending, (state) => {
        state.profiledProductsByBrand.loading = true;
      })
      .addCase(getProfiledProductsByBrandAsync.fulfilled, (state, action) => {
        state.profiledProductsByBrand.loading = false;
        state.profiledProductsByBrand.data = action.payload;
      })
      .addCase(getProfiledProductsByBrandAsync.rejected, (state, action) => {
        state.profiledProductsByBrand.loading = false;
        state.profiledProductsByBrand.error = action.payload as string;
      });
  },
});

export default settingSlice.reducer;
