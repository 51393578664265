import { useEffect, useState, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Select,
  Table,
  Space,
  Popover,
  List,
  Checkbox,
  Form,
  Modal,
  Tag,
  Switch,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  ExportOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import { debounce } from "throttle-debounce";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TITLE, BASE_PATH } from ".";
import service from "../../services/order";
import warehouseService from "../../services/warehouse";
import settingsService from "../../services/settings";
import useSearchParamsTableState from "../../hooks/useSearchParamsTableState";
import { useSelector } from "react-redux";
import { Store } from "../../store";
import OrderItemsModal from "../../components/OrderItemsModal";
import statesOfIndia from "../../utils/statesOfIndia";

const { Text, Title } = Typography;

export default function Entities(props: any) {
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({ onFetch: service.paginateJobs });
  const navigate = useNavigate();
  const clientState = useSelector((state: Store) => state.client);
  const columns: any[] = [
    {
      title: "ID",
      dataIndex: "_id",
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      render: (value: string) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Actions",
      render: (record: any) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => navigate(`${BASE_PATH}/sjit-jobs/${record._id}`)}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center">
        <Title level={3}>SJIT Jobs</Title>
        <Button type="link" icon={<SyncOutlined />} onClick={() => onRefresh()}>
          Refresh
        </Button>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}></Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={data?.list}
            columns={columns}
            pagination={{
              ...(tableState?.pagination || {}),
              total: data?.queryCount,
            }}
            bordered
            className="overflow-table"
            onChange={(pagination, filters, sorter) => {
              delete (sorter as any).column;
              setTableState({ pagination, filters, sorter });
            }}
            rowKey={(record) => record._id}
            size="small"
          />
        </Col>
      </Row>
    </>
  );
}
