import {
  Breadcrumb,
  Button,
  Checkbox,
  Form,
  Input,
  Space,
  Collapse,
  Typography,
} from "antd";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_PATH, TITLE } from ".";
import service from "../../../services/role";
import { titleCase } from "title-case";

const { Title } = Typography;
const { Panel } = Collapse;

// Original modulePermissions array
const modulePermissions = [
  {
    module: "dashboard",
    title: "Dashboard",
    functions: [{ text: "view", value: false }],
  },
  {
    module: "purchase-orders",
    title: "Purchases -> Purchase Orders",
    functions: [
      { text: "view", value: false },
      { text: "list", value: false },
      { text: "edit", value: false },
      { text: "generate", value: false },
      { text: "create", value: false },
      { text: "upload", value: false },
      { text: "manual-upload", value: false },
    ],
  },
  {
    module: "goods-receive-notes",
    title: "Purchases -> Goods Receive Notes",
    functions: [
      { text: "create", value: false },
      { text: "view", value: false },
      { text: "list", value: false },
      { text: "edit", value: false },
    ],
  },
  {
    module: "raw-material-purchase-orders",
    title: "Raw Material Purchase Orders",
    functions: [
      { text: "approve", value: false },
      { text: "view", value: false },
      { text: "list", value: false },
      { text: "edit", value: false },
      { text: "create", value: false },
    ],
  },
  {
    module: "orders",
    title: "Orders",
    functions: [
      { text: "create", value: false },
      { text: "list", value: false },
      { text: "list-items", value: false },
    ],
  },
  {
    module: "payments",
    title: "Finance -> Payments",
    functions: [
      { text: "create", value: false },
      { text: "list", value: false },
      { text: "edit", value: false },
    ],
  },
  {
    module: "vendor-credit-note",
    title: "Finance -> Credit Notes",
    functions: [
      { text: "create", value: false },
      { text: "list", value: false },
      { text: "edit", value: false },
    ],
  },
  {
    module: "vendor-debit-note",
    title: "Finance -> Debit Notes",
    functions: [
      { text: "create", value: false },
      { text: "list", value: false },
      { text: "edit", value: false },
    ],
  },
  {
    module: "journal",
    title: "Finance -> Journal",
    functions: [
      { text: "create", value: false },
      { text: "list", value: false },
      { text: "edit", value: false },
    ],
  },
  {
    module: "ledgers",
    title: "Finance -> Ledgers",
    functions: [{ text: "view", value: false }],
  },
  {
    module: "sku-report",
    title: "Reports -> SKU",
    functions: [{ text: "list", value: false }],
  },
  {
    module: "po-summary-report",
    title: "Reports -> PO Summary Report",
    functions: [{ text: "list", value: false }],
  },
  {
    module: "pending-po-report",
    title: "Reports -> Pending PO Report",
    functions: [{ text: "list", value: false }],
  },
  {
    module: "grn-summary-report",
    title: "Reports -> GRN Report",
    functions: [{ text: "list", value: false }],
  },
  {
    module: "inward",
    title: "Manual Inward",
    functions: [{ text: "list", value: false }],
  },
  {
    module: "returns",
    title: "Returns",
    functions: [{ text: "list", value: false }],
  },
  {
    module: "skus",
    title: "SKUs",
    functions: [
      { text: "upload", value: false },
      { text: "list", value: false },
    ],
  },
  {
    module: "vendors",
    title: "Vendors",
    functions: [
      { text: "create", value: false },
      { text: "list", value: false },
      { text: "edit", value: false },
      { text: "export", value: false },
    ],
  },
  {
    module: "suppliers",
    title: "Suppliers",
    functions: [
      { text: "create", value: false },
      { text: "list", value: false },
      { text: "edit", value: false },
      { text: "export", value: false },
    ],
  },
  {
    module: "api-key",
    title: "Settings -> API Key",
    functions: [{ text: "view", value: false }],
  },
  {
    module: "mapping",
    title: "Settings -> Mapping",
    functions: [{ text: "view", value: false }],
  },
  {
    module: "zone",
    title: "Settings -> Zone",
    functions: [{ text: "view", value: false }],
  },
  {
    module: "size-distribution",
    title: "Settings -> Size Distribution",
    functions: [{ text: "view", value: false }],
  },
  {
    module: "label-printer",
    title: "Settings -> Label Printer",
    functions: [{ text: "view", value: false }],
  },
  {
    module: "raw-material",
    title: "Settings -> Raw Material",
    functions: [{ text: "view", value: false }],
  },
  {
    module: "role",
    title: "Settings -> Role",
    functions: [{ text: "view", value: false }],
  },
  {
    module: "user",
    title: "Settings -> User",
    functions: [{ text: "view", value: false }],
  },
  {
    module: "cut-off-style-id",
    title: "Settings -> Cut Off Style ID",
    functions: [{ text: "view", value: false }],
  },
];

interface PermissionsFormProps {
  value?: any;
  onChange?: (value: any) => void;
}

const PermissionsForm: React.FC<PermissionsFormProps> = ({
  value = [],
  onChange,
}) => {
  const formContext = Form.useFormInstance(); // Add this line to get form instance

  // Function to group modules by their top-level category
  const groupModules = (modules: any[]) => {
    const grouped: { [key: string]: any[] } = {};

    modules.forEach((item) => {
      const [group, ...rest] = item.title.split("->").map((s) => s.trim());
      const key = group || "Other";
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(item);
    });

    return grouped;
  };

  const groupedModules = groupModules(modulePermissions);

  // Handle changes when individual functions are toggled
  const handleFunctionChange = (
    moduleName: string,
    selectedFunctions: string[]
  ) => {
    const updatedPermissions = [...value];
    const moduleIndex = updatedPermissions.findIndex(
      (p) => p.module === moduleName
    );

    if (moduleIndex === -1) {
      if (selectedFunctions.length > 0) {
        updatedPermissions.push({
          module: moduleName,
          values: selectedFunctions,
        });
      }
    } else {
      if (selectedFunctions.length > 0) {
        updatedPermissions[moduleIndex].values = selectedFunctions;
      } else {
        updatedPermissions.splice(moduleIndex, 1);
      }
    }

    // Update form field value directly
    formContext.setFieldValue("permissions", updatedPermissions);
    onChange && onChange(updatedPermissions);
  };

  // Handle "Select All" action for a module
  const handleSelectAllModule = (
    moduleName: string,
    allFunctions: string[]
  ) => {
    handleFunctionChange(moduleName, allFunctions);
  };

  // Handle "Deselect All" action for a module
  const handleDeselectAllModule = (moduleName: string) => {
    handleFunctionChange(moduleName, []);
  };

  // Handle "Select All" action for a group
  const handleSelectAllGroup = (group: string) => {
    let updatedPermissions = [...value];
    const modulesInGroup = groupedModules[group];

    modulesInGroup.forEach((mod) => {
      const allFunctionValues = mod.functions.map((f: any) => f.text);
      const moduleIndex = updatedPermissions.findIndex(
        (p) => p.module === mod.module
      );

      if (moduleIndex === -1) {
        updatedPermissions.push({
          module: mod.module,
          values: allFunctionValues,
        });
      } else {
        updatedPermissions[moduleIndex].values = allFunctionValues;
      }
    });

    formContext.setFieldValue("permissions", updatedPermissions);
    onChange && onChange(updatedPermissions);
  };

  // Handle "Deselect All" action for a group
  const handleDeselectAllGroup = (group: string) => {
    let updatedPermissions = [...value];
    const modulesInGroup = groupedModules[group];

    modulesInGroup.forEach((mod) => {
      const moduleIndex = updatedPermissions.findIndex(
        (p) => p.module === mod.module
      );
      if (moduleIndex !== -1) {
        updatedPermissions.splice(moduleIndex, 1);
      }
    });

    formContext.setFieldValue("permissions", updatedPermissions);
    onChange && onChange(updatedPermissions);
  };

  return (
    <Collapse accordion>
      {Object.keys(groupedModules).map((group) => {
        const modulesInGroup = groupedModules[group];

        // Determine if all functions in the group are selected
        const allFunctionsInGroup = modulesInGroup.flatMap((mod) =>
          mod.functions.map((f: any) => `${mod.module}:${f.text}`)
        );

        const selectedFunctionsInGroup = value
          .filter((p: any) =>
            modulesInGroup.some((mod) => mod.module === p.module)
          )
          .flatMap((p: any) => p.values.map((f: string) => `${p.module}:${f}`));

        const allSelectedGroup =
          selectedFunctionsInGroup.length === allFunctionsInGroup.length;
        const noneSelectedGroup = selectedFunctionsInGroup.length === 0;

        return (
          <Panel
            header={
              <Space direction="horizontal" size="middle">
                <span style={{ fontWeight: "bold" }}>{titleCase(group)}</span>
                <Space>
                  {!allSelectedGroup && (
                    <Button
                      size="small"
                      type="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectAllGroup(group);
                      }}
                    >
                      Select All
                    </Button>
                  )}
                  {!noneSelectedGroup && (
                    <Button
                      size="small"
                      type="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeselectAllGroup(group);
                      }}
                    >
                      Deselect All
                    </Button>
                  )}
                </Space>
              </Space>
            }
            key={group}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              {modulesInGroup.map((mod) => {
                const currentPermissions = value.find(
                  (p) => p.module === mod.module
                );
                const selectedFunctions = currentPermissions
                  ? currentPermissions.values
                  : [];

                const allFunctionValues = mod.functions.map((f: any) => f.text);

                const allSelected =
                  selectedFunctions.length === allFunctionValues.length;
                const noneSelected = selectedFunctions.length === 0;

                return (
                  <div
                    key={mod.module}
                    style={{
                      padding: "10px 0",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <Space
                      direction="horizontal"
                      size="small"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {titleCase(mod.title.split("->").pop() || mod.title)}
                      </span>
                      <Space>
                        {!allSelected && (
                          <Button
                            size="small"
                            type="link"
                            onClick={() =>
                              handleSelectAllModule(
                                mod.module,
                                allFunctionValues
                              )
                            }
                          >
                            Select All
                          </Button>
                        )}
                        {!noneSelected && (
                          <Button
                            size="small"
                            type="link"
                            onClick={() => handleDeselectAllModule(mod.module)}
                          >
                            Deselect All
                          </Button>
                        )}
                      </Space>
                    </Space>
                    <Checkbox.Group
                      options={mod.functions.map((f) => ({
                        label: titleCase(f.text),
                        value: f.text,
                      }))}
                      value={selectedFunctions}
                      onChange={(checkedValues: any[]) =>
                        handleFunctionChange(mod.module, checkedValues)
                      }
                      style={{
                        marginTop: "8px",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    />
                  </div>
                );
              })}
            </Space>
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default function EntityForm(props: any) {
  const [id, setId] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [form] = Form.useForm();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const parseForPush = (permissions: any[]) => {
    const payload: { [key: string]: any } = {};
    for (let p of permissions) {
      const { module, values } = p;
      payload[module] = {};
      for (let value of values) {
        payload[module][value] = true;
      }
    }
    return payload;
  };

  const parseForPull = (permissions: any) => {
    const payload: any[] = [];
    Object.keys(permissions).forEach((key) => {
      const output = { module: key, values: [] };

      Object.keys(permissions[key]).forEach((k) => {
        if (permissions[key][k]) {
          output.values.push(k);
        }
      });
      payload.push(output);
    });
    return payload;
  };

  const handleSubmit = (values: any) => {
    console.log(values);

    // Remove empty permissions
    values.permissions = values.permissions.filter(
      (p: any) => p.values.length !== 0
    );
    values.permissions = parseForPush(values.permissions);
    setLoading(true);
    if (id) {
      service
        .update({ ...values, _id: id })
        .then((_) => {
          setLoading(false);
          navigate(BASE_PATH);
        })
        .catch((error) => {
          setLoading(false);
          // Handle error accordingly
          console.error(error);
        });
    } else {
      service
        .create(values)
        .then((_) => {
          setLoading(false);
          navigate(BASE_PATH);
        })
        .catch((error) => {
          setLoading(false);
          // Handle error accordingly
          console.error(error);
        });
    }
  };

  const handleCancel = () => {
    navigate(BASE_PATH);
  };

  useEffect(() => {
    const idParam = new URLSearchParams(location.search).get("id");
    if (idParam) {
      setId(idParam);
      service
        .get(idParam)
        .then((data) => {
          // Assuming the data.permissions are in the parsed format
          const parsedPermissions = parseForPull(data.permissions);
          form.setFieldsValue({ ...data, permissions: parsedPermissions });
        })
        .catch((error) => {
          // Handle error accordingly
          console.error(error);
        });
    }
  }, [location, form]);

  return (
    <div>
      <Breadcrumb style={{ marginBottom: "1rem" }}>
        <Breadcrumb.Item>Taxonomy</Breadcrumb.Item>
        <Breadcrumb.Item>{TITLE[1]}</Breadcrumb.Item>
        <Breadcrumb.Item>{TITLE[0]}</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={3}>
        {id ? "Update" : "New"} {TITLE[0]}
      </Title>
      <div className="mt-4 bg-white shadow p-4">
        <Form
          form={form}
          layout="vertical"
          initialValues={{ permissions: [] }}
          onFinish={handleSubmit}
          onValuesChange={(changedValues, allValues) => {
            const permissions = allValues.permissions;
            setDisabled(permissions.length === 0);
          }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Permissions" name="permissions" rules={[]}>
            <PermissionsForm />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button type="default" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={disabled}
              >
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
