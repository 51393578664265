import { Routes, Route } from "react-router-dom";
import List from "./List";
import Form from "./Form";
import View from "./View";
export const MODEL = "sku";
export const BASE_PATH = "/finance/journal";
export const TITLE = ["Journal", "Journals"];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/form/:id`} element={<Form />} />
      <Route path={`/form`} element={<Form />} />
      <Route path={`/:id`} element={<View />} />
      <Route path={`/`} element={<List />} />
    </Routes>
  );
}
