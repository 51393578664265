import { Collapse, Descriptions, Modal } from "antd";
import orderService from "../services/order";
import invoiceService from "../services/invoice";
import { useEffect, useState } from "react";
export default function OrderItemsModal(props: any) {
  const service = props.type === "invoice" ? invoiceService : orderService;
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props.data?._id) {
      service.getOrderItems(props.data._id).then((res) => {
        setData(res);
      });
    }
  }, [props.data]);
  const orderItem = data[0];
  return (
    <Modal {...props} title="Order Items" footer={null}>
      <Collapse defaultActiveKey={"1"}>
        {data?.length > 0 &&
          data.map((item: any, index: number) => (
            <Collapse.Panel header={`Order Item ${index + 1}`} key={index + 1}>
              <Descriptions title="Order Details" bordered>
                <Descriptions.Item label="Order ID" span={3}>
                  {item.channelOrderId}
                </Descriptions.Item>
                <Descriptions.Item label="Sub Order ID">
                  {item.channelSubOrderId}
                </Descriptions.Item>
                <Descriptions.Item label="SKU Code">
                  {item.skuCode}
                </Descriptions.Item>
                <Descriptions.Item label="Quantity">
                  {item.qty}
                </Descriptions.Item>
                <Descriptions.Item label="Selling Price">
                  {item.sellingPricePerItem}
                </Descriptions.Item>
                <Descriptions.Item label="Shipping Charge">
                  {item.shippingChargePerItem}
                </Descriptions.Item>
                <Descriptions.Item label="Promo Discounts">
                  {item.promoDiscounts}
                </Descriptions.Item>
                <Descriptions.Item label="Gift Wrap Charges">
                  {item.giftWrapCharges}
                </Descriptions.Item>
                <Descriptions.Item label="Transaction Charges">
                  {item.transactionCharges}
                </Descriptions.Item>
                <Descriptions.Item label="Invoice Amount">
                  {item.invoiceAmount}
                </Descriptions.Item>
                <Descriptions.Item label="Currency Code">
                  {item.currencyCode}
                </Descriptions.Item>
                <Descriptions.Item label="Tax Rate">
                  {item.taxRate}%
                </Descriptions.Item>
                <Descriptions.Item label="Tax Amount">
                  {item.taxAmount}
                </Descriptions.Item>
                <Descriptions.Item label="IGST Rate">
                  {item.igstRate}%
                </Descriptions.Item>
                <Descriptions.Item label="IGST Amount">
                  {item.igstAmount}
                </Descriptions.Item>
                <Descriptions.Item label="CGST Rate">
                  {item.cgstRate}%
                </Descriptions.Item>
                <Descriptions.Item label="CGST Amount">
                  {item.cgstAmount}
                </Descriptions.Item>
                <Descriptions.Item label="SGST Rate">
                  {item.sgstRate}%
                </Descriptions.Item>
                <Descriptions.Item label="SGST Amount">
                  {item.sgstAmount}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  {item.status}
                </Descriptions.Item>
                <Descriptions.Item label="Settlement Amount">
                  {item.settlementAmount}
                </Descriptions.Item>
                <Descriptions.Item label="SKU UPC">
                  {item.skuUpc}
                </Descriptions.Item>
                <Descriptions.Item label="Listing SKU">
                  {item.listingSku}
                </Descriptions.Item>
                <Descriptions.Item label="Created At">
                  {new Date(item.createdAt).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label="Updated At">
                  {new Date(item.updatedAt).toLocaleString()}
                </Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          ))}
      </Collapse>
    </Modal>
  );
}
