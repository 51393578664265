import { useEffect, useState, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Select,
  Table,
  Space,
  Popover,
  List,
  Checkbox,
  Form,
  Modal,
  Tag,
  Switch,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  CloseOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { TbColumns3 } from "react-icons/tb";

import { debounce } from "throttle-debounce";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TITLE, BASE_PATH } from ".";
import service from "../../services/invoice";

import useSearchParamsTableState from "../../hooks/useSearchParamsTableState";
import { useSelector } from "react-redux";
import { Store } from "../../store";
import OrderItemsModal from "../../components/OrderItemsModal";
import mappingColumns from "../../utils/mappingColumns";
import useSelectedColumnsState from "../../hooks/useSelectedColumnsState";

const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const MASTER_KEYS = [];
const ADDITIONAL_COLUMNS = mappingColumns;

export default function Entities(props: any) {
  const breadcrumbsRef = useRef([{ label: TITLE[1], url: BASE_PATH }]);
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({ onFetch: service.paginateItems });
  const navigate = useNavigate();
  const [resetPasswordVisible, setResetPasswordVisible] = useState("");
  const [toggleLoading, setToggleLoading] = useState(false);
  const [toggleKey, setToggleKey] = useState("");
  const [selectedClient, setSelectedClient] = useState<any>();
  const [selectedEntity, setSelectedEntity] = useState<any>();
  const clientState = useSelector((state: Store) => state.client);
  const warehouseState = useSelector((state: Store) => state.warehouse);
  const { selectedColumns, setSelectedColumns } = useSelectedColumnsState({
    breadcrumbs: breadcrumbsRef.current,
    availableColumns: ADDITIONAL_COLUMNS,
    defaultSelected: [],
  });
  useEffect(() => {
    loadMastersDebounced();
  }, []);
  const [masters, setMasters] = useState<any>({});
  useEffect(() => {
    if (tableState) {
      setTableState({
        ...tableState,
        filters: {
          ...tableState.filters,
          warehouse: [warehouseState.selected],
        },
      });
    }
  }, [warehouseState.selected]);
  const getColumnsFilters = useCallback(
    (key: string) => {
      return masters[key]
        ? masters[key].map((entity) => ({
            text: entity.name,
            value: entity._id,
          }))
        : [];
    },
    [masters]
  );
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);
  const columns: any[] = [
    {
      title: "Channel Order ID",
      dataIndex: "channelOrderId",
      key: "channelOrderId",
    },
    {
      title: "Channel Sub Order ID",
      dataIndex: "channelSubOrderId",
      key: "channelSubOrderId",
    },
    { title: "SKU Code", dataIndex: "skuCode", key: "skuCode" },
    { title: "Quantity", dataIndex: "qty", key: "qty" },
    {
      title: "Selling Price Per Item",
      dataIndex: "sellingPricePerItem",
      key: "sellingPricePerItem",
    },
    {
      title: "Shipping Charge Per Item",
      dataIndex: "shippingChargePerItem",
      key: "shippingChargePerItem",
    },
    {
      title: "Promo Discounts",
      dataIndex: "promoDiscounts",
      key: "promoDiscounts",
    },
    {
      title: "Gift Wrap Charges",
      dataIndex: "giftWrapCharges",
      key: "giftWrapCharges",
    },
    {
      title: "Transaction Charges",
      dataIndex: "transactionCharges",
      key: "transactionCharges",
    },
    {
      title: "Invoice Amount",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
    },
    { title: "Currency Code", dataIndex: "currencyCode", key: "currencyCode" },
    { title: "Tax Rate", dataIndex: "taxRate", key: "taxRate" },
    { title: "Tax Amount", dataIndex: "taxAmount", key: "taxAmount" },
    { title: "IGST Rate", dataIndex: "igstRate", key: "igstRate" },
    { title: "IGST Amount", dataIndex: "igstAmount", key: "igstAmount" },
    { title: "CGST Rate", dataIndex: "cgstRate", key: "cgstRate" },
    { title: "CGST Amount", dataIndex: "cgstAmount", key: "cgstAmount" },
    { title: "SGST Rate", dataIndex: "sgstRate", key: "sgstRate" },
    { title: "SGST Amount", dataIndex: "sgstAmount", key: "sgstAmount" },
    { title: "Status", dataIndex: "status", key: "status" },
    {
      title: "Settlement Amount",
      dataIndex: "settlementAmount",
      key: "settlementAmount",
    },
    { title: "SKU UPC", dataIndex: "skuUpc", key: "skuUpc" },
    { title: "Listing SKU", dataIndex: "listingSku", key: "listingSku" },
    ...selectedColumns,
  ];

  const handleView = (entityId?: string) => {
    navigate(`${BASE_PATH}/${entityId}`);
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={3}>All Invoice Items</Title>
        </Col>
        <Col></Col>
        <Col>
          <Popover
            overlayClassName="popover-content-w-full"
            content={
              <List
                size="small"
                dataSource={ADDITIONAL_COLUMNS}
                renderItem={(item) => (
                  <List.Item className="flex items-center gap-x-2 justify-start">
                    <div className="w-max">
                      <Checkbox
                        checked={selectedColumns.some(
                          (c) => c.key === item.key
                        )}
                        onChange={(e) => {
                          setSelectedColumns(item);
                        }}
                        children={item.title}
                      />
                    </div>
                  </List.Item>
                )}
              />
            }
            title="Select Columns"
            trigger="click"
          >
            <Button icon={<TbColumns3 />} />
          </Popover>
        </Col>
      </Row>
      {/* <Row justify="space-between" className="p-4">
        <Col className="flex">
          <Search
            placeholder={`Search ${TITLE[1]}`}
            enterButton={<SearchOutlined />}
            size="middle"
            onChange={(event) => {
              setInput(event.target.value);
            }}
            value={input}
          />
        </Col>
        <Col>
          {data?.queryCount !== data?.totalCount && (
            <Text className="flex items-center">
              Showing {data.queryCount} of {data?.totalCount} entries
              <Button
                type="link"
                onClick={() => {
                  setInput("");
                }}
              >
                Clear Search
              </Button>
            </Text>
          )}
        </Col>
        <Col></Col>
      </Row> */}
      <Row className="mt-2">
        <Col span={24}>
          <div className="overflow-data-table">
            <Table
              loading={loading}
              dataSource={data?.list}
              columns={columns}
              pagination={{
                ...(tableState?.pagination || {}),
                total: data?.queryCount,
              }}
              bordered
              className="overflow-table"
              onChange={(pagination, filters, sorter) => {
                delete (sorter as any).column;
                setTableState({
                  pagination,
                  filters: { ...filters, client: [clientState.current] },
                  sorter,
                });
                setTableState({ pagination, filters, sorter });
              }}
              rowKey={(record) => record._id}
              size="small"
            />
          </div>
        </Col>
      </Row>
      <OrderItemsModal
        open={!!selectedEntity}
        data={selectedEntity}
        type="invoice"
        onCancel={() => setSelectedEntity(undefined)}
      />
    </div>
  );
}
