import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const TYPE = "LABEL_PRINTER";

export interface LabelPrinterState {
  labelPrinter: any;
  labelPrinters: any[];
  loading: boolean;
}

const initialState: LabelPrinterState = {
  labelPrinter: null,
  labelPrinters: [],
  loading: false,
};

const slice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setLabelPrinter: (state, action) => {
      state.labelPrinter = action.payload;
    },
    setLabelPrinters: (state, action) => {
      state.labelPrinters = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setLabelPrinter, setLabelPrinters, setLoading } = slice.actions;

export default slice.reducer;
