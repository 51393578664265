import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  TimePicker,
  Tooltip,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../../store";
import { useEffect, useState } from "react";
import settingService from "../../../services/settings";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import * as SettingActions from "../../../store/settingSlice";
import statesOfIndia from "../../../utils/statesOfIndia";
import querystring from "query-string";
import _ from "lodash";
function EntityForm(props: any) {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const settingState = useSelector((state: Store) => state.setting);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [id, setId] = useState("");
  const [loadLoading, setLoadLoading] = useState(false);
  useEffect(() => {
    const params: any = querystring.parse(location.search);
    if (!_.isEmpty(params)) {
      setId(params.id);
      setLoadLoading(true);
      settingService
        .getZone(params.id)
        .then((payload) => {
          form.setFieldsValue({ ...payload });
        })
        .finally(() => setLoadLoading(false));
    }
  }, [location]);
  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      if (id) {
        await settingService.updateZone({
          ...values,
          _id: id,
        });
      } else {
        await settingService.createZone({
          ...values,
        });
      }
      navigate("/settings/zone");
      return;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const associatedStates = settingState.zones.reduce((acc, zone) => {
    return [...acc, ...zone.states];
  }, []);
  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        New Zone
      </div>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="mt-4"
        initialValues={{
          expiresAt: {
            date: dayjs().add(1, "month"),
            time: dayjs().add(1, "month"),
          },
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input className="w-[350px]" placeholder="Enter Name" />
        </Form.Item>
        <Form.Item
          name="states"
          label="Constituent States"
          className="w-[350px]"
          rules={[
            {
              required: true,
              message: "At least one state is required",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Select States"
            className="w-[350px]"
          >
            {statesOfIndia
              .filter((state) => !associatedStates.includes(state.name))
              .map((state) => (
                <Select.Option key={state.name} value={state.name}>
                  {state.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Tooltip
          title="Creating new API Key will invalidate and deactivate all other API keys"
          placement="right"
        >
          <Form.Item className="w-max" noStyle>
            <Space>
              <Button onClick={() => navigate("/settings/zone")}>Cancel</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Tooltip>
      </Form>
    </div>
  );
}

export default EntityForm;
