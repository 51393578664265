import {
  Alert,
  Button,
  Input,
  Space,
  Table,
  TableColumnsType,
  Tag,
} from "antd";
import { Store } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import settingsService from "../../../services/settings";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { FixedSizeList as ListVirtualized } from "react-window";
import debounce from "lodash.debounce";

import {
  PlusOutlined,
  StopOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import skuSlice, * as skuActions from "../../../store/skuSlice";
import * as XSLX from "xlsx";
import { saveAs } from "file-saver";
import useSearchParamsTableState from "../../../hooks/useSearchParamsTableState";

const VirtualizedFilter = ({ data, onSelect, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState("");

  // Ref for the input to focus when the filter opens
  const inputRef = useRef(null);

  // Debounced search handler
  const handleSearch = useMemo(
    () =>
      debounce((e) => {
        setSearchTerm(e.target.value.toLowerCase());
      }, 300),
    []
  );

  // Filtered data based on search term
  const filteredData = useMemo(() => {
    if (!searchTerm) return data;
    return data.filter((item) => item.label.toLowerCase().includes(searchTerm));
  }, [data, searchTerm]);

  // Row renderer for react-window
  const Row = ({ index, style }) => {
    const item = filteredData[index];
    return (
      <div
        style={{
          ...style,
          padding: "8px 12px",
          cursor: "pointer",
          backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff",
        }}
        onClick={() => onSelect(item.value)}
      >
        {item.label}
      </div>
    );
  };

  return (
    <div style={{ width: 300 }}>
      <Input
        placeholder={placeholder}
        onChange={handleSearch}
        autoFocus
        ref={inputRef}
      />
      <div
        style={{
          height: 300, // Adjust based on your preference
          border: "1px solid #f0f0f0",
          marginTop: 8,
        }}
      >
        {filteredData.length > 0 ? (
          <ListVirtualized
            height={300}
            itemCount={filteredData.length}
            itemSize={35} // Height of each item
            width={"100%"}
          >
            {Row}
          </ListVirtualized>
        ) : (
          <div style={{ padding: "8px 12px" }}>No Results Found</div>
        )}
      </div>
    </div>
  );
};

function ApiKeys(props: any) {
  const navigate = useNavigate();
  const skuState = useSelector((state: Store) => state.sku);
  const [exportLoading, setExportLoading] = useState(false);
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({ onFetch: settingsService.paginateMappings });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(skuActions.getUnmappedSkusAsync({}));
  }, []);

  const handleExport = async () => {
    try {
      setExportLoading(true);
      const file = await settingsService.exportMappings();
      saveAs(file, "mappings.xlsx");
    } catch (error) {
    } finally {
      setExportLoading(false);
    }
  };

  const columns = [
    { title: "Image", dataIndex: "image", key: "image" },
    {
      title: "OMS SKU Code",
      dataIndex: "omsSkuCode",
      key: "omsSkuCode",
    },
    {
      title: "Purchase Price",
      dataIndex: "purchasePrice",
      key: "purchasePrice",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },

    {
      title: "Myntra SKU Code",
      dataIndex: "myntraSkuCode",
      key: "myntraSkuCode",
    },
    { title: "Brand", dataIndex: "brand", key: "brand" },
    { title: "Parent SKU", dataIndex: "parentSku", key: "parentSku" },
    { title: "MRP", dataIndex: "mrp", key: "mrp" },
    {
      title: "BAU Sellin Price",
      dataIndex: "bauSellinPrice",
      key: "bauSellinPrice",
    },
    {
      title: "EVENT Selling Price",
      dataIndex: "eventSellingPrice",
      key: "eventSellingPrice",
    },
    { title: "Category", dataIndex: "category", key: "category" },
    {
      title: "Sub Category",
      dataIndex: "subCategory",
      key: "subCategory",
    },
    { title: "Styles", dataIndex: "styles", key: "styles" },
    { title: "SKU Name", dataIndex: "skuName", key: "skuName" },
    {
      title: "Flipkart FSN",
      dataIndex: "flipkartFsn",
      key: "flipkartFsn",
    },
    {
      title: "Flpkart LID",
      dataIndex: "flpkartLid",
      key: "flpkartLid",
    },
    {
      title: "Myntra Style Id",
      dataIndex: "myntraStyleId",
      key: "myntraStyleId",
    },
    {
      title: "Myntra SKU Code.1",
      dataIndex: "myntraSkuCode1",
      key: "myntraSkuCode1",
    },
    {
      title: "Myntra Seller SKU Code",
      dataIndex: "myntraSellerSkuCode",
      key: "myntraSellerSkuCode",
    },
    {
      title: "Myntra SKU Id",
      dataIndex: "myntraSkuId",
      key: "myntraSkuId",
    },
    {
      title: "AJIO SKU Code",
      dataIndex: "ajioSkuCode",
      key: "ajioSkuCode",
    },
    {
      title: "AJIO Seller SKU Code",
      dataIndex: "ajioSellerSkuCode",
      key: "ajioSellerSkuCode",
    },
    {
      title: "Amazon Seller SKU Code",
      dataIndex: "amazonSellerSkuCode",
      key: "amazonSellerSkuCode",
    },
    {
      title: "Amazon SKU Code",
      dataIndex: "amazonSkuCode",
      key: "amazonSkuCode",
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (updatedAt) => dayjs(updatedAt).format("DD/MM/YYYY hh:mm A"),
    },
  ];

  const downloadUnMappedSKUsAsExcel = async () => {
    try {
      setExportLoading(true);
      const headers = columns.map((column) => column.title);
      const workbook = XSLX.utils.book_new();
      const worksheet = XSLX.utils.json_to_sheet(
        skuState.unmapped.map((sku) => ({ "OMS SKU Code": sku.skuCode })),
        {
          header: headers,
        }
      );
      XSLX.utils.book_append_sheet(workbook, worksheet, "unmapped-skus");
      const buffer = XSLX.write(workbook, { bookType: "xlsx", type: "array" });
      const file = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(file, "unmapped-skus.xlsx");
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  const parentSkuOptions = useMemo(
    () =>
      data?.dependencies.parentSku.map((v: any) => ({
        text: v,
        value: v,
      })),
    [data?.dependencies.parentSku]
  );

  const expandable = useMemo(
    () => ({
      expandedRowRender: (record) => {
        // Define the array fields you want to display
        const arrayFields = [
          {
            title: "Flipkart Seller SKU Code",
            data: record.flipkartSellerSkuCode,
          },
          { title: "Flipkart FSN", data: record.flipkartFsn },
          { title: "Flipkart LID", data: record.flipkartLid },
          { title: "Myntra Style ID", data: record.myntraStyleId },
          { title: "Myntra SKU Code", data: record.myntraSkuCode },
          { title: "Myntra Seller SKU Code", data: record.myntraSellerSkuCode },
          { title: "Myntra SKU ID", data: record.myntraSkuId },
          { title: "AJIO SKU Code", data: record.ajioSkuCode },
          { title: "AJIO Seller SKU Code", data: record.ajioSellerSkuCode },
          { title: "Amazon Seller SKU Code", data: record.amazonSellerSkuCode },
          { title: "Amazon SKU Code", data: record.amazonSkuCode },
        ];

        return (
          <Table
            columns={[
              { title: "Field", dataIndex: "field", key: "field" },
              { title: "Values", dataIndex: "values", key: "values" },
            ]}
            dataSource={arrayFields.map((field, index) => ({
              key: index,
              field: field.title,
              values: field.data ? field.data.join(", ") : "N/A",
            }))}
            pagination={false}
            size="small"
          />
        );
      },
      rowExpandable: (record) => {
        // Only expandable if at least one field has data
        const fields = [
          record.flipkartSellerSkuCode,
          record.flipkartFsn,
          record.flipkartLid,
          record.myntraStyleId,
          record.myntraSkuCode,
          record.myntraSellerSkuCode,
          record.myntraSkuId,
          record.ajioSkuCode,
          record.ajioSellerSkuCode,
          record.amazonSellerSkuCode,
          record.amazonSkuCode,
        ];
        return fields.some((field) => field && field.length > 0);
      },
    }),
    []
  );

  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        Mappings
        <div className="text-sm font-normal">
          <Space className="text-sm">
            <Alert
              type="error"
              showIcon
              message={`${skuState.unmapped.length} unmapped SKUs`}
              action={
                <a
                  href="#"
                  className="ml-4"
                  onClick={downloadUnMappedSKUsAsExcel}
                >
                  Download
                </a>
              }
            />
            <Button
              type="primary"
              onClick={() => handleExport()}
              loading={exportLoading}
              icon={<DownloadOutlined />}
            >
              Export to Excel
            </Button>
            <Button
              type="primary"
              onClick={() => navigate("/settings/mapping/bulk")}
              icon={<PlusOutlined />}
            >
              Add New
            </Button>
          </Space>
        </div>
      </div>
      <div className="">
        <Table
          className="mt-4"
          bordered
          loading={loading}
          pagination={{
            ...(tableState?.pagination || {}),
            total: data?.queryCount,
          }}
          onChange={(pagination, filters, sorter) => {
            delete (sorter as any).column;
            setTableState({ pagination, filters, sorter });
          }}
          size="small"
          dataSource={data?.list}
          expandable={expandable}
          rowKey={(record) => record._id}
          columns={[
            { title: "Image", dataIndex: "image", key: "image" },
            {
              title: "OMS SKU Code",
              dataIndex: "omsSkuCode",
              key: "omsSkuCode",
            },
            {
              title: "Purchase Price",
              dataIndex: "purchasePrice",
              key: "purchasePrice",
            },
            {
              title: "Gender",
              dataIndex: "gender",
              key: "gender",
              filters: ["male", "female"].map((v) => ({ text: v, value: v })),
            },
            { title: "Brand", dataIndex: "brand", key: "brand" },
            {
              title: "Parent SKU",
              dataIndex: "parentSku",
              key: "parentSku",
              filters: parentSkuOptions,
            },
            { title: "MRP", dataIndex: "mrp", key: "mrp" },
            {
              title: "BAU Sellin Price",
              dataIndex: "bauSellinPrice",
              key: "bauSellinPrice",
            },
            {
              title: "EVENT Selling Price",
              dataIndex: "eventSellingPrice",
              key: "eventSellingPrice",
            },

            { title: "Category", dataIndex: "category", key: "category" },
            {
              title: "Sub Category",
              dataIndex: "subCategory",
              key: "subCategory",
            },
            { title: "Styles", dataIndex: "styles", key: "styles" },
            { title: "SKU Name", dataIndex: "skuName", key: "skuName" },

            {
              title: "Updated At",
              dataIndex: "updatedAt",
              key: "updatedAt",
              render: (updatedAt) =>
                dayjs(updatedAt).format("DD/MM/YYYY hh:mm A"),
            },
          ]}
        />
      </div>
    </div>
  );
}

export default ApiKeys;
