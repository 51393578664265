import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/vendor-credit-note";

export const create = (data: any[]) => postRequest(`${SERVICE_URL}/`, data);
export const update = (data: any[]) => putRequest(`${SERVICE_URL}/`, data);
export const generatePaymentID = () => getRequest(`${SERVICE_URL}/payment-id`);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);
export const get = (id: string) => getRequest(`${SERVICE_URL}/${id}`);
export const voidEntity = (id: string) =>
  putRequest(`${SERVICE_URL}/void/${id}`);
export default {
  create,
  paginate,
  get,
  update,
  voidEntity,
};
