import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import service from "../services/batch";
const TYPE = "BATCH";

export interface BatchState {
  batches: any[];
  loading: boolean;
}

const initialState: BatchState = {
  batches: [],
  loading: false,
};

export const loadBatchesAsync = createAsyncThunk(
  `${TYPE}/LOAD_BATCHES`,
  async (client: any, thunkAPI) => {
    try {
      const response = await service.getBatches(client);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const batchSlice = createSlice({
  name: TYPE,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadBatchesAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loadBatchesAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.batches = action.payload;
    });
    builder.addCase(loadBatchesAsync.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default batchSlice.reducer;
