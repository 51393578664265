import React from "react";
import logo from "./logo.svg";
import "./App.scss";
import { Button } from "antd";
import AppLayout from "./layouts/AppLayout";
import { ConfigProvider, theme } from "antd";
import RouteConfig from "./router";

function App() {
  const { token } = theme.useToken();
  return <RouteConfig />;
}

export default App;
