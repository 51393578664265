import React, { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import service from "../services/po";
import skuService from "../services/sku";
import { FilePdfOutlined } from "@ant-design/icons";
import getDocDefinition from "../utils/getVDNDocDefinition";
import _ from "lodash";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function VCNPDFModal(props: any) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const showModal = async () => {
    if (!data) return;

    setLoading(true);

    const payload = _.cloneDeep(data);

    const skuIds = new Set<string>(data.skus?.map((sku: any) => sku.sku));

    const skuObjects = await Promise.all(
      Array.from(skuIds).map((skuId) => skuService.get(skuId))
    );

    const products = {};

    if (data.skus) {
      const consolidatedSkus = [];

      for (let sku of data.skus) {
        const existingSku = consolidatedSkus.find(
          (s: any) => s.sku === sku.sku
        );
        if (existingSku) {
          existingSku.quantity += sku.quantity;
        } else {
          consolidatedSkus.push(_.cloneDeep(sku));
        }
      }

      let totalQuantity = 0;
      let totalAmount = 0;

      for (let sku of consolidatedSkus) {
        const skuObject = skuObjects.find((s: any) => s._id === sku.sku);
        const skuCodeTokens: string[] = skuObject.skuCode.split("_");
        const size = skuCodeTokens.pop();
        const productCode = skuCodeTokens.join("_");

        products[productCode] = [
          ...(products[productCode] || []),
          {
            skuCode: skuObject.skuCode,
            size: size,
            _id: skuObject._id,
            quantity: sku.quantity,
            rate: sku.rate,
            amount: sku.quantity * sku.rate,
          },
        ];

        totalQuantity += sku.quantity;
        totalAmount += sku.quantity * sku.rate;
      }

      const processedProducts = [];

      for (let productCode in products) {
        products[productCode] = products[productCode].sort((a: any, b: any) =>
          a.size.localeCompare(b.size)
        );
        const quantities = products[productCode].map((p: any) => ({
          size: p.size,
          skuCode: p.skuCode,
          ordered: p.quantity,
          rate: p.rate,
          amount: p.amount,
        }));
        processedProducts.push({
          productCode: productCode,
          quantities: quantities,
        });
      }
      payload.skus = processedProducts;
      payload.totalQuantity = totalQuantity;
      payload.totalAmount = totalAmount;
    }

    const { skus } = payload;
    const skuCodes = skus
      .map((sku: any) => sku.quantities)
      .flat()
      .map((quantity: any) => quantity.skuCode);

    const mappings = await Promise.all(
      skuCodes.map((skuCode: string) => skuService.getMappingForSku(skuCode))
    );

    for (let i = 0; i < skus.length; i++) {
      const sku = skus[i];
      sku.mapping = mappings[i];
    }

    // Define the PDF document
    const docDefinition: any = getDocDefinition(payload);

    setLoading(false);

    // Generate the PDF
    pdfMake.createPdf(docDefinition).getBlob((blob) => {
      const url = URL.createObjectURL(blob);
      setPdfUrl(url);
      setIsModalVisible(true);
    });
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setPdfUrl(null);
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  return (
    <>
      <Button
        type="link"
        onClick={showModal}
        loading={loading}
        icon={<FilePdfOutlined />}
      >
        View PDF
      </Button>
      <Modal
        title="PDF Viewer"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={800}
        style={{ top: 20 }}
      >
        {pdfUrl && (
          <iframe
            src={pdfUrl}
            style={{ width: "100%", height: "1020px", border: "none" }}
          />
        )}
      </Modal>
    </>
  );
}
